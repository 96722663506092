export default {
    "en": {
        "(and {count} more error)": "(and {count} more error)",
        "(and {count} more errors)": "(and {count} more errors)",
        "* Required fields": "* Required fields",
        "6-Digit code": "6-Digit code",
        "1234AA Place": "1234AA Place",
        "{appName}": "{appName}",
        "{Attribute} {lang}": "{Attribute} {lang}",
        "{Attribute} is invalid": "{Attribute} is invalid",
        "{Attribute} must be in the future.": "{Attribute} must be in the future.",
        "{break}": "{break}",
        "{break} minutes": "{break} minutes",
        "{causer} has invited you to apply for a shift you liked.": "{causer} has invited you to apply for a shift you liked.",
        "{count} characters": "{count} characters",
        "{employer} invited you to fill the shift you liked. Please apply for this shift to fill it.": "{employer} invited you to fill the shift you liked. Please apply for this shift to fill it.",
        "{employer} invited you to fill this shift on {start} at {location}. Please apply for this shift to fill it.": "{employer} invited you to fill this shift on {start} at {location}. Please apply for this shift to fill it.",
        "{freelancer} deleted his account!": "{freelancer} deleted his account!",
        "{freelancer} deleted his account and cannot work on {start} - {end}.": "{freelancer} deleted his account and cannot work on {start} - {end}.",
        "{freelancer} disputed his amended hours": "{freelancer} disputed his amended hours",
        "{freelancer} disputed the hours that were amended by {location}.": "{freelancer} disputed the hours that were amended by {location}.",
        "{freelancer} just applied for your shift on {start}. Please review his application now!": "{freelancer} just applied for your shift on {start}. Please review his application now!",
        "{freelancer} just applied your shift": "{freelancer} just applied your shift",
        "{freelancer} just applied your shift on {start}": "{freelancer} just applied your shift on {start}",
        "{freelancer} liked your shift for {position} on {start} at {location}. If you like you're able to invite {freelancer} to apply for this shift.": "{freelancer} liked your shift for {position} on {start} at {location}. If you like you're able to invite {freelancer} to apply for this shift.",
        "{freelancer} likes your shift": "{freelancer} likes your shift",
        "{freelancer} retracted application!": "{freelancer} retracted application!",
        "{freelancer} retracted his application for your shift on {start} - {end}.": "{freelancer} retracted his application for your shift on {start} - {end}.",
        "{location} accepted your application for the shift on {shiftStart}!": "{location} accepted your application for the shift on {shiftStart}!",
        "{location} accepted your application for this shift:": "{location} accepted your application for this shift:",
        "{location} retracted a shift you applied for": "{location} retracted a shift you applied for",
        "{location} retracted a shift you applied for.": "{location} retracted a shift you applied for.",
        "{Model} {name}": "{Model} {name}",
        "{model} {name}": "{model} {name}",
        "{Model} accepted": "{Model} accepted",
        "{Model} added to favorites": "{Model} added to favorites",
        "{Model} amended": "{Model} amended",
        "{model} applicants": "{model} applicants",
        "{Model} blocked": "{Model} blocked",
        "{Model} created": "{Model} created",
        "{Model} deleted": "{Model} deleted",
        "{model} detail": "{model} detail",
        "{Model} details": "{Model} details",
        "{model} details": "{model} details",
        "{model} edit": "{model} edit",
        "{Model} edited": "{Model} edited",
        "{Model} invited": "{Model} invited",
        "{Model} is not a concept": "{Model} is not a concept",
        "{Model} marked as paid": "{Model} marked as paid",
        "{Model} marked as unpaid": "{Model} marked as unpaid",
        "{Model} name": "{Model} name",
        "{model} name": "{model} name",
        "{Model} overview": "{Model} overview",
        "{model} overview": "{model} overview",
        "{Model} rejected": "{Model} rejected",
        "{Model} removed from favorites": "{Model} removed from favorites",
        "{Model} retracted": "{Model} retracted",
        "{Model} settings": "{Model} settings",
        "{model} team": "{model} team",
        "{Model} unblocked": "{Model} unblocked",
        "{Model} updated": "{Model} updated",
        "{Model} verified": "{Model} verified",
        "{Model} was {status_changed}": "{Model} was {status_changed}",
        "{Model} was send": "{Model} was send",
        "{number} reviews": "{number} reviews",
        "{position} required by {employer}": "{position} required by {employer}",
        "{start} - {end}": "{start} - {end}",
        "{userName} from {companyName} created location {locationNickname}": "{userName} from {companyName} created location {locationNickname}",
        "{userName} from {companyName} just created a location named {locationName}": "{userName} from {companyName} just created a location named {locationName}",
        "? For example, you can work here as a chef, cook or sous-chef. With about 200,000 inhabitants": "? For example, you can work here as a chef, cook or sous-chef. With about 200,000 inhabitants",
        "? Je kunt hier bijvoorbeeld aan de slag als chefkok, kok of sous-chef. Met zo’n 200.000 inwoners staat": "? Je kunt hier bijvoorbeeld aan de slag als chefkok, kok of sous-chef. Met zo’n 200.000 inwoners staat",
        "About": "About",
        "About us": "About us",
        "Accept": "Accept",
        "Accept {model}": "Accept {model}",
        "Acceptance lapsed": "Acceptance lapsed",
        "Accept a Top-Chef": "Accept a Top-Chef",
        "Accepted": "Accepted",
        "accepted": "accepted",
        "Accept Invitation": "Accept Invitation",
        "Accept the hours worked and rate Top-Chef": "Accept the hours worked and rate Top-Chef",
        "Accountmanager": "Accountmanager",
        "Accredited": "Accredited",
        "Action": "Action",
        "Action points": "Action points",
        "Action required": "Action required",
        "Actions": "Actions",
        "Active applications": "Active applications",
        "Active freelancers export": "Active freelancers export",
        "Activity": "Activity",
        "Add": "Add",
        "Add {attribute}": "Add {attribute}",
        "Add {model}": "Add {model}",
        "Add {name}": "Add {name}",
        "Add a {model}": "Add a {model}",
        "Add additional security to your account using two factor authentication.": "Add additional security to your account using two factor authentication.",
        "Add a new team member to your team, allowing them to collaborate with you.": "Add a new team member to your team, allowing them to collaborate with you.",
        "Added.": "Added.",
        "Add new {attribute}": "Add new {attribute}",
        "Address": "Address",
        "Address data:": "Address data:",
        "Add Team Member": "Add Team Member",
        "Add team member": "Add team member",
        "Add to favorites": "Add to favorites",
        "Admin": "Admin",
        "Administration email": "Administration email",
        "Administration emails": "Administration emails",
        "Administrator": "Administrator",
        "Administrator users can perform any action.": "Administrator users can perform any action.",
        "A fresh verification link has been sent to your email address.": "A fresh verification link has been sent to your email address.",
        "After signing up you can work immediately as a freelance chef. The clients can then give you a review after a shift. So you are responsible for your work and you build your own success.": "After signing up you can work immediately as a freelance chef. The clients can then give you a review after a shift. So you are responsible for your work and you build your own success.",
        "Age": "Age",
        "Agree": "Agree",
        "All": "All",
        "All {attribute}": "All {attribute}",
        "All {model} belonging to the user": "All {model} belonging to the user",
        "All employees within Top-chefs bv have committed themselves to dealing confidentially with yourn                        personal data.": "All employees within Top-chefs bv have committed themselves to dealing confidentially with yourn                        personal data.",
        "All employees within Top-chefs bv have committed themselves to dealing confidentially with your personal data.": "All employees within Top-chefs bv have committed themselves to dealing confidentially with your personal data.",
        "All frequently asked questions": "All frequently asked questions",
        "All of the people that are part of this team.": "All of the people that are part of this team.",
        "Allow cookies": "Allow cookies",
        "All rights reserved.": "All rights reserved.",
        "Already registered?": "Already registered?",
        "Already Reported": "Already Reported",
        "Also interesting?": "Also interesting?",
        "Also please find attached our framework agreement. We kindly ask you to sign the framework agreement and send it to us via {email} within 7 days.": "Also please find attached our framework agreement. We kindly ask you to sign the framework agreement and send it to us via {email} within 7 days.",
        "Also please we have emailed your our framework agreement. We kindly ask you to sign the framework agreement and send it to us via {email} within 7 days.": "Also please we have emailed your our framework agreement. We kindly ask you to sign the framework agreement and send it to us via {email} within 7 days.",
        "Always ask": "Always ask",
        "Amended": "Amended",
        "Amount": "Amount",
        "and": "and",
        "and we will be happy to help you!": "and we will be happy to help you!",
        "A new shift(s) where created!": "A new shift(s) where created!",
        "A new shift(s) where created on the location {locationName}": "A new shift(s) where created on the location {locationName}",
        "A new shift was created!": "A new shift was created!",
        "A new shift was created on the location {locationName}": "A new shift was created on the location {locationName}",
        "A new user has started the registration process but has not completed it yet.": "A new user has started the registration process but has not completed it yet.",
        "A new verification link has been sent to the email address you provided in your profile settings.": "A new verification link has been sent to the email address you provided in your profile settings.",
        "A new verification link has been sent to your email address.": "A new verification link has been sent to your email address.",
        "Answer": "Answer",
        "Answer (en)": "Answer (en)",
        "Answer (nl)": "Answer (nl)",
        "A number of advantages for you as a catering company:": "A number of advantages for you as a catering company:",
        "Any employer can sign up and place a shift after approval.": "Any employer can sign up and place a shift after approval.",
        "API Token": "API Token",
        "API Token Permissions": "API Token Permissions",
        "API Tokens": "API Tokens",
        "API tokens allow third-party services to authenticate with our application on your behalf.": "API tokens allow third-party services to authenticate with our application on your behalf.",
        "App": "App",
        "Application": "Application",
        "application": "application",
        "Applied": "Applied",
        "Apply for new shifts": "Apply for new shifts",
        "Apply for other shifts": "Apply for other shifts",
        "Apply Now": "Apply Now",
        "Approve {model}": "Approve {model}",
        "Approve Hours": "Approve Hours",
        "Approve hours": "Approve hours",
        "Archive": "Archive",
        "Are the chefs checked whether they really have a Chamber of Commerce (KvK)?": "Are the chefs checked whether they really have a Chamber of Commerce (KvK)?",
        "Are the kilometers also registered?": "Are the kilometers also registered?",
        "Are you sure? In this case we will retract this shift and their applicants and place the shift again so that new chefs can apply. This action is irreversable": "Are you sure? In this case we will retract this shift and their applicants and place the shift again so that new chefs can apply. This action is irreversable",
        "Are you sure that you want to remove the review? This will impact the total score.": "Are you sure that you want to remove the review? This will impact the total score.",
        "Are you sure that you want to remove the review? We will still count the review in the total score.": "Are you sure that you want to remove the review? We will still count the review in the total score.",
        "Are you sure you want to accept this applicant?": "Are you sure you want to accept this applicant?",
        "Are you sure you want to approve these hours ?": "Are you sure you want to approve these hours ?",
        "Are you sure you want to delete this {model}?": "Are you sure you want to delete this {model}?",
        "Are you sure you want to delete this team? Once a team is deleted, all of its resources and data will be permanently deleted.": "Are you sure you want to delete this team? Once a team is deleted, all of its resources and data will be permanently deleted.",
        "Are you sure you want to delete your account? Once your account is deleted, all of its resources and data will be permanently deleted. Please enter your password to confirm you would like to permanently delete your account.": "Are you sure you want to delete your account? Once your account is deleted, all of its resources and data will be permanently deleted. Please enter your password to confirm you would like to permanently delete your account.",
        "Are you sure you want to invite this freelancer?": "Are you sure you want to invite this freelancer?",
        "Are you sure you want to propose these new hours?": "Are you sure you want to propose these new hours?",
        "Are you sure you want to retract this shift?": "Are you sure you want to retract this shift?",
        "Are you sure you would like to delete this API token?": "Are you sure you would like to delete this API token?",
        "Are you sure you would like to leave this team?": "Are you sure you would like to leave this team?",
        "Are you sure you would like to remove this person from the team?": "Are you sure you would like to remove this person from the team?",
        "arrow": "arrow",
        "As an employer, can I also hire a chef within the app?": "As an employer, can I also hire a chef within the app?",
        "As an employer, can I also pay in advance?": "As an employer, can I also pay in advance?",
        "A selection of our clients:": "A selection of our clients:",
        "Assign": "Assign",
        "A test notification from {appName}": "A test notification from {appName}",
        "A Timeout Occurred": "A Timeout Occurred",
        "At least one administration email is required. Be sure to click the plus icon to add an email.": "At least one administration email is required. Be sure to click the plus icon to add an email.",
        "At least one contract email is required. Be sure to click the plus icon to add an email.": "At least one contract email is required. Be sure to click the plus icon to add an email.",
        "Attach": "Attach",
        "Attached to the email you will find the contact": "Attached to the email you will find the contact",
        "Attached to the email you will find the contract": "Attached to the email you will find the contract",
        "Attached to the email you will find the contract(s)": "Attached to the email you will find the contract(s)",
        "At The Seafood Bar, we are thrilled to be working with Top Chefs. Their Customer Service department is really fantastic, so fast and very proactive and helpful. That makes the cooperation really nice!": "At The Seafood Bar, we are thrilled to be working with Top Chefs. Their Customer Service department is really fantastic, so fast and very proactive and helpful. That makes the cooperation really nice!",
        "Author": "Author",
        "Author:": "Author:",
        "Average": "Average",
        "Average {model}": "Average {model}",
        "Back": "Back",
        "back": "back",
        "Back to overview": "Back to overview",
        "Back to overview arrow": "Back to overview arrow",
        "Backup codes": "Backup codes",
        "Bad": "Bad",
        "Bad Gateway": "Bad Gateway",
        "Badge assigned": "Badge assigned",
        "Badges": "Badges",
        "Badge unassigned": "Badge unassigned",
        "Bad Request": "Bad Request",
        "Bandwidth Limit Exceeded": "Bandwidth Limit Exceeded",
        "Bas Brouwer - Top-Chef": "Bas Brouwer - Top-Chef",
        "Bas brouwer - Top-Chef": "Bas brouwer - Top-Chef",
        "Bas brouwer - Top Chef": "Bas brouwer - Top Chef",
        "Before continuing, could you verify your email address by clicking on the link we just emailed to you? If you didn't receive the email, we will gladly send you another.": "Before continuing, could you verify your email address by clicking on the link we just emailed to you? If you didn't receive the email, we will gladly send you another.",
        "Before proceeding, please check your email for a verification link.": "Before proceeding, please check your email for a verification link.",
        "Besides my family and regular job, I like having Top-Chefs put me away at different locations, I can decide when and where I work also the payments are always fast. I have already had the opportunity to work with Top-Chefs' app.<br><br>From registering to accepting shifts and hours registration is fast and flawless. Writing mutual reviews works extra motivating for me to work hard, but also gives me a voice about how we the \"chefs\" are treated on location.": "Besides my family and regular job, I like having Top-Chefs put me away at different locations, I can decide when and where I work also the payments are always fast. I have already had the opportunity to work with Top-Chefs' app.<br><br>From registering to accepting shifts and hours registration is fast and flawless. Writing mutual reviews works extra motivating for me to work hard, but also gives me a voice about how we the \"chefs\" are treated on location.",
        "Besides my family and regular job, I like having Top-Chefs put me away at different locations, I can decide when and where I work also the payments are always fast. I have already had the opportunity to work with Top-Chefs' app.nnFrom registering to accepting shifts and hours registration is fast and flawless. Writing mutual reviews works extra motivating for me to work hard, but also gives me a voice about how we the \"chefs\" are treated on location.": "Besides my family and regular job, I like having Top-Chefs put me away at different locations, I can decide when and where I work also the payments are always fast. I have already had the opportunity to work with Top-Chefs\\' app.nnFrom registering to accepting shifts and hours registration is fast and flawless. Writing mutual reviews works extra motivating for me to work hard, but also gives me a voice about how we the \"chefs\" are treated on location.",
        "Besides my family and regular job, I like having Top-Chefs put me away at different locations, I can decide when and where I work also the payments are always fast. I have already had the opportunity to work with Top-Chefs\\' app.<br><br>From registering to accepting shifts and hours registration is fast and flawless. Writing mutual reviews works extra motivating for me to work hard, but also gives me a voice about how we the \"chefs\" are treated on location.": "Besides my family and regular job, I like having Top-Chefs put me away at different locations, I can decide when and where I work also the payments are always fast. I have already had the opportunity to work with Top-Chefs\\' app.<br><br>From registering to accepting shifts and hours registration is fast and flawless. Writing mutual reviews works extra motivating for me to work hard, but also gives me a voice about how we the \"chefs\" are treated on location.",
        "Besides my family and regular job, I like having Top-Chefs put me away at different locations, I can decide when and where I work also the payments are always fast. I have already had the opportunity to work with Top-Chefs\\' app.nnFrom registering to accepting shifts and hours registration is fast and flawless. Writing mutual reviews works extra motivating for me to work hard, but also gives me a voice about how we the \"chefs\" are treated on location.": "Besides my family and regular job, I like having Top-Chefs put me away at different locations, I can decide when and where I work also the payments are always fast. I have already had the opportunity to work with Top-Chefs\\' app.nnFrom registering to accepting shifts and hours registration is fast and flawless. Writing mutual reviews works extra motivating for me to work hard, but also gives me a voice about how we the \"chefs\" are treated on location.",
        "Besides my family and regular job, I like having Top-Chefs put me away at different locations, I can decide when and where I work also the payments are always fast. I have already had the opportunity to work with Top-Chefs\\' app.\\n\\nFrom registering to accepting shifts and hours registration is fast and flawless. Writing mutual reviews works extra motivating for me to work hard, but also gives me a voice about how we the \"chefs\" are treated on location.": "Besides my family and regular job, I like having Top-Chefs put me away at different locations, I can decide when and where I work also the payments are always fast. I have already had the opportunity to work with Top-Chefs\\' app.\\n\\nFrom registering to accepting shifts and hours registration is fast and flawless. Writing mutual reviews works extra motivating for me to work hard, but also gives me a voice about how we the \"chefs\" are treated on location.",
        "Billing": "Billing",
        "Block": "Block",
        "Block {model}": "Block {model}",
        "Blocked": "Blocked",
        "Blocked {model}": "Blocked {model}",
        "Blocked freelancers": "Blocked freelancers",
        "Blog": "Blog",
        "blog": "blog",
        "Blogs": "Blogs",
        "blogs": "blogs",
        "Break": "Break",
        "Brought in": "Brought in",
        "Browse": "Browse",
        "Browser Sessions": "Browser Sessions",
        "BSN": "BSN",
        "Build a freelance team around your catering business.": "Build a freelance team around your catering business.",
        "Business": "Business",
        "By phone daily from 09{00} to 17{00}": "By phone daily from 09{00} to 17{00}",
        "By phone daily from {from} to {to}": "By phone daily from {from} to {to}",
        "By using the Top-Chefs platform, hiring new chefs becomes a lot easier and more fun!": "By using the Top-Chefs platform, hiring new chefs becomes a lot easier and more fun!",
        "Calling no longer possible? Then send us an emergency WhatsApp message!": "Calling no longer possible? Then send us an emergency WhatsApp message!",
        "Call to": "Call to",
        "Can't wait to get started with the Top-Chefs app? Download the app for free and create a profile. And remember; we’ll do your administration!": "Can't wait to get started with the Top-Chefs app? Download the app for free and create a profile. And remember; we’ll do your administration!",
        "Can be updated only": "Can be updated only",
        "Cancel": "Cancel",
        "Can I also call Top-Chefs when I have a question?": "Can I also call Top-Chefs when I have a question?",
        "Can I also work for private parties?": "Can I also work for private parties?",
        "Can I also write a review?": "Can I also write a review?",
        "Can I be removed from the platform?": "Can I be removed from the platform?",
        "Can I decide when I work?": "Can I decide when I work?",
        "Can I work at Top-Chefs when I only speak English?": "Can I work at Top-Chefs when I only speak English?",
        "Can I work through Top-chefs without being registered with the Chamber of Commerce?": "Can I work through Top-chefs without being registered with the Chamber of Commerce?",
        "Can the client also block me?": "Can the client also block me?",
        "Capital company outside honor": "Capital company outside honor",
        "Categories": "Categories",
        "Category": "Category",
        "category": "category",
        "Catering companies": "Catering companies",
        "changes": "changes",
        "Chat": "Chat",
        "Chat with your bosses and or employers.": "Chat with your bosses and or employers.",
        "Check corrections": "Check corrections",
        "Checkout applicants": "Checkout applicants",
        "Check Shift": "Check Shift",
        "Chef": "Chef",
        "Chef icon": "Chef icon",
        "Chef price": "Chef price",
        "Chef questions": "Chef questions",
        "Chefs": "Chefs",
        "Chef training completed": "Chef training completed",
        "Choose": "Choose",
        "Choose {name}": "Choose {name}",
        "Choose File": "Choose File",
        "Choose Image": "Choose Image",
        "Cities": "Cities",
        "City": "City",
        "Clear time registration and costs.": "Clear time registration and costs.",
        "Click here to re-send the verification email.": "Click here to re-send the verification email.",
        "click here to request another": "click here to request another",
        "Click to copy": "Click to copy",
        "Click to copy shift": "Click to copy shift",
        "Client Closed Request": "Client Closed Request",
        "Close": "Close",
        "Closed": "Closed",
        "CoC (KvK) number": "CoC (KvK) number",
        "CoC (KvK) number is invalid": "CoC (KvK) number is invalid",
        "CoC (KvK) number must consist of 8 letters": "CoC (KvK) number must consist of 8 letters",
        "CoC number": "CoC number",
        "Code": "Code",
        "code": "code",
        "Collapse": "Collapse",
        "Collapse All": "Collapse All",
        "Coming soon!": "Coming soon!",
        "Comment": "Comment",
        "Companies": "Companies",
        "companies": "companies",
        "Company": "Company",
        "company": "company",
        "Company details": "Company details",
        "Company framework agreements": "Company framework agreements",
        "Company name": "Company name",
        "Company owner": "Company owner",
        "Completed": "Completed",
        "Completed {model}": "Completed {model}",
        "Completed Shifts": "Completed Shifts",
        "Completed shifts:": "Completed shifts:",
        "Complete your registration to sign up for shifts": "Complete your registration to sign up for shifts",
        "Concept": "Concept",
        "conditions": "conditions",
        "Confirm": "Confirm",
        "Confirm Password": "Confirm Password",
        "Confirm password: *": "Confirm password: *",
        "Conflict": "Conflict",
        "Connect": "Connect",
        "Connection Closed Without Response": "Connection Closed Without Response",
        "Connection Timed Out": "Connection Timed Out",
        "Contact": "Contact",
        "Content": "Content",
        "Continue": "Continue",
        "Contract(s) have been sent to your email address": "Contract(s) have been sent to your email address",
        "Contract emails": "Contract emails",
        "Contracts emails": "Contracts emails",
        "Contract signed": "Contract signed",
        "Cook": "Cook",
        "Cook price": "Cook price",
        "Cost": "Cost",
        "Cost {model}": "Cost {model}",
        "Cost center": "Cost center",
        "Could not fetch data": "Could not fetch data",
        "Country": "Country",
        "Create": "Create",
        "Create {attribute}": "Create {attribute}",
        "Create {model}": "Create {model}",
        "Create {name}": "Create {name}",
        "Create a {model}": "Create a {model}",
        "Create Account": "Create Account",
        "Create account": "Create account",
        "Create an account": "Create an account",
        "Create a new team to collaborate with others on projects.": "Create a new team to collaborate with others on projects.",
        "Create a password": "Create a password",
        "Create API Token": "Create API Token",
        "Create a shift": "Create a shift",
        "Created": "Created",
        "Created.": "Created.",
        "Created at": "Created at",
        "Created in shiftbase": "Created in shiftbase",
        "Create New Team": "Create New Team",
        "Create Password": "Create Password",
        "Create Team": "Create Team",
        "Create your own preferred pool of favorite chefs.": "Create your own preferred pool of favorite chefs.",
        "Creating shift exceeds maximum allowed credit. Please contact support to resolve this issue.": "Creating shift exceeds maximum allowed credit. Please contact support to resolve this issue.",
        "Credit Invoice": "Credit Invoice",
        "Current {attribute}": "Current {attribute}",
        "Current Password": "Current Password",
        "Danger": "Danger",
        "Dashboard": "Dashboard",
        "Data exchange": "Data exchange",
        "Date": "Date",
        "Date/Time": "Date/Time",
        "Date {model}": "Date {model}",
        "Date Of Birth": "Date Of Birth",
        "Date of birth": "Date of birth",
        "Day": "Day",
        "Dear {freelancer},": "Dear {freelancer},",
        "Dear {location},": "Dear {location},",
        "Dear {Name},": "Dear {Name},",
        "Dear Sir / Madam,": "Dear Sir / Madam,",
        "Dear Top Chef,": "Dear Top Chef,",
        "Decline": "Decline",
        "Decline cookies": "Decline cookies",
        "Default": "Default",
        "Delete": "Delete",
        "Delete {model}": "Delete {model}",
        "Delete {name}": "Delete {name}",
        "Delete Account": "Delete Account",
        "Delete API Token": "Delete API Token",
        "Delete Team": "Delete Team",
        "department": "department",
        "Depending on the type of user, the following data will be removed": "Depending on the type of user, the following data will be removed",
        "Description": "Description",
        "Description {attribute}": "Description {attribute}",
        "Detach": "Detach",
        "Details": "Details",
        "Details {model}": "Details {model}",
        "Developer": "Developer",
        "Did you forget your password?": "Did you forget your password?",
        "Direct debit": "Direct debit",
        "Disable": "Disable",
        "Discard": "Discard",
        "Discount on manhours": "Discount on manhours",
        "Disputed": "Disputed",
        "Disputed {model}": "Disputed {model}",
        "Disputed shift reviewed": "Disputed shift reviewed",
        "Distance from location": "Distance from location",
        "Distance from location (km)": "Distance from location (km)",
        "Distance in {attribute}": "Distance in {attribute}",
        "Done": "Done",
        "Done.": "Done.",
        "done icon": "done icon",
        "Dont have an account yet?": "Dont have an account yet?",
        "Don’t have access to your authenticator device ?": "Don’t have access to your authenticator device ?",
        "Down": "Down",
        "Download {model}": "Download {model}",
        "Download app": "Download app",
        "Download backup codes and continue.": "Download backup codes and continue.",
        "Download the {brand} app": "Download the {brand} app",
        "Download the app.": "Download the app.",
        "Download the app on google play": "Download the app on google play",
        "Download the app on the app store": "Download the app on the app store",
        "Do you also do long-term assignments?": "Do you also do long-term assignments?",
        "Do you have a question or complaint? For example, about who we share data with or our handlingn                        of your data? If so, please send an e-mail to": "Do you have a question or complaint? For example, about who we share data with or our handlingn                        of your data? If so, please send an e-mail to",
        "Do you have a question or complaint? For example, about who we share data with or our handling of your data ? If so, please send an e - mail to": "Do you have a question or complaint? For example, about who we share data with or our handling of your data ? If so, please send an e - mail to",
        "Do you have a question or complaint? For example, about who we share data with or our handling of your data? If so, please send an e-mail to": "Do you have a question or complaint? For example, about who we share data with or our handling of your data? If so, please send an e-mail to",
        "Do you have questions or can`t register?": "Do you have questions or can`t register?",
        "Draft": "Draft",
        "Dress code": "Dress code",
        "Dropdown arrow": "Dropdown arrow",
        "Due Date": "Due Date",
        "Due date": "Due date",
        "Duplicate": "Duplicate",
        "Duplicate {name}": "Duplicate {name}",
        "Duration": "Duration",
        "E-mail": "E-mail",
        "E-mail*": "E-mail*",
        "E-Mail Address": "E-Mail Address",
        "E-mail address": "E-mail address",
        "Easily chat with your future chefs": "Easily chat with your future chefs",
        "Easily find freelance chefs for open shifts": "Easily find freelance chefs for open shifts",
        "Easily find freelance operating employees for open shifts": "Easily find freelance operating employees for open shifts",
        "Easy registration.": "Easy registration.",
        "Easy time registration": "Easy time registration",
        "Easy time registration.": "Easy time registration.",
        "Edit": "Edit",
        "Edit {model}": "Edit {model}",
        "Edit {name}": "Edit {name}",
        "Edit FAQ": "Edit FAQ",
        "Editor": "Editor",
        "Editor users have the ability to read, create, and update.": "Editor users have the ability to read, create, and update.",
        "Edit Profile": "Edit Profile",
        "Eelco Meerman": "Eelco Meerman",
        "Email": "Email",
        "email": "email",
        "Email #{position} is not valid": "Email #{position} is not valid",
        "Email: {email}": "Email: {email}",
        "Email: {employerEmail}": "Email: {employerEmail}",
        "Email address": "Email address",
        "Email Password Reset Link": "Email Password Reset Link",
        "Emergency number daily from 09{00} to 17{00}": "Emergency number daily from 09{00} to 17{00}",
        "Emergency number daily from {from} to {to}": "Emergency number daily from {from} to {to}",
        "Employer": "Employer",
        "Employer, position or city*": "Employer, position or city*",
        "Employer, position or place*": "Employer, position or place*",
        "Employer questions": "Employer questions",
        "Employers": "Employers",
        "employers": "employers",
        "Employers, position or city*": "Employers, position or city*",
        "Enable": "Enable",
        "Enclosed you will find the invoice for the worked services.": "Enclosed you will find the invoice for the worked services.",
        "End": "End",
        "End Date": "End Date",
        "End time": "End time",
        "end time": "end time",
        "End week": "End week",
        "Ensure your account is using a long, random password to stay secure.": "Ensure your account is using a long, random password to stay secure.",
        "Entrepreneur of a": "Entrepreneur of a",
        "Every day we match hospitality staff & hospitality companies! Are you a freelance waitress and looking for a new assignment? You choose where and when and get paid quickly! Register now via our app.": "Every day we match hospitality staff & hospitality companies! Are you a freelance waitress and looking for a new assignment? You choose where and when and get paid quickly! Register now via our app.",
        "Every day we match Top Chefs & hospitality companies! Are you a freelance chef looking for a new assignment? You choose where and when and get paid quickly! Register now via our app.": "Every day we match Top Chefs & hospitality companies! Are you a freelance chef looking for a new assignment? You choose where and when and get paid quickly! Register now via our app.",
        "Every day we match Top Chefs & Restaurants! With success, because we do not work with temporary or permanent contracts. You determine the shifts yourself! So do you want another Top Chef? No problem, because you choose.": "Every day we match Top Chefs & Restaurants! With success, because we do not work with temporary or permanent contracts. You determine the shifts yourself! So do you want another Top Chef? No problem, because you choose.",
        "Everyone": "Everyone",
        "Every single day we match Top-Chefs & Restaurants! And with success, because we don’t work with fixed-term and permanent contracts. Or on the other side; do you want to work in different restaurants? No problem. You decide!": "Every single day we match Top-Chefs & Restaurants! And with success, because we don’t work with fixed-term and permanent contracts. Or on the other side; do you want to work in different restaurants? No problem. You decide!",
        "Every single day we match Top-Chefs & Restaurants! And with success, because we don’t work with fixed-term and permanent contracts. So do you want another Top-Chef? No problem. You decide!": "Every single day we match Top-Chefs & Restaurants! And with success, because we don’t work with fixed-term and permanent contracts. So do you want another Top-Chef? No problem. You decide!",
        "Every single day we match Top-Chefs & Restaurants! And with success, because we don’t work with fixed-term and permanent contracts. So do you want another Top-Chef? Or on the other side; do you want to work in different restaurants? No problem. You decide!": "Every single day we match Top-Chefs & Restaurants! And with success, because we don’t work with fixed-term and permanent contracts. So do you want another Top-Chef? Or on the other side; do you want to work in different restaurants? No problem. You decide!",
        "ex.btw": "ex.btw",
        "Example: NL123456789B00": "Example: NL123456789B00",
        "Excerpt": "Excerpt",
        "excerpt": "excerpt",
        "Expand": "Expand",
        "Expand All": "Expand All",
        "Expectation Failed": "Expectation Failed",
        "Explanation": "Explanation",
        "Export": "Export",
        "Export Shifts": "Export Shifts",
        "Export shifts": "Export shifts",
        "F": "F",
        "Failed Dependency": "Failed Dependency",
        "FAQ": "FAQ",
        "FAQ chef": "FAQ chef",
        "FAQ employer": "FAQ employer",
        "FAQ general": "FAQ general",
        "FAQs": "FAQs",
        "Fast payment, between 10 and 12 days.": "Fast payment, between 10 and 12 days.",
        "Fast payment.": "Fast payment.",
        "Favorite {model}": "Favorite {model}",
        "Favorite freelancers": "Favorite freelancers",
        "Favorites": "Favorites",
        "filament-breezy:{default}.clipboard.link": "filament-breezy:{default}.clipboard.link",
        "filament-breezy:{default}.clipboard.tooltip": "filament-breezy:{default}.clipboard.tooltip",
        "filament-breezy:{default}.profile.2fa.description": "filament-breezy:{default}.profile.2fa.description",
        "filament-breezy:{default}.profile.2fa.enabled.description": "filament-breezy:{default}.profile.2fa.enabled.description",
        "filament-breezy:{default}.profile.2fa.enabled.store_codes": "filament-breezy:{default}.profile.2fa.enabled.store_codes",
        "filament-breezy:{default}.profile.2fa.enabled.title": "filament-breezy:{default}.profile.2fa.enabled.title",
        "filament-breezy:{default}.profile.2fa.finish_enabling.description": "filament-breezy:{default}.profile.2fa.finish_enabling.description",
        "filament-breezy:{default}.profile.2fa.finish_enabling.title": "filament-breezy:{default}.profile.2fa.finish_enabling.title",
        "filament-breezy:{default}.profile.2fa.must_enable": "filament-breezy:{default}.profile.2fa.must_enable",
        "filament-breezy:{default}.profile.2fa.not_enabled.description": "filament-breezy:{default}.profile.2fa.not_enabled.description",
        "filament-breezy:{default}.profile.2fa.not_enabled.title": "filament-breezy:{default}.profile.2fa.not_enabled.title",
        "filament-breezy:{default}.profile.2fa.setup_key": "filament-breezy:{default}.profile.2fa.setup_key",
        "filament-breezy:{default}.profile.2fa.title": "filament-breezy:{default}.profile.2fa.title",
        "filament-breezy:{default}.profile.password.heading": "filament-breezy:{default}.profile.password.heading",
        "filament-breezy:{default}.profile.password.subheading": "filament-breezy:{default}.profile.password.subheading",
        "filament-breezy:{default}.profile.password.submit.label": "filament-breezy:{default}.profile.password.submit.label",
        "filament-breezy:{default}.profile.personal_info.heading": "filament-breezy:{default}.profile.personal_info.heading",
        "filament-breezy:{default}.profile.personal_info.subheading": "filament-breezy:{default}.profile.personal_info.subheading",
        "filament-breezy:{default}.profile.personal_info.submit.label": "filament-breezy:{default}.profile.personal_info.submit.label",
        "filament-breezy:{default}.profile.sanctum.copied.label": "filament-breezy:{default}.profile.sanctum.copied.label",
        "filament-breezy:{default}.profile.sanctum.create.message": "filament-breezy:{default}.profile.sanctum.create.message",
        "filament-breezy:{default}.profile.sanctum.description": "filament-breezy:{default}.profile.sanctum.description",
        "filament-breezy:{default}.profile.sanctum.title": "filament-breezy:{default}.profile.sanctum.title",
        "File": "File",
        "Files": "Files",
        "Filled": "Filled",
        "Filled in shiftbase": "Filled in shiftbase",
        "Fill in the 6-digit code": "Fill in the 6-digit code",
        "Fill shift": "Fill shift",
        "Filter by {attribute}": "Filter by {attribute}",
        "Finding the right freelance chef or working at the place you would like to work is not easy. With our platform, we are now changing that and can connect Top-Chefs and restaurants quickly and easily. Because both restaurants and chefs have Top-Chefs subscriptions, we create a place where they meet. With our new platform, we are the matchmaker in the culinary world!": "Finding the right freelance chef or working at the place you would like to work is not easy. With our platform, we are now changing that and can connect Top-Chefs and restaurants quickly and easily. Because both restaurants and chefs have Top-Chefs subscriptions, we create a place where they meet. With our new platform, we are the matchmaker in the culinary world!",
        "Finding the right independent cook is not easy. The Top-Chefs platform will change that! We can connect freelance chefs and restaurants quickly and easily. Because both the catering companies and the independent chefs have a Top Chefs subscription, we create a place where they meet. With our new platform we are the matchmaker in the culinary world.": "Finding the right independent cook is not easy. The Top-Chefs platform will change that! We can connect freelance chefs and restaurants quickly and easily. Because both the catering companies and the independent chefs have a Top Chefs subscription, we create a place where they meet. With our new platform we are the matchmaker in the culinary world.",
        "Finding the right independent cook is not easy. The Top-Chefs platform will change that! We cann                        connect freelance chefs and restaurants quickly and easily. Because both the catering companiesn                        and the independent chefs have a Top Chefs subscription, we create a place where they meet. Withn                        our new platform we are the matchmaker in the culinary world.": "Finding the right independent cook is not easy. The Top-Chefs platform will change that! We cann                        connect freelance chefs and restaurants quickly and easily. Because both the catering companiesn                        and the independent chefs have a Top Chefs subscription, we create a place where they meet. Withn                        our new platform we are the matchmaker in the culinary world.",
        "Find the perfect {brand} for your open shift": "Find the perfect {brand} for your open shift",
        "Find the perfect chefs for your open shift | Top-Chefs": "Find the perfect chefs for your open shift | Top-Chefs",
        "Find the perfect Top-Chef for your open shift": "Find the perfect Top-Chef for your open shift",
        "Find the right freelance chef or work in the place where you would like to work. This wasn’t that easy. But now, we can connect restaurants and Top Chefs fast and easy. Because both the restaurants and the chefs subscribe to Top-Chefs, we create a place where both meet. With our new app we are the Matchmaker in the culinary world! Of course you ask yourself the question if it’s not a lot of work for you. The answer is no, because guess what… Top-Chefs takes care of all the administrative work. All you have to do is sign up in our user-friendly app. After your subscription you can choose the shift or chef you want. You decide and we’ll do the work!": "Find the right freelance chef or work in the place where you would like to work. This wasn’t that easy. But now, we can connect restaurants and Top Chefs fast and easy. Because both the restaurants and the chefs subscribe to Top-Chefs, we create a place where both meet. With our new app we are the Matchmaker in the culinary world! Of course you ask yourself the question if it’s not a lot of work for you. The answer is no, because guess what… Top-Chefs takes care of all the administrative work. All you have to do is sign up in our user-friendly app. After your subscription you can choose the shift or chef you want. You decide and we’ll do the work!",
        "Find the right freelance chef or work in the place where you would like to work. This wasn’t that easy. But now, we can connect restaurants and Top Chefs fast and easy. Because both the restaurants and the chefs subscribe to Top-Chefs, we create a place where both meet. With our new platform we are the Matchmaker in the culinary world! Of course you ask yourself the question if it’s not a lot of work for you. The answer is no, because guess what… Top-Chefs takes care of all the administrative work. All you have to do is sign up. After your subscription you can choose the shift or chef you want. You decide and we’ll do the work!": "Find the right freelance chef or work in the place where you would like to work. This wasn’t that easy. But now, we can connect restaurants and Top Chefs fast and easy. Because both the restaurants and the chefs subscribe to Top-Chefs, we create a place where both meet. With our new platform we are the Matchmaker in the culinary world! Of course you ask yourself the question if it’s not a lot of work for you. The answer is no, because guess what… Top-Chefs takes care of all the administrative work. All you have to do is sign up. After your subscription you can choose the shift or chef you want. You decide and we’ll do the work!",
        "Find your perfect Top-Chef now": "Find your perfect Top-Chef now",
        "Finish enabling two factor authentication.": "Finish enabling two factor authentication.",
        "First Name": "First Name",
        "First name": "First name",
        "First Name*": "First Name*",
        "Follow us": "Follow us",
        "Food and drinks included": "Food and drinks included",
        "Food and drinks included?": "Food and drinks included?",
        "Forbidden": "Forbidden",
        "for effective management and policy;": "for effective management and policy;",
        "Forgot password?": "Forgot password?",
        "Forgot your CoC number? You can also type in the name of your company.": "Forgot your CoC number? You can also type in the name of your company.",
        "Forgot Your Password?": "Forgot Your Password?",
        "Forgot your password?": "Forgot your password?",
        "Forgot your password? No problem. Just let us know your email address and we will email you a password reset link that will allow you to choose a new one.": "Forgot your password? No problem. Just let us know your email address and we will email you a password reset link that will allow you to choose a new one.",
        "For which freelancers": "For which freelancers",
        "For which TOP-CHEFS?": "For which TOP-CHEFS?",
        "For your security, please confirm your password to continue.": "For your security, please confirm your password to continue.",
        "Found": "Found",
        "Foundation": "Foundation",
        "Founder": "Founder",
        "Fr": "Fr",
        "Framework agreement": "Framework agreement",
        "Framework agreement signed": "Framework agreement signed",
        "Free app usage": "Free app usage",
        "Freelancer": "Freelancer",
        "freelancer": "freelancer",
        "Freelancer (optional)": "Freelancer (optional)",
        "Freelancer applied notification sent": "Freelancer applied notification sent",
        "Freelancer blocked": "Freelancer blocked",
        "Freelancer has already applied for overlapping shift": "Freelancer has already applied for overlapping shift",
        "Freelancer has multiple shifts on same day": "Freelancer has multiple shifts on same day",
        "Freelancer has one or more overlapping shifts": "Freelancer has one or more overlapping shifts",
        "Freelancer Hours": "Freelancer Hours",
        "Freelancers": "Freelancers",
        "freelancers": "freelancers",
        "Freelancers can only use our mobile app to login": "Freelancers can only use our mobile app to login",
        "Freelancer unblocked": "Freelancer unblocked",
        "Free parking": "Free parking",
        "Free use app": "Free use app",
        "Free use of the Top-Chefs app, in which you can register for free and quickly.": "Free use of the Top-Chefs app, in which you can register for free and quickly.",
        "Frequently Asked Questions": "Frequently Asked Questions",
        "From": "From",
        "From a chef to chef!<br /> Top-Chefs stands for reliability, loyalty, quality & craftmanship. Not hiring but collaboration!": "From a chef to chef!<br /> Top-Chefs stands for reliability, loyalty, quality & craftmanship. Not hiring but collaboration!",
        "From the authenticator app": "From the authenticator app",
        "Function": "Function",
        "Gallery": "Gallery",
        "Gateway Timeout": "Gateway Timeout",
        "General": "General",
        "General questions": "General questions",
        "General terms": "General terms",
        "general terms": "general terms",
        "Get in touch": "Get in touch",
        "Get paid instantly": "Get paid instantly",
        "Get paid quickly": "Get paid quickly",
        "Get started!": "Get started!",
        "Get started and get paid weekly": "Get started and get paid weekly",
        "Give freelance chefs reviews.": "Give freelance chefs reviews.",
        "Given {model}": "Given {model}",
        "Go Home": "Go Home",
        "Gone": "Gone",
        "Good": "Good",
        "Google Authenticator": "Google Authenticator",
        "Go to page {page}": "Go to page {page}",
        "Great! You have accepted the invitation to join the {team} team.": "Great! You have accepted the invitation to join the {team} team.",
        "Head Development": "Head Development",
        "Header": "Header",
        "Head Marketing": "Head Marketing",
        "Helaas, er zijn geen shifts met deze filters gevonden. Pas deze aan en zoek opnieuw.": "Helaas, er zijn geen shifts met deze filters gevonden. Pas deze aan en zoek opnieuw.",
        "Hello!": "Hello!",
        "Hi": "Hi",
        "Hi {firstName}!": "Hi {firstName}!",
        "Hi {userName}": "Hi {userName}",
        "Hidden": "Hidden",
        "Hide": "Hide",
        "Hide {name}": "Hide {name}",
        "Hire a Chef": "Hire a Chef",
        "Hi Support,": "Hi Support,",
        "Hi Top-Chef": "Hi Top-Chef",
        "Home": "Home",
        "Homepage": "Homepage",
        "hospitality business?": "hospitality business?",
        "hospitality company": "hospitality company",
        "hour": "hour",
        "Hourly Cost": "Hourly Cost",
        "Hourly cost for {position}": "Hourly cost for {position}",
        "Hours": "hours",
        "hours": "hours",
        "Hours accredited": "Hours accredited",
        "Hours registration in the app/platform, direct to shift approval.": "Hours registration in the app/platform, direct to shift approval.",
        "How can we help you?": "How can we help you?",
        "How does it work?": "How does it work?",
        "How do I call in sick?": "How do I call in sick?",
        "How many shifts can I upload?": "How many shifts can I upload?",
        "How much experience do I need to work through Top-Chefs?": "How much experience do I need to work through Top-Chefs?",
        "HTTP Version Not Supported": "HTTP Version Not Supported",
        "I'm a teapot": "I'm a teapot",
        "I'm a Top-Chef questions": "I'm a Top-Chef questions",
        "I'm looking for personnel": "I'm looking for personnel",
        "I'm looking for work": "I'm looking for work",
        "I agree to the": "I agree to the",
        "I agree to the {terms_of_service} and {privacy_policy}": "I agree to the {terms_of_service} and {privacy_policy}",
        "I am a test notification from {appName}": "I am a test notification from {appName}",
        "I am a Top-Chef!": "I am a Top-Chef!",
        "I am looking to:": "I am looking to:",
        "IBAN": "IBAN",
        "Id card": "Id card",
        "I declare that I have read and agree to the": "I declare that I have read and agree to the",
        "Identification": "Identification",
        "identification": "identification",
        "Identification back": "Identification back",
        "Identification front": "Identification front",
        "Identification number": "Identification number",
        "Identification number valid until": "Identification number valid until",
        "Identification type": "Identification type",
        "If necessary, you may log out of all of your other browser sessions across all of your devices. Some of your recent sessions are listed below; however, this list may not be exhaustive. If you feel your account has been compromised, you should also update your password.": "If necessary, you may log out of all of your other browser sessions across all of your devices. Some of your recent sessions are listed below; however, this list may not be exhaustive. If you feel your account has been compromised, you should also update your password.",
        "If the employer is not satisfied, they can choose to block you. The shifts of this client will then no longer show up on your timeline.": "If the employer is not satisfied, they can choose to block you. The shifts of this client will then no longer show up on your timeline.",
        "If this {model} needs to be removed, use the button below": "If this {model} needs to be removed, use the button below",
        "If you're having trouble clicking the \"{actionText}\" button, copy and paste the URL below into your web browser:": "If you're having trouble clicking the \"{actionText}\" button, copy and paste the URL below into your web browser:",
        "If you're having trouble clicking the \"{actionText}\" button, copy and paste the URL belowninto your web browser:": "If you're having trouble clicking the \"{actionText}\" button, copy and paste the URL belowninto your web browser:",
        "If you already have an account, you may accept this invitation by clicking the button below:": "If you already have an account, you may accept this invitation by clicking the button below:",
        "If you created this {model} by mistake, it can be removed.": "If you created this {model} by mistake, it can be removed.",
        "If you did not create an account, no further action is required.": "If you did not create an account, no further action is required.",
        "If you did not expect to receive an invitation to this team, you may discard this email.": "If you did not expect to receive an invitation to this team, you may discard this email.",
        "If you did not receive the email": "If you did not receive the email",
        "If you did not request a password reset, no further action is required.": "If you did not request a password reset, no further action is required.",
        "If you do not have an account, you may create one by clicking the button below. After creating an account, you may click the invitation acceptance button in this email to accept the team invitation:": "If you do not have an account, you may create one by clicking the button below. After creating an account, you may click the invitation acceptance button in this email to accept the team invitation:",
        "If you encounter any difficulties during the registration process, please do not hesitate to send us the necessary information at support@top-chefs.nl. Our team stands ready to assist you at any time.": "If you encounter any difficulties during the registration process, please do not hesitate to send us the necessary information at support{'@'}top-chefs.nl. Our team stands ready to assist you at any time.",
        "If you have any other questions and/or comments, please contact our support department at: {telNo}": "If you have any other questions and/or comments, please contact our support department at: {telNo}",
        "If you have not yet done so, you can directly create a location under the settings. You can also add your staff members under each location. Once you have created a location, you can immediately schedule your services.": "If you have not yet done so, you can directly create a location under the settings. You can also add your staff members under each location. Once you have created a location, you can immediately schedule your services.",
        "If you think this was a mistake, please contact us via email or phone.": "If you think this was a mistake, please contact us via email or phone.",
        "If you want to use your rights, you can make this known verbally or by means of an email. This email can be sent to backoffice@top-chefs.nl. Your interests can also represented by a representative (such as a written proxy, or your trustee or mentor).": "If you want to use your rights, you can make this known verbally or by means of an email. This email can be sent to backoffice{'@'}top-chefs.nl. Your interests can also represented by a representative (such as a written proxy, or your trustee or mentor).",
        "If you want to use your rights, you can make this known verbally or by means of an email. This email can be sent to backoffice@top - chefs.nl.Your interests can also represented by a representative(such as a written proxy, or your trustee or mentor). mentor).": "If you want to use your rights, you can make this known verbally or by means of an email. This email can be sent to backoffice{'@'}top - chefs.nl.Your interests can also represented by a representative(such as a written proxy, or your trustee or mentor). mentor).",
        "If you want to use your rights, you can make this known verbally or by means of an email. This email can be sent to backoffice@top-chefs.nl . Your interests can also represented by a representative (such as a written proxy, or your trustee or mentor). mentor).": "If you want to use your rights, you can make this known verbally or by means of an email. This email can be sent to backoffice{'@'}top-chefs.nl . Your interests can also represented by a representative (such as a written proxy, or your trustee or mentor). mentor).",
        "If you want to use your rights, you can make this known verbally or by means of an email. Thisn                        email can be sent to backoffice@top-chefs.nl . Your interests can also represented by an                        representative (such as a written proxy, or your trustee or mentor). mentor).": "If you want to use your rights, you can make this known verbally or by means of an email. Thisn                        email can be sent to backoffice{'@'}top-chefs.nl . Your interests can also represented by an                        representative (such as a written proxy, or your trustee or mentor). mentor).",
        "I have saved the backup codes": "I have saved the backup codes",
        "Image": "Image",
        "Image impression": "Image impression",
        "Image of app": "Image of app",
        "Image of laptop": "Image of laptop",
        "Impersonate": "Impersonate",
        "Impersonation": "Impersonation",
        "Import": "Import",
        "Import {name}": "Import {name}",
        "Impression image": "Impression image",
        "IM Used": "IM Used",
        "Inactive freelancers export": "Inactive freelancers export",
        "In addition, we take care of all the administration, through the online registration system in the Top-Chefs app. At a glance, you can easily check your hours on our applications. By using our platform, you create a freelance team around your catering company, so you always have enough catering staff.": "In addition, we take care of all the administration, through the online registration system in the Top-Chefs app. At a glance, you can easily check your hours on our applications. By using our platform, you create a freelance team around your catering company, so you always have enough catering staff.",
        "In addition to being registered at the Chamber of Commerce (KvK), you are expected to be able to work on your own.": "In addition to being registered at the Chamber of Commerce (KvK), you are expected to be able to work on your own.",
        "In addition to my family and permanent job, I like having Top-Chefs put me away at different locations, I can decide when and where I work, and the payments are always fast. I have already been able to work with the Top Chefs app. From registering to accepting services and time registration is fast and flawless. The option to chat with employers in case of any problems or questions is also nice. Writing mutual reviews motivates me to work hard, but also gives me a say in how we the “chefs” are treated on location.": "In addition to my family and permanent job, I like having Top-Chefs put me away at different locations, I can decide when and where I work, and the payments are always fast. I have already been able to work with the Top Chefs app.nnFrom registering to accepting services and time registration is fast and flawless. The option to chat with employers in case of any problems or questions is also nice. Writing mutual reviews motivates me to work hard, but also gives me a say in how we the “chefs” are treated on location.",
        "incl.btw": "incl.btw",
        "Independent": "Independent",
        "Independent chef": "Independent chef",
        "Independent chef price": "Independent chef price",
        "I need a Top-Chef!": "I need a Top-Chef!",
        "In how many days will my invoice be paid?": "In how many days will my invoice be paid?",
        "In principle, no processing takes place for other purposes.": "In principle, no processing takes place for other purposes.",
        "Insufficient Storage": "Insufficient Storage",
        "Internal remark": "Internal remark",
        "Internal Server Error": "Internal Server Error",
        "in the top 10 largest cities in the Netherlands. You can bet that this will provide opportunities for your career! Take a quick look at which shifts are open for you here.": "in the top 10 largest cities in the Netherlands. You can bet that this will provide opportunities for your career! Take a quick look at which shifts are open for you here.",
        "Introduction": "Introduction",
        "Invalid JSON was returned from the route.": "Invalid JSON was returned from the route.",
        "Invalid phone number": "Invalid phone number",
        "Invalid SSL Certificate": "Invalid SSL Certificate",
        "Invitation": "Invitation",
        "Invite": "Invite",
        "Invited": "Invited",
        "Invite freelancer": "Invite freelancer",
        "Invoice": "Invoice",
        "invoice": "invoice",
        "Invoice date": "Invoice date",
        "Invoice due date": "Invoice due date",
        "Invoice Number": "Invoice Number",
        "Invoice number": "Invoice number",
        "Invoice paid to freelancer": "Invoice paid to freelancer",
        "Invoice received from freelancer": "Invoice received from freelancer",
        "Invoice reference": "Invoice reference",
        "Invoices": "Invoices",
        "invoices": "invoices",
        "Invoice status": "Invoice status",
        "Is already a team members": "Is already a team members",
        "Is it possible to work directly with a restaurant after an assignment from Top-Chefs?": "Is it possible to work directly with a restaurant after an assignment from Top-Chefs?",
        "Is there a minimum number of hours you have to work?": "Is there a minimum number of hours you have to work?",
        "Is there free parking available on this location?": "Is there free parking available on this location?",
        "It is recommended to keep the {attribute} between {min} and {max} characters long": "It is recommended to keep the {attribute} between {min} and {max} characters long",
        "I want a Top-Chef": "I want a Top-Chef",
        "I want a Top-Chef questions": "I want a Top-Chef questions",
        "I’m a Top-Chef": "I’m a Top-Chef",
        "Just now": "Just now",
        "kilometer": "kilometer",
        "Kind regards,": "Kind regards,",
        "KM around": "KM around",
        "Known": "Known",
        "KVK number": "KVK number",
        "KvK number": "KvK number",
        "Language": "Language",
        "Language dropdown icon": "Language dropdown icon",
        "Language flag": "Language flag",
        "Lapsed": "Lapsed",
        "Last active": "Last active",
        "Last activity": "Last activity",
        "Last activity from": "Last activity from",
        "Last activity from/to": "Last activity from/to",
        "Last activity source": "Last activity source",
        "Last activity to": "Last activity to",
        "Last Name": "Last Name",
        "Last name": "Last name",
        "Last Name*": "Last Name*",
        "Last used": "Last used",
        "Latitude": "Latitude",
        "Leave": "Leave",
        "Leave empty to hide from public": "Leave empty to hide from public",
        "Leave Team": "Leave Team",
        "Legal form": "Legal form",
        "Length Required": "Length Required",
        "Let Top-Chefs fill this shift": "Let Top-Chefs fill this shift",
        "Let Top-Chefs pick one of my favorites to fill this shift": "Let Top-Chefs pick one of my favorites to fill this shift",
        "Like": "Like",
        "Liked": "Liked",
        "Limited company": "limited company",
        "limited company": "limited company",
        "Limited partnership": "limited partnership",
        "limited partnership": "limited partnership",
        "line": "line",
        "Load": "Load",
        "Localize": "Localize",
        "Location": "Location",
        "location": "location",
        "Location {location} has been updated": "Location {location} has been updated",
        "Location blocked": "Location blocked",
        "Location created for {companyName}": "Location created for {companyName}",
        "Location Hours": "Location Hours",
        "Location icon": "Location icon",
        "Location name": "Location name",
        "location radius": "location radius",
        "Locations": "Locations",
        "locations": "locations",
        "Location unblocked": "Location unblocked",
        "Location was created for {companyName}": "Location was created for {companyName}",
        "Locked": "Locked",
        "Log in": "Log in",
        "Login": "Login",
        "Login icon": "Login icon",
        "Login with a recovery code.": "Login with a recovery code.",
        "Log Out": "Log Out",
        "Logout": "Logout",
        "Log Out Other Browser Sessions": "Log Out Other Browser Sessions",
        "Longitude": "Longitude",
        "Loop Detected": "Loop Detected",
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
        "M": "M",
        "Mailadres": "Mailadres",
        "Mail to": "Mail to",
        "Maintenance Mode": "Maintenance Mode",
        "Make appointment": "Make appointment",
        "Manage Account": "Manage Account",
        "Manage and log out your active sessions on other browsers and devices.": "Manage and log out your active sessions on other browsers and devices.",
        "Manage API Tokens": "Manage API Tokens",
        "Manage Role": "Manage Role",
        "Manage Team": "Manage Team",
        "Manual": "Manual",
        "Margin": "Margin",
        "Margin per hour": "Margin per hour",
        "Margin per hour for {position}": "Margin per hour for {position}",
        "Mark as open": "Mark as open",
        "Mark as paid": "Mark as paid",
        "Marketing": "Marketing",
        "Maximum credit": "Maximum credit",
        "Maximum traveling costs": "Maximum traveling costs",
        "Mediation": "Mediation",
        "Message": "Message",
        "Message*": "Message*",
        "Message [{LOCALE}]": "Message [{LOCALE}]",
        "Meta description": "Meta description",
        "Method Not Allowed": "Method Not Allowed",
        "Microsoft Authenticator": "Microsoft Authenticator",
        "Misdirected Request": "Misdirected Request",
        "Mitchel": "Mitchel",
        "Mo": "Mo",
        "Mo-fr from 8{00} - 18{00}": "Mo-fr from 8{00} - 18{00}",
        "Mobile number": "Mobile number",
        "Moment": "Moment",
        "Month": "Month",
        "Monthly": "Monthly",
        "Mood image 1": "Mood image 1",
        "Mood image 2": "Mood image 2",
        "Mood image 3": "Mood image 3",
        "Mood image 4": "Mood image 4",
        "Mood image 5": "Mood image 5",
        "Mood image 6": "Mood image 6",
        "Mood image 7": "Mood image 7",
        "More information": "More information",
        "Moved Permanently": "Moved Permanently",
        "Multi-Status": "Multi-Status",
        "Multiple Choices": "Multiple Choices",
        "Muted": "Muted",
        "N/A": "N/A",
        "Name": "Name",
        "name": "name",
        "Name: {employerName}": "Name: {employerName}",
        "Name: {name}": "Name: {name}",
        "Nationality": "Nationality",
        "Needs verification": "Needs verification",
        "Netherlands": "Netherlands",
        "Network Authentication Required": "Network Authentication Required",
        "Network Connect Timeout Error": "Network Connect Timeout Error",
        "Network Read Timeout Error": "Network Read Timeout Error",
        "New": "New",
        "New {attribute}": "New {attribute}",
        "New {model}": "New {model}",
        "New {name}": "New {name}",
        "New contact form from {$this->message->email}!": "New contact form from {$this->message->email}!",
        "New employer registered": "New employer registered",
        "New freelancer company": "New freelancer company",
        "New message from Top-Chefs": "New message from Top-Chefs",
        "New Password": "New Password",
        "New password: *": "New password: *",
        "News": "News",
        "Newsletter": "Newsletter",
        "New unfinished registration": "New unfinished registration",
        "Next": "Next",
        "Next page": "Next page",
        "Nickname": "Nickname",
        "No": "No",
        "No, in the contracts and general terms & conditions is written that all assignments run through the platform. Our quality managers will visit the restaurants. In case of violation of the general terms & conditions, we will act immediately.": "No, in the contracts and general terms & conditions is written that all assignments run through the platform. Our quality managers will visit the restaurants. In case of violation of the general terms & conditions, we will act immediately.",
        "No, the time registration is done within the app. After the shift you can enter your hours. The employer can then immediately approve the hours you’ve worked.": "No, the time registration is done within the app. After the shift you can enter your hours. The employer can then immediately approve the hours you’ve worked.",
        "No, we only work with catering related companies.": "No, we only work with catering related companies.",
        "No {model} found": "No {model} found",
        "No available shifts": "No available shifts",
        "No budget known": "No budget known",
        "No Content": "No Content",
        "No files found": "No files found",
        "Non-Authoritative Information": "Non-Authoritative Information",
        "None": "None",
        "No rating": "No rating",
        "No surcharges exist": "No surcharges exist",
        "Not Acceptable": "Not Acceptable",
        "Not Extended": "Not Extended",
        "Not Found": "Not Found",
        "Notification sent!": "Notification sent!",
        "Not Implemented": "Not Implemented",
        "Not Modified": "Not Modified",
        "Not verified": "Not verified",
        "Number": "Number",
        "Number of {models}": "Number of {models}",
        "of": "of",
        "Oh no": "Oh no",
        "OK": "OK",
        "Once a team is deleted, all of its resources and data will be permanently deleted. Before deleting this team, please download any data or information regarding this team that you wish to retain.": "Once a team is deleted, all of its resources and data will be permanently deleted. Before deleting this team, please download any data or information regarding this team that you wish to retain.",
        "Once your account is deleted, all of its resources and data will be permanently deleted. Before deleting your account, please download any data or information that you wish to retain.": "Once your account is deleted, all of its resources and data will be permanently deleted. Before deleting your account, please download any data or information that you wish to retain.",
        "Only favorites": "Only favorites",
        "Only favorites can respond to your shift, show to all freelancers? (This action is irreversible)": "Only favorites can respond to your shift, show to all freelancers? (This action is irreversible)",
        "On your phone or tablet with camera. We can advise you to use {google} or {microsoft}.": "On your phone or tablet with camera. We can advise you to use {google} or {microsoft}.",
        "Oops": "Oops",
        "Open": "Open",
        "Open {model}": "Open {model}",
        "Open blog": "Open blog",
        "Open budget": "Open budget",
        "Open general terms": "Open general terms",
        "Open in a current window": "Open in a current window",
        "Open in a new window": "Open in a new window",
        "Open in a parent frame": "Open in a parent frame",
        "Opening hours support:": "Opening hours support:",
        "Open in the topmost frame": "Open in the topmost frame",
        "Open on the website": "Open on the website",
        "Open the authenticator app, add a new entry and scan the QR code": "Open the authenticator app, add a new entry and scan the QR code",
        "Operating employee!": "Operating employee!",
        "Optional. If provided, the user is redirected to the shift when clicking on the notification.": "Optional. If provided, the user is redirected to the shift when clicking on the notification.",
        "Order": "Order",
        "Order Column": "Order Column",
        "or download our Top-Chefs app and place your first shift!": "or download our Top-Chefs app and place your first shift!",
        "Origin Is Unreachable": "Origin Is Unreachable",
        "Our account manager Mitchel is here for you!": "Our account manager Mitchel is here for you!",
        "Our clients": "Our clients",
        "Our platform is designed to enable people like you to easily find and apply for shifts that align with your expertise and schedule. To fully utilize this feature, we kindly ask that you complete your registration through our app.": "Our platform is designed to enable people like you to easily find and apply for shifts that align with your expertise and schedule. To fully utilize this feature, we kindly ask that you complete your registration through our app.",
        "Our support is there for you in case of questions and/or problems.": "Our support is there for you in case of questions and/or problems.",
        "Our support team is there for you, should you have any questions.": "Our support team is there for you, should you have any questions.",
        "Our Team": "Our Team",
        "Our Top Chefs love it!": "Our Top Chefs love it!",
        "Overview {models}": "Overview {models}",
        "Owner": "Owner",
        "Owner of the selected company": "Owner of the selected company",
        "Owner restaurant VADEVI & Sonah catering": "Owner restaurant VADEVI & Sonah catering",
        "Page Expired": "Page Expired",
        "Page item": "Page item",
        "Pagination Navigation": "Pagination Navigation",
        "Paid": "Paid",
        "Partial Content": "Partial Content",
        "Partnership": "partnership",
        "partnership": "partnership",
        "Passport": "Passport",
        "Password": "Password",
        "password": "password",
        "Paste or type the code we sent a code to your e-mail.": "Paste or type the code we sent a code to your e-mail.",
        "Paste or type the code we sent a code to your phone.": "Paste or type the code we sent a code to your phone.",
        "PAY ATTENTION! Messages should only be used for TOP-CHEFS services. Use for personal purposes, recruitment or harassment of TOP-CHEFS is not allowed.": "PAY ATTENTION! Messages should only be used for TOP-CHEFS services. Use for personal purposes, recruitment or harassment of TOP-CHEFS is not allowed.",
        "Payload Too Large": "Payload Too Large",
        "Payment period": "Payment period",
        "Payment Required": "Payment Required",
        "Pay no connection and usage costs": "Pay no connection and usage costs",
        "Pay no connection and usage fees.": "Pay no connection and usage fees.",
        "Pending Team Invitations": "Pending Team Invitations",
        "per {model}": "per {model}",
        "Percentage": "Percentage",
        "Percentage on top of the regular wages": "Percentage on top of the regular wages",
        "per hour": "per hour",
        "Permanently delete this team.": "Permanently delete this team.",
        "Permanently delete your account.": "Permanently delete your account.",
        "Permanent Redirect": "Permanent Redirect",
        "Permissions": "Permissions",
        "per Shift": "per Shift",
        "Person is authorized to sign.": "Person is authorized to sign.",
        "Phone Number": "Phone Number",
        "Phone number": "Phone number",
        "phone number": "phone number",
        "Phone number: {employerPhoneNumber}": "Phone number: {employerPhoneNumber}",
        "Photo": "Photo",
        "Photo identification matches the data.": "Photo identification matches the data.",
        "Place name": "Place name",
        "Planning": "Planning",
        "Platform cost": "Platform cost",
        "Please add a location at the bottom under {companyName} and you're good to go!": "Please add a location at the bottom under {companyName} and you're good to go!",
        "Please check with client expectations.": "Please check with client expectations.",
        "Please click the button below to checkout your applicants and approve them for your shift.": "Please click the button below to checkout your applicants and approve them for your shift.",
        "Please click the button below to verify your email address.": "Please click the button below to verify your email address.",
        "Please confirm access to your account by entering one of your emergency recovery codes.": "Please confirm access to your account by entering one of your emergency recovery codes.",
        "Please confirm access to your account by entering the authentication code provided by your authenticator application.": "Please confirm access to your account by entering the authentication code provided by your authenticator application.",
        "Please copy your new API token. For your security, it won't be shown again.": "Please copy your new API token. For your security, it won't be shown again.",
        "Please enter your password to confirm you would like to log out of your other browser sessions across all of your devices.": "Please enter your password to confirm you would like to log out of your other browser sessions across all of your devices.",
        "Please indicate if it's possible to park for free.": "Please indicate if it's possible to park for free.",
        "Please indicate if Top-Chefs should pick one of your favorites.": "Please indicate if Top-Chefs should pick one of your favorites.",
        "Please indicate the budget.": "Please indicate the budget.",
        "Please note! Our rates may fluctuate seasonally, due to crowds or the number of queries. <br /> Fixed price agreements are negotiable! If so, please contact our support: {email}": "Please note! Our rates may fluctuate seasonally, due to crowds or the number of queries. <br /> Fixed price agreements are negotiable! If so, please contact our support: {email}",
        "Please note, when email address is changed, the current user no longer has access to this company. In addition, a new user will be created with the provided fields (if the email address is not already in use).": "Please note, when email address is changed, the current user no longer has access to this company. In addition, a new user will be created with the provided fields (if the email address is not already in use).",
        "Please note, when email address is changed, the current user no longer has access to this company.n                    In addition, a new user will be created with the provided fields (if the email address is notn                    already in use).": "Please note, when email address is changed, the current user no longer has access to this company.n                    In addition, a new user will be created with the provided fields (if the email address is notn                    already in use).",
        "Please pay the invoice amount as disclosed the payment terms. Our bank details are stated on the invoice. In case of a credit note, we would like to receive your bank details. You can send this to {email}. We will then transfer the credit amount to you as soon as possible. Please note that refunds are only possible via bank transfer.": "Please pay the invoice amount as disclosed the payment terms. Our bank details are stated on the invoice. In case of a credit note, we would like to receive your bank details. You can send this to {email}. We will then transfer the credit amount to you as soon as possible. Please note that refunds are only possible via bank transfer.",
        "Please provide the email address of the person you would like to add to this team.": "Please provide the email address of the person you would like to add to this team.",
        "Please retract your shift from the platform so somebody else can apply for it. Please do this at least 6 hours in advance.": "Please retract your shift from the platform so somebody else can apply for it. Please do this at least 6 hours in advance.",
        "Please use the international notation, +31612345678": "Please use the international notation, +31612345678",
        "Position": "Position",
        "Positions": "Positions",
        "positions": "positions",
        "Positions: Hourly costs": "Positions: Hourly costs",
        "Positions: Margin per hour": "Positions: Margin per hour",
        "Postal code": "Postal code",
        "Precondition Failed": "Precondition Failed",
        "Precondition Required": "Precondition Required",
        "Premium Badge": "Premium Badge",
        "Premium badge": "Premium badge",
        "Press 1 after calling": "Press 1 after calling",
        "Preview": "Preview",
        "Previous page": "Previous page",
        "Price": "Price",
        "Prices": "Prices",
        "Pricing": "Pricing",
        "Print": "Print",
        "Privacy declaration": "Privacy declaration",
        "Privacy Policy": "Privacy Policy",
        "privacy policy": "privacy policy",
        "Privacy policy: Top-chefs bv": "Privacy policy: Top-chefs bv",
        "Privacy Statement": "Privacy Statement",
        "Private company": "private company",
        "private company": "private company",
        "Processing": "Processing",
        "Profile": "Profile",
        "Profile {name}": "Profile {name}",
        "Profile data": "Profile data",
        "Profile Image": "Profile Image",
        "Profile Information": "Profile Information",
        "Profile information": "Profile information",
        "Profile picture": "Profile picture",
        "profile picture": "profile picture",
        "Properties": "Properties",
        "Propose new hours": "Propose new hours",
        "Proprietorship": "proprietorship",
        "proprietorship": "proprietorship",
        "Provide a reason for rejecting this company": "Provide a reason for rejecting this company",
        "Provision of your personal data to third parties": "Provision of your personal data to third parties",
        "Proxy Authentication Required": "Proxy Authentication Required",
        "Public": "Public",
        "Publication date": "Publication date",
        "Published": "Published",
        "Published on: {moment}": "Published on: {moment}",
        "Quantity": "Quantity",
        "Quarterly": "Quarterly",
        "Question": "Question",
        "Question (en)": "Question (en)",
        "Question (nl)": "Question (nl)",
        "Question or complaint": "Question or complaint",
        "Railgun Error": "Railgun Error",
        "Range Not Satisfiable": "Range Not Satisfiable",
        "Rate": "Rate",
        "Rating": "Rating",
        "rating": "rating",
        "Read more": "Read more",
        "Realised by": "Realised by",
        "Reason": "Reason",
        "Reason for blocking": "Reason for blocking",
        "Reason for decline": "Reason for decline",
        "Receive a clear invoice": "Receive a clear invoice",
        "Received {model}": "Received {model}",
        "Recovery Code": "Recovery Code",
        "Regards": "Regards",
        "Regenerate Recovery Codes": "Regenerate Recovery Codes",
        "Register": "Register",
        "Register as a Chef": "Register as a Chef",
        "Register as a Top-Chef": "Register as a Top-Chef",
        "Register as a Top Operating Officer": "Register as a Top Operating Officer",
        "Register in no-time": "Register in no-time",
        "Register within minutes.": "Register within minutes.",
        "Registration almost complete!": "Registration almost complete!",
        "Registration declined": "Registration declined",
        "Regular price": "Regular price",
        "Reinsert {model}": "Reinsert {model}",
        "Reject": "Reject",
        "Reject {model}": "Reject {model}",
        "Rejected": "Rejected",
        "Rejected Applications": "Rejected Applications",
        "Related blogs:": "Related blogs:",
        "Remark freelancer": "Remark freelancer",
        "Remember Me": "Remember Me",
        "Remember me": "Remember me",
        "Remove": "Remove",
        "Remove {attribute}, keep {second_attribute}": "Remove {attribute}, keep {second_attribute}",
        "Remove {attribute}, keep {second_attribute": "Remove {attribute}, keep {second_attribute",
        "Remove from favorites": "Remove from favorites",
        "Remove Photo": "Remove Photo",
        "Remove Team Member": "Remove Team Member",
        "Remove {attribute, keep {second_attribute}": "Remove {attribute, keep {second_attribute}",
        "Repeat {attribute}": "Repeat {attribute}",
        "Replace company": "Replace company",
        "Replaced": "Replaced",
        "Replace identification back": "Replace identification back",
        "Replace identification front": "Replace identification front",
        "Replace Profile Image": "Replace Profile Image",
        "Request Header Fields Too Large": "Request Header Fields Too Large",
        "Request Timeout": "Request Timeout",
        "Required Action": "Required Action",
        "Resend": "Resend",
        "Resend {attribute}": "Resend {attribute}",
        "Resend in": "Resend in",
        "Resend Verification Email": "Resend Verification Email",
        "Reset": "Reset",
        "Reset Content": "Reset Content",
        "Reset Password": "Reset Password",
        "Reset password": "Reset password",
        "Reset Password Notification": "Reset Password Notification",
        "Residency permit": "Residency permit",
        "Restore": "Restore",
        "Restore {name}": "Restore {name}",
        "results": "results",
        "Retract": "Retract",
        "Retract {model}": "Retract {model}",
        "Retracted": "Retracted",
        "Retry With": "Retry With",
        "Revenue": "Revenue",
        "Review": "Review",
        "review": "review",
        "Reviews": "Reviews",
        "reviews": "reviews",
        "Role": "Role",
        "S": "S",
        "Sa": "Sa",
        "Salary icon": "Salary icon",
        "Save": "Save",
        "Save & Close": "Save & Close",
        "Save & Return": "Save & Return",
        "Save {name}": "Save {name}",
        "Saved.": "Saved.",
        "Save these codes on a secure place if you can’t get access with your Authenticator app.": "Save these codes on a secure place if you can’t get access with your Authenticator app.",
        "Scanning not possible? Fill in this code in the app.": "Scanning not possible? Fill in this code in the app.",
        "Scan the QR code": "Scan the QR code",
        "Scheduled for: {moment}": "Scheduled for: {moment}",
        "Search": "Search",
        "Search {name}": "Search {name}",
        "Search by {attribute}": "Search by {attribute}",
        "sec": "sec",
        "See Other": "See Other",
        "Select": "Select",
        "Select a {model}": "Select a {model}",
        "Select a day": "Select a day",
        "Select All": "Select All",
        "Select a month": "Select a month",
        "Select A New Photo": "Select A New Photo",
        "Select an option": "Select an option",
        "Select Freelancer": "Select Freelancer",
        "Select Location": "Select Location",
        "Select Location/Freelancer": "Select Location/Freelancer",
        "Send": "Send",
        "Send invoice now": "Send invoice now",
        "Send notification": "Send notification",
        "Send Password Reset Link": "Send Password Reset Link",
        "Send to notification center": "Send to notification center",
        "Send us a message:": "Send us a message:",
        "Send via email": "Send via email",
        "Send via push notification": "Send via push notification",
        "Send via sms": "Send via sms",
        "Sent24u registration reminder": "Sent24u registration reminder",
        "SEO {attribute}": "SEO {attribute}",
        "SEO title": "SEO title",
        "Server Error": "Server Error",
        "Service Unavailable": "Service Unavailable",
        "Session Has Expired": "Session Has Expired",
        "Settings": "Settings",
        "Settings updated": "Settings updated",
        "Set Two Factor Authentication": "Set Two Factor Authentication",
        "Setup Key": "Setup Key",
        "Share": "Share",
        "Shift": "Shift",
        "shift": "shift",
        "Shift agreement": "Shift agreement",
        "Shiftbase API key": "Shiftbase API key",
        "Shiftbase Department": "Shiftbase Department",
        "Shift billing": "Shift billing",
        "shift contract": "shift contract",
        "Shift hours": "Shift hours",
        "Shift hours approved": "Shift hours approved",
        "Shift id": "Shift id",
        "Shift is already accredited or amended": "Shift is already accredited or amended",
        "Shift is not (yet) completed": "Shift is not (yet) completed",
        "Shift kopiëren": "Shift kopiëren",
        "Shifts": "Shifts",
        "shifts": "shifts",
        "Shifts already placed will not be updated with new default value.": "Shifts already placed will not be updated with new default value.",
        "Shifts available": "Shifts available",
        "Show": "Show",
        "Show (web)app": "Show (web)app",
        "Show {model}": "Show {model}",
        "Show {name}": "Show {name}",
        "Show All": "Show All",
        "Showing": "Showing",
        "Show on {attribute}": "Show on {attribute}",
        "Show Recovery Codes": "Show Recovery Codes",
        "Show to all freelancers": "Show to all freelancers",
        "Simple item": "Simple item",
        "Since it’s your first time logging in here, we’ve sent a code to your phone to verify your user profile.": "Since it’s your first time logging in here, we’ve sent a code to your phone to verify your user profile.",
        "Sincerely,": "Sincerely,",
        "Sitemap": "Sitemap",
        "Slug": "Slug",
        "sms": "sms",
        "Solution": "Solution",
        "Solve": "Solve",
        "Sous chef": "Sous chef",
        "Sous chef price": "Sous chef price",
        "specialties": "specialties",
        "Specialty": "Specialty",
        "Specific": "Specific",
        "SSL Handshake Failed": "SSL Handshake Failed",
        "Staff": "Staff",
        "staff shortage?": "staff shortage?",
        "Start": "Start",
        "Start Date": "Start Date",
        "Start date": "Start date",
        "Starting from": "Starting from",
        "Start new chat": "Start new chat",
        "Start time": "Start time",
        "start time": "start time",
        "Start week": "Start week",
        "Status": "Status",
        "statuses": "statuses",
        "statussen": "statussen",
        "Step {number}": "Step {number}",
        "Store {attribute}": "Store {attribute}",
        "Store changes": "Store changes",
        "Store these recovery codes in a secure password manager. They can be used to recover access to your account if your two factor authentication device is lost.": "Store these recovery codes in a secure password manager. They can be used to recover access to your account if your two factor authentication device is lost.",
        "Street name": "Street name",
        "Streetname 1": "Streetname 1",
        "Street Number": "Street Number",
        "Street Number Addition": "Street Number Addition",
        "Su": "Su",
        "Subject": "Subject",
        "subject": "subject",
        "Submit": "Submit",
        "Subscribe": "Subscribe",
        "Subtotal": "Subtotal",
        "Success": "Success",
        "Support": "Support",
        "Surcharges": "Surcharges",
        "Switch": "Switch",
        "Switching Protocols": "Switching Protocols",
        "Switch Teams": "Switch Teams",
        "Switch To Role": "Switch To Role",
        "T": "T",
        "Tag": "Tag",
        "Tags": "Tags",
        "Team": "Team",
        "Team Details": "Team Details",
        "Team Invitation": "Team Invitation",
        "Team Members": "Team Members",
        "Team members": "Team members",
        "Team Name": "Team Name",
        "Team Owner": "Team Owner",
        "Team Settings": "Team Settings",
        "Temporary Redirect": "Temporary Redirect",
        "Terms and Conditions": "Terms and Conditions",
        "Terms of Service": "Terms of Service",
        "Th": "Th",
        "Thank you for reaching out!": "Thank you for reaching out!",
        "Thank you for reaching out! We will get back to you shortly.": "Thank you for reaching out! We will get back to you shortly.",
        "Thank you for registering!": "Thank you for registering!",
        "Thank you for registration! You can apply for shifts now right away!": "Thank you for registration! You can apply for shifts now right away!",
        "Thank you for your message! We've sent you a confirmation mail, we'll get in touch with you soon!": "Thank you for your message! We've sent you a confirmation mail, we'll get in touch with you soon!",
        "Thank you for your message! We\\'ve sent you a confirmation mail, we\\'ll get in touch with you soon!": "Thank you for your message! We\\'ve sent you a confirmation mail, we\\'ll get in touch with you soon!",
        "That's not a problem at all.": "That's not a problem at all.",
        "That's possible, Top-Chefs only fulfill the shifts. The chefs themselves can indicate whether they accept the shifts.": "That's possible, Top-Chefs only fulfill the shifts. The chefs themselves can indicate whether they accept the shifts.",
        "That is possible. Please send us an email.": "That is possible. Please send us an email.",
        "The \"Premium Badge\" on our freelancing platform represents active participation, substantial experience, and steadfast reliability. It's awarded to freelancers who consistently take on and excellently complete shifts. The badge symbolizes trustworthiness in delivering high-quality work on time.": "The \"Premium Badge\" on our freelancing platform represents active participation, substantial experience, and steadfast reliability. It's awarded to freelancers who consistently take on and excellently complete shifts. The badge symbolizes trustworthiness in delivering high-quality work on time.",
        "The \"Premium Badge\" on our freelancing platform represents active participation, substantial experience, and steadfast reliability. It\\'s awarded to freelancers who consistently take on and excellently complete shifts. The badge symbolizes trustworthiness in delivering high-quality work on time.": "The \"Premium Badge\" on our freelancing platform represents active participation, substantial experience, and steadfast reliability. It\\'s awarded to freelancers who consistently take on and excellently complete shifts. The badge symbolizes trustworthiness in delivering high-quality work on time.",
        "The {attribute} can not be zero.": "The {attribute} can not be zero.",
        "The {attribute} field is required when {other} is {value}.": "The {attribute} field is required when {other} is {value}.",
        "The {attribute} is not valid.": "The {attribute} is not valid.",
        "The {attribute} must be after {date}.": "The {attribute} must be after {date}.",
        "The {attribute} must be at least {length} characters.": "The {attribute} must be at least {length} characters.",
        "The {attribute} must be at least {length} characters and contain at least one number.": "The {attribute} must be at least {length} characters and contain at least one number.",
        "The {attribute} must be at least {length} characters and contain at least one special character.": "The {attribute} must be at least {length} characters and contain at least one special character.",
        "The {attribute} must be at least {length} characters and contain at least one special character and one number.": "The {attribute} must be at least {length} characters and contain at least one special character and one number.",
        "The {attribute} must be at least {length} characters and contain at least one uppercase character, one number, and one special character.": "The {attribute} must be at least {length} characters and contain at least one uppercase character, one number, and one special character.",
        "The {attribute} must be at least {length} characters and contain at least one uppercase character.": "The {attribute} must be at least {length} characters and contain at least one uppercase character.",
        "The {attribute} must be at least {length} characters and contain at least one uppercase character and one number.": "The {attribute} must be at least {length} characters and contain at least one uppercase character and one number.",
        "The {attribute} must be at least {length} characters and contain at least one uppercase character and one special character.": "The {attribute} must be at least {length} characters and contain at least one uppercase character and one special character.",
        "The {attribute} must be a valid role.": "The {attribute} must be a valid role.",
        "The {attribute} must be verified.": "The {attribute} must be verified.",
        "The {attribute} must contain at least one letter.": "The {attribute} must contain at least one letter.",
        "The {attribute} must contain at least one number.": "The {attribute} must contain at least one number.",
        "The {attribute} must contain at least one symbol.": "The {attribute} must contain at least one symbol.",
        "The {attribute} must contain at least one uppercase and one lowercase letter.": "The {attribute} must contain at least one uppercase and one lowercase letter.",
        "The AVG is the law to protect privacy and personal data. Under this law an organization workingn                        with personal data certain obligations. These privacy regulations are intended to inform youn                        about your rights and our obligations under the AVG.": "The AVG is the law to protect privacy and personal data. Under this law an organization workingn                        with personal data certain obligations. These privacy regulations are intended to inform youn                        about your rights and our obligations under the AVG.",
        "The AVG is the law to protect privacy and personal data. Under this law an organization working with personal data certain obligations. These privacy regulations are intended to inform you about your rights and our obligations under the AVG.": "The AVG is the law to protect privacy and personal data. Under this law an organization working with personal data certain obligations. These privacy regulations are intended to inform you about your rights and our obligations under the AVG.",
        "The duties of Top-chefs bv": "The duties of Top-chefs bv",
        "The employees of Top-chefs bv are obliged to treat your personal data confidentially. handle confidentially. This means, for example, that top-chefs bv requires your express permission before providing your personal express permission. However, there are some exceptions to this rule.": "The employees of Top-chefs bv are obliged to treat your personal data confidentially. handle confidentially. This means, for example, that top-chefs bv requires your express permission before providing your personal express permission. However, there are some exceptions to this rule.",
        "The employees of Top-chefs bv are obliged to treat your personal data confidentially. handle confidentially.This means, for example, that top - chefs bv requires your express permission before providing your personal express permission.However, there are some exceptions to this rule.": "The employees of Top-chefs bv are obliged to treat your personal data confidentially. handle confidentially.This means, for example, that top - chefs bv requires your express permission before providing your personal express permission.However, there are some exceptions to this rule.",
        "The employees of Top-chefs bv are obliged to treat your personal data confidentially. handlen                        confidentially. This means, for example, that top-chefs bv requires your express permissionn                        before providing your personal express permission. However, there are some exceptions to thisn                        rule.": "The employees of Top-chefs bv are obliged to treat your personal data confidentially. handlen                        confidentially. This means, for example, that top-chefs bv requires your express permissionn                        before providing your personal express permission. However, there are some exceptions to thisn                        rule.",
        "The following details of {location} have been changed:": "The following details of {location} have been changed:",
        "The following people don’t have any valid ID’s anymore, please check.": "The following people don’t have any valid ID’s anymore, please check.",
        "The freelancer {name} has multiple shifts on the same day ({date}).": "The freelancer {name} has multiple shifts on the same day ({date}).",
        "The given {attribute} has appeared in a data leak. Please choose a different {attribute}.": "The given {attribute} has appeared in a data leak. Please choose a different {attribute}.",
        "The given data was invalid.": "The given data was invalid.",
        "The hourly rate varies per assignment, per shift and per client.": "The hourly rate varies per assignment, per shift and per client.",
        "The password is incorrect.": "The password is incorrect.",
        "The platform is free to use.": "The platform is free to use.",
        "The provided code was invalid.": "The provided code was invalid.",
        "The provided password does not match your current password.": "The provided password does not match your current password.",
        "The provided password was incorrect.": "The provided password was incorrect.",
        "The provided two factor authentication code was invalid.": "The provided two factor authentication code was invalid.",
        "The provided two factor recovery code was invalid.": "The provided two factor recovery code was invalid.",
        "There is a credit limit per employer. If that limit is exceeded, the invoices must be paid or the credit needs to be extended first to upload shifts again.": "There is a credit limit per employer. If that limit is exceeded, the invoices must be paid or the credit needs to be extended first to upload shifts again.",
        "The response is not a streamed response.": "The response is not a streamed response.",
        "The response is not a view.": "The response is not a view.",
        "The right to correct, supplement or delete data if necessary": "The right to correct, supplement or delete data if necessary",
        "The right to inspect and copy that data (to the extent that another person's privacy is isn                            not violated thereby).": "The right to inspect and copy that data (to the extent that another person's privacy is isn                            not violated thereby).",
        "The right to inspect and copy that data (to the extent that another person's privacy is is not violated thereby).": "The right to inspect and copy that data (to the extent that another person's privacy is is not violated thereby).",
        "The right to inspect and copy that data (to the extent that another person\\'s privacy is is not violated thereby).": "The right to inspect and copy that data (to the extent that another person\\'s privacy is is not violated thereby).",
        "The right to know whether and what personal data about you is being processed.": "The right to know whether and what personal data about you is being processed.",
        "The right to oppose the processing of your data in certain cases.": "The right to oppose the processing of your data in certain cases.",
        "The right to request (partial) destruction of your data. This can only if the preservationn                            of the data for another person is not of significant importance and the data on the basis ofn                            a statutory regulation does not must be kept.": "The right to request (partial) destruction of your data. This can only if the preservationn                            of the data for another person is not of significant importance and the data on the basis ofn                            a statutory regulation does not must be kept.",
        "The right to request (partial) destruction of your data. This can only if the preservation of the data for another person is not of significant importance and the data on the basis of a statutory regulation does not must be kept.": "The right to request (partial) destruction of your data. This can only if the preservation of the data for another person is not of significant importance and the data on the basis of a statutory regulation does not must be kept.",
        "These people have been invited to your team and have been sent an invitation email. They may join the team by accepting the email invitation.": "These people have been invited to your team and have been sent an invitation email. They may join the team by accepting the email invitation.",
        "The shift has been replaced.": "The shift has been replaced.",
        "The team's name and owner information.": "The team's name and owner information.",
        "The Top-Chefs platform makes finding chefs for your restaurant a lot easier. Read more here!": "The Top-Chefs platform makes finding chefs for your restaurant a lot easier. Read more here!",
        "The Top-Chefs platform makes finding wait staff for your restaurant a lot easier. Read more here!": "The Top-Chefs platform makes finding wait staff for your restaurant a lot easier. Read more here!",
        "This {attribute} is invalid": "This {attribute} is invalid",
        "This combination of {attribute} is invalid": "This combination of {attribute} is invalid",
        "This device": "This device",
        "This form can only be used to register as Employer. To register as Chef, please download the App.": "This form can only be used to register as Employer. To register as Chef, please download the App.",
        "This invoice is not yet paid": "This invoice is not yet paid",
        "This invoice is paid": "This invoice is paid",
        "This invoice may have been sent twice or never arrived before due to technical reasons. We would like to ask you to pay the invoices that have already expired immediately. We count on your understanding.": "This invoice may have been sent twice or never arrived before due to technical reasons. We would like to ask you to pay the invoices that have already expired immediately. We count on your understanding.",
        "This is a secure area of the application. Please confirm your password before continuing.": "This is a secure area of the application. Please confirm your password before continuing.",
        "This link expires in 24 hours": "This link expires in 24 hours",
        "This link expires in {number} minutes": "This link expires in {number} minutes",
        "This location already has a concept invoice": "This location already has a concept invoice",
        "This moment has a<strong>surcharge</strong>of {surcharge}%, which is paid to the freelancer": "This moment has a<strong>surcharge</strong>of {surcharge}%, which is paid to the freelancer",
        "This moment has a surcharge of {surcharge}%, which is paid to the freelancer": "This moment has a surcharge of {surcharge}%, which is paid to the freelancer",
        "This password does not match our records.": "This password does not match our records.",
        "This password reset link will expire in {count} minutes.": "This password reset link will expire in {count} minutes.",
        "This shift is no longer open": "This shift is no longer open",
        "This user already belongs to the team.": "This user already belongs to the team.",
        "This user has already been invited to the team.": "This user has already been invited to the team.",
        "Till": "Till",
        "till": "till",
        "Time": "Time",
        "Time surcharges": "Time surcharges",
        "Title": "Title",
        "title": "title",
        "To": "to",
        "to": "to",
        "Today": "Today",
        "Toevoegen": "Toevoegen",
        "To finish enabling two factor authentication, scan the following QR code using your phone's authenticator application or enter the setup key and provide the generated OTP code.": "To finish enabling two factor authentication, scan the following QR code using your phone's authenticator application or enter the setup key and provide the generated OTP code.",
        "Toggle navigation": "Toggle navigation",
        "token": "token",
        "Token Name": "Token Name",
        "Too Early": "Too Early",
        "Too Many Requests": "Too Many Requests",
        "Top-Chef": "Top-Chef",
        "Top-Chefs": "Top-Chefs",
        "Top-chefs": "Top-chefs",
        "Top-Chefs, since 2017. Top-Chefs already had a lot of experience in the food industry. Looking for suitable freelance chefs was a common problem in this industry. But why not solve this problem? That’s where it all started. With Michelin and concept development experience, this was just a matter of time. Now, Top-Chefs connects restaurants and chefs every day. Our new app Top-Chefs fits the current market perfectly!": "Top-Chefs, since 2017. Top-Chefs already had a lot of experience in the food industry. Looking for suitable freelance chefs was a common problem in this industry. But why not solve this problem? That’s where it all started. With Michelin and concept development experience, this was just a matter of time. Now, Top-Chefs connects restaurants and chefs every day. Our new app Top-Chefs fits the current market perfectly!",
        "Top-chefs bv": "Top-chefs bv",
        "Top-chefs bv does not exchange your relevant data with third parties, exception is STRIXIn                        Solutions B.V. for maintaining the app and profile.": "Top-chefs bv does not exchange your relevant data with third parties, exception is STRIXIn                        Solutions B.V. for maintaining the app and profile.",
        "Top-chefs bv does not exchange your relevant data with third parties, exception is STRIXI Solutions B.V. for maintaining the app and profile.": "Top-chefs bv does not exchange your relevant data with third parties, exception is STRIXI Solutions B.V. for maintaining the app and profile.",
        "Top-chefs bv does not exchange your relevant data with third parties, exception is STRIXI Solutions B.V.for maintaining the app and profile.": "Top-chefs bv does not exchange your relevant data with third parties, exception is STRIXI Solutions B.V.for maintaining the app and profile.",
        "Top-chefs bv is according to the AVG the controller for the processing of personal data thatn                        takes place in the practice. The practice complies with the resulting obligations as follows:": "Top-chefs bv is according to the AVG the controller for the processing of personal data thatn                        takes place in the practice. The practice complies with the resulting obligations as follows:",
        "Top-chefs bv is according to the AVG the controller for the processing of personal data that takes place in the practice. The practice complies with the resulting obligations as follows:": "Top-chefs bv is according to the AVG the controller for the processing of personal data that takes place in the practice. The practice complies with the resulting obligations as follows:",
        "Top-chefs bv is according to the AVG the controller for the processing of personal data that takes place in the practice.The practice complies with the resulting obligations as follows:": "Top-chefs bv is according to the AVG the controller for the processing of personal data that takes place in the practice.The practice complies with the resulting obligations as follows:",
        "Top-chefs bv may process various personal data about you. This is necessary to properly accountn                        properly and necessary for the financial settlement of any hours worked. hours. In addition,n                        processing may be necessary for, for example, combating serious problems so that we can supportn                        you.": "Top-chefs bv may process various personal data about you. This is necessary to properly accountn                        properly and necessary for the financial settlement of any hours worked. hours. In addition,n                        processing may be necessary for, for example, combating serious problems so that we can supportn                        you.",
        "Top-chefs bv may process various personal data about you. This is necessary to properly account properly and necessary for the financial settlement of any hours worked. hours. In addition, processing may be necessary for, for example, combating serious problems so that we can support you.": "Top-chefs bv may process various personal data about you. This is necessary to properly account properly and necessary for the financial settlement of any hours worked. hours. In addition, processing may be necessary for, for example, combating serious problems so that we can support you.",
        "Top-Chefs has sent you the following message:": "Top-Chefs has sent you the following message:",
        "Top-Chefs have the rights to remove you from the platform at any time.": "Top-Chefs have the rights to remove you from the platform at any time.",
        "Top-Chefs is a top party to work with. We have been using their platform from the start and we like it very much. Easy user interface, the services are filled quickly and the quality of the freelancers is high. In short, a very nice platform!": "Top-Chefs is a top party to work with. We have been using their platform from the start and we like it very much. Easy user interface, the services are filled quickly and the quality of the freelancers is high. In short, a very nice platform!",
        "Top-Chefs is the matchmaker between chefs and hospitality companies!": "Top-Chefs is the matchmaker between chefs and hospitality companies!",
        "Top-Chefs is the matchmaker between chefs and hospitality companies. As a hospitality company, you decide who fills a shift and when! So if you want another Top-Chef, no problem, because you choose! Register easily via our website now!": "Top-Chefs is the matchmaker between chefs and hospitality companies. As a hospitality company, you decide who fills a shift and when! So if you want another Top-Chef, no problem, because you choose! Register easily via our website now!",
        "Top-Chefs is the matchmaker between chefs and restaurants!": "Top-Chefs is the matchmaker between chefs and restaurants!",
        "Top-Chefs was founded in 2017 and has been supporting catering companies in finding new staff for many years. With our years of experience, we can quickly put the right freelance chef in the right place. We quickly link the requests from catering companies to our chefs, so that you as a catering entrepreneur save time and can fully focus on the quality of the restaurant! And the most beautiful thing? We take care of the administration and perform the online time registration. It doesn't get any easier!": "Top-Chefs was founded in 2017 and has been supporting catering companies in finding new staff for many years. With our years of experience, we can quickly put the right freelance chef in the right place. We quickly link the requests from catering companies to our chefs, so that you as a catering entrepreneur save time and can fully focus on the quality of the restaurant! And the most beautiful thing? We take care of the administration and perform the online time registration. It doesn't get any easier!",
        "Top-Chefs was founded in 2017 and has been supporting hospitality companies in finding hospitality staff for many years. We have an extensive network and years of experience, which allows us to quickly put the right freelancer in the right place. By quickly matching requests from hospitality businesses with our freelancers, you save time and can focus on the quality of your business.": "Top-Chefs was founded in 2017 and has been supporting hospitality companies in finding hospitality staff for many years. We have an extensive network and years of experience, which allows us to quickly put the right freelancer in the right place. By quickly matching requests from hospitality businesses with our freelancers, you save time and can focus on the quality of your business.",
        "Top-Chefs was founded in 2017 and has supported hospitality companies in finding staff for many years.<br /><br />Top-Chefs has an extensive network and years of experience. As a result, we can put the right chef in the right place at lightning speed. By matching your requests to our chefs quickly, you save time and can concentrate on the quality of your hotel and catering business. In addition, we also take care of the administration, taking the registration of hours away from you by registering online. You can check hours, indicate issues and approve them at a glance. By using our platform, you create a freelance team around your catering company so that you always have enough chefs. In the platform, we also offer an option to share reviews and ratings and chat directly with Top Chefs. This ensures that you create motivated and hard-working freelancers in your team.": "Top-Chefs was founded in 2017 and has supported hospitality companies in finding staff for many years.<br /><br />Top-Chefs has an extensive network and years of experience. As a result, we can put the right chef in the right place at lightning speed. By matching your requests to our chefs quickly, you save time and can concentrate on the quality of your hotel and catering business. In addition, we also take care of the administration, taking the registration of hours away from you by registering online. You can check hours, indicate issues and approve them at a glance. By using our platform, you create a freelance team around your catering company so that you always have enough chefs. In the platform, we also offer an option to share reviews and ratings and chat directly with Top Chefs. This ensures that you create motivated and hard-working freelancers in your team.",
        "Top-Chefs was founded in 2017 and has supported hospitality companies in finding staff for many years.nnTop-Chefs has an extensive network and years of experience. As a result, we can put the right chef in the right place at lightning speed. By matching your requests to our chefs quickly, you save time and can concentrate on the quality of your hotel and catering business. In addition, we also take care of the administration, taking the registration of hours away from you by registering online. You can check hours, indicate issues and approve them at a glance. By using our platform, you create a freelance team around your catering company so that you always have enough chefs. In the platform, we also offer an option to share reviews and ratings and chat directly with Top Chefs. This ensures that you create motivated and hard-working freelancers in your team.": "Top-Chefs was founded in 2017 and has supported hospitality companies in finding staff for many years.nnTop-Chefs has an extensive network and years of experience. As a result, we can put the right chef in the right place at lightning speed. By matching your requests to our chefs quickly, you save time and can concentrate on the quality of your hotel and catering business. In addition, we also take care of the administration, taking the registration of hours away from you by registering online. You can check hours, indicate issues and approve them at a glance. By using our platform, you create a freelance team around your catering company so that you always have enough chefs. In the platform, we also offer an option to share reviews and ratings and chat directly with Top Chefs. This ensures that you create motivated and hard-working freelancers in your team.",
        "To revoke the shift send an email to {email}": "To revoke the shift send an email to {email}",
        "Total": "Total",
        "Total {model}": "Total {model}",
        "Total Accredited Hours": "Total Accredited Hours",
        "Total Future Hours": "Total Future Hours",
        "Total hours worked: {duration} h": "Total hours worked: {duration} h",
        "Total Requested Hours": "Total Requested Hours",
        "To use Top Chefs Two Factor Authentication is required as extra security, you can set this now": "To use Top Chefs Two Factor Authentication is required as extra security, you can set this now",
        "Translate": "Translate",
        "Translate It": "Translate It",
        "Travel costs: € {cost}": "Travel costs: € {cost}",
        "Travel Reimbursement": "Travel Reimbursement",
        "Travel reimbursement": "Travel reimbursement",
        "Travel reimbursement budget": "Travel reimbursement budget",
        "Tu": "Tu",
        "Two Factor Authentication": "Two Factor Authentication",
        "Two factor authentication": "Two factor authentication",
        "Two Factor Authentication - Backup codes": "Two Factor Authentication - Backup codes",
        "Two factor authentication is now enabled. Scan the following QR code using your phone's authenticator application or enter the setup key.": "Two factor authentication is now enabled. Scan the following QR code using your phone's authenticator application or enter the setup key.",
        "Type": "Type",
        "Type: {type}": "Type: {type}",
        "types": "types",
        "Unassign": "Unassign",
        "Unauthorized": "Unauthorized",
        "Unavailable For Legal Reasons": "Unavailable For Legal Reasons",
        "Unblock": "Unblock",
        "Unfortunately, the page you are looking for does not exist (anymore). Looking for information that you can't find on our website? Then contact us!": "Unfortunately, the page you are looking for does not exist (anymore). Looking for information that you can't find on our website? Then contact us!",
        "Unfortunately, the page you are looking for does not exist (anymore). Looking for information that you can\\'t find on our website? Then contact us!": "Unfortunately, the page you are looking for does not exist (anymore). Looking for information that you can\\'t find on our website? Then contact us!",
        "Unit Price": "Unit Price",
        "Unit price": "Unit price",
        "unit price": "unit price",
        "Unknown": "Unknown",
        "Unknown Error": "Unknown Error",
        "Unpack": "Unpack",
        "Unprocessable Entity": "Unprocessable Entity",
        "Unsubscribe": "Unsubscribe",
        "Unsupported Media Type": "Unsupported Media Type",
        "Up": "Up",
        "Update": "Update",
        "Update {name}": "Update {name}",
        "Updated": "Updated",
        "Update Password": "Update Password",
        "Update password": "Update password",
        "Update your account's profile information and email address.": "Update your account's profile information and email address.",
        "Upgrade Required": "Upgrade Required",
        "Uploaded back identification": "Uploaded back identification",
        "Uploaded front identification": "Uploaded front identification",
        "Uploaded identification": "Uploaded identification",
        "Uploaded selfie": "Uploaded selfie",
        "URI Too Long": "URI Too Long",
        "Use an authentication code": "Use an authentication code",
        "Use a recovery code": "Use a recovery code",
        "Use Proxy": "Use Proxy",
        "User": "User",
        "User cannot be removed from own team": "User cannot be removed from own team",
        "User is already in team": "User is already in team",
        "User Profile": "User Profile",
        "user types": "user types",
        "User was added to team": "User was added to team",
        "User was removed from team": "User was removed from team",
        "Uses the Top-Chefs app in: {lang}": "Uses the Top-Chefs app in: {lang}",
        "Using a non-Dutch number? Please also add the country code. (example +3234567890)": "Using a non-Dutch number? Please also add the country code. (example +3234567890)",
        "UX Designer": "UX Designer",
        "Validity: {date}": "Validity: {date}",
        "Variant Also Negotiates": "Variant Also Negotiates",
        "VAT number": "VAT number",
        "VAT number (optional)": "VAT number (optional)",
        "Vat Rate": "Vat Rate",
        "Vat rate": "Vat rate",
        "Verified": "Verified",
        "Verified by Top-Chefs.": "Verified by Top-Chefs.",
        "Verified identity": "Verified identity",
        "Verify": "Verify",
        "Verify {attribute}": "Verify {attribute}",
        "Verify {model}": "Verify {model}",
        "Verify Email Address": "Verify Email Address",
        "Verify with your phone": "Verify with your phone",
        "Verify Your Email Address": "Verify Your Email Address",
        "Very bad": "Very bad",
        "Very Good": "Very Good",
        "View": "View",
        "View {name}": "View {name}",
        "View all blogposts": "View all blogposts",
        "View chefs' skills and experiences.": "View chefs' skills and experiences.",
        "View chefs skills and experiences.": "View chefs skills and experiences.",
        "View Freelancer": "View Freelancer",
        "View profile": "View profile",
        "View the shifts and sign up": "View the shifts and sign up",
        "W": "W",
        "Wage": "Wage",
        "Waiter!": "Waiter!",
        "Waitstaff": "Waitstaff",
        "Waitstaff price": "Waitstaff price",
        "wanted": "wanted",
        "Want guidance for finding freelance chefs to suit your hospitality business? We are here for you every day to help your hospitality business. From answering questions about our platform, to personal advice.": "Want guidance for finding freelance chefs to suit your hospitality business? We are here for you every day to help your hospitality business. From answering questions about our platform, to personal advice.",
        "Was unable to find an address with this postcode and street number": "Was unable to find an address with this postcode and street number",
        "We": "We",
        "We are happy to help your {brand}!": "We are happy to help your {brand}!",
        "We are thrilled to welcome you onboard! However, we have noticed that your registration process is not yet complete.": "We are thrilled to welcome you onboard! However, we have noticed that your registration process is not yet complete.",
        "Web Server is Down": "Web Server is Down",
        "Website": "Website",
        "We declined your registration": "We declined your registration",
        "We declined your registration because of the following reason:": "We declined your registration because of the following reason:",
        "We do all the administration": "We do all the administration",
        "We do all the administration.": "We do all the administration.",
        "Week": "Week",
        "Weekly": "Weekly",
        "Week nr.": "Week nr.",
        "weeks": "weeks",
        "We have been working with Top-Chefs for some time now and for now we are very satisfied! Communication is good and experienced forces are placed.": "We have been working with Top-Chefs for some time now and for now we are very satisfied! Communication is good and experienced forces are placed.",
        "Welcome to Top-Chefs!": "Welcome to Top-Chefs!",
        "We look forward to being part of your professional journey!": "We look forward to being part of your professional journey!",
        "We match freelance cooks with catering companies.": "We match freelance cooks with catering companies.",
        "We pay the invoice within 7 and 14 days. Invoices are paid once a week.": "We pay the invoice within 7 and 14 days. Invoices are paid once a week.",
        "We try to respond as quickly as possible!": "We try to respond as quickly as possible!",
        "We were unable to find a registered user with this email address.": "We were unable to find a registered user with this email address.",
        "We’ll do all the administration": "We’ll do all the administration",
        "We’re sorry for the inconvenience. We’ve re-opened the shift so you can receive new applicants or accept someone else.": "We’re sorry for the inconvenience. We’ve re-opened the shift so you can receive new applicants or accept someone else.",
        "What are the advantages of our platform as a hospitality company?": "What are the advantages of our platform as a hospitality company?",
        "What are the benefits of our platform for you as a freelancer?": "What are the benefits of our platform for you as a freelancer?",
        "What are the minimum requirements?": "What are the minimum requirements?",
        "What do I have to bring to my shift?": "What do I have to bring to my shift?",
        "What do you mean {brand} With our platform, you'll never have to deal with this again!": "What do you mean {brand} With our platform, you'll never have to deal with this again!",
        "What is the hourly rate?": "What is the hourly rate?",
        "What kind of employers are there?": "What kind of employers are there?",
        "WhatsApp daily from 09{00} to 00{00}": "WhatsApp daily from 09{00} to 00{00}",
        "WhatsApp daily from {from} to {to}": "WhatsApp daily from {from} to {to}",
        "WhatsApp to": "WhatsApp to",
        "What to do if a freelancer doesn't show up?": "What to do if a freelancer doesn't show up?",
        "When two factor authentication is enabled, you will be prompted for a secure, random token during authentication. You may retrieve this token from your phone's Google Authenticator application.": "When two factor authentication is enabled, you will be prompted for a secure, random token during authentication. You may retrieve this token from your phone's Google Authenticator application.",
        "Whoops!": "Whoops!",
        "Whoops! Something went wrong.": "Whoops! Something went wrong.",
        "Will I receive a timesheet from you?": "Will I receive a timesheet from you?",
        "Within a few clicks, you have placed an enquiry and/or accepted a shift.": "Within a few clicks, you have placed an enquiry and/or accepted a shift.",
        "With Top-Chefs, you'll have a freelance chef filling your shift within an hour! And with your own preferred pool, you decide by whom and when. Register now completely free and place your first shift!": "With Top-Chefs, you'll have a freelance chef filling your shift within an hour! And with your own preferred pool, you decide by whom and when. Register now completely free and place your first shift!",
        "Worked hours": "Worked hours",
        "Work experience": "Work experience",
        "work experience": "work experience",
        "work experiences": "work experiences",
        "Would you like to carry out freelance assignments in": "Would you like to carry out freelance assignments in",
        "Would you like to have an appointment?": "Would you like to have an appointment?",
        "Would you rather still have contact with our account manager?": "Would you rather still have contact with our account manager?",
        "Write reviews and give stars": "Write reviews and give stars",
        "Write reviews and share stars, this is possible for chefs and employers.": "Write reviews and share stars, this is possible for chefs and employers.",
        "Yes": "Yes",
        "Yes, both the chef and the client can leave a review.": "Yes, both the chef and the client can leave a review.",
        "Yes, i would like to receive newsletters.": "Yes, i would like to receive newsletters.",
        "Yes, that’s possible. When it’s too busy, you can leave a voicemail. Our customer service will call you back as soon as possible.": "Yes, that’s possible. When it’s too busy, you can leave a voicemail. Our customer service will call you back as soon as possible.",
        "Yes, the client can upload a long-term assignment over several shifts for which you can apply.": "Yes, the client can upload a long-term assignment over several shifts for which you can apply.",
        "Yes, when registering we ask for a Chamber of Commerce (KvK) registration number. We verify the existence of the company automatically.": "Yes, when registering we ask for a Chamber of Commerce (KvK) registration number. We verify the existence of the company automatically.",
        "Yes, with the Chamber of Commerce check, the kilometers per employer are automatically calculated.": "Yes, with the Chamber of Commerce check, the kilometers per employer are automatically calculated.",
        "Yes, you can respond to shifts or accept shifts.": "Yes, you can respond to shifts or accept shifts.",
        "You accept our": "You accept our",
        "You are expected to have your clothes and knives with you. In addition, you look neat and tidy. When there’s clothing available on location, we will mention this.": "You are expected to have your clothes and knives with you. In addition, you look neat and tidy. When there’s clothing available on location, we will mention this.",
        "You are not allowed to delete your account.": "You are not allowed to delete your account.",
        "You are receiving this email because we received a password reset request for your account.": "You are receiving this email because we received a password reset request for your account.",
        "You can immediately send an email to Top-Chefs. This issue is then added to the chef's file. You can also block the chef right away.": "You can immediately send an email to Top-Chefs. This issue is then added to the chef's file. You can also block the chef right away.",
        "You cannot create shifts for a restricted location. If you recently created a location, please wait for it to be approved.": "You cannot create shifts for a restricted location. If you recently created a location, please wait for it to be approved.",
        "You choose where and when": "You choose where and when",
        "You choose where and when.": "You choose where and when.",
        "You consent when creating your profile of the fact that personal data about you will ben                        processed will be processed.": "You consent when creating your profile of the fact that personal data about you will ben                        processed will be processed.",
        "You consent when creating your profile of the fact that personal data about you will be processed will be processed.": "You consent when creating your profile of the fact that personal data about you will be processed will be processed.",
        "You have been invited to join the {team} team!": "You have been invited to join the {team} team!",
        "You have enabled two factor authentication.": "You have enabled two factor authentication.",
        "You have not enabled two factor authentication.": "You have not enabled two factor authentication.",
        "You have the following rights:": "You have the following rights:",
        "You have to work at least 6 hours per day.": "You have to work at least 6 hours per day.",
        "You may accept this invitation by clicking the button below:": "You may accept this invitation by clicking the button below:",
        "You may delete any of your existing tokens if they are no longer needed.": "You may delete any of your existing tokens if they are no longer needed.",
        "You may not delete your personal team.": "You may not delete your personal team.",
        "You may not leave a team that you created.": "You may not leave a team that you created.",
        "You must accept the general terms, and privacy policy to register.": "You must accept the general terms, and privacy policy to register.",
        "Your account for {siteName}": "Your account for {siteName}",
        "Your account is incomplete or blocked. Please contact Top-Chefs support at 085 760 34 18.": "Your account is incomplete or blocked. Please contact Top-Chefs support at 085 760 34 18.",
        "Your application for {location} was rejected. Better luck next time!": "Your application for {location} was rejected. Better luck next time!",
        "Your disputed shift has been reviewed": "Your disputed shift has been reviewed",
        "Your disputed shift has been reviewed by Top-Chefs support and the shift hours are now definitive.": "Your disputed shift has been reviewed by Top-Chefs support and the shift hours are now definitive.",
        "You received this email because your account is ready for {siteName}": "You received this email because your account is ready for {siteName}",
        "Your email address is unverified.": "Your email address is unverified.",
        "Your experience on this site is enhanced by the use of cookies.": "Your experience on this site is enhanced by the use of cookies.",
        "Your hours have been approved. For shifts worked **and approved**, you can send us a collective invoice per week to [{backoffice_email}](mailto:{backoffice_email}).": "Your hours have been approved. For shifts worked **and approved**, you can send us a collective invoice per week to [{backoffice_email}](mailto:{backoffice_email}).",
        "Your hours have been approved. For shifts worked and approved, you can send us a collective invoice per week to {backoffice_email}.": "Your hours have been approved. For shifts worked and approved, you can send us a collective invoice per week to {backoffice_email}.",
        "Your hours have been approved for your shift on {start}": "Your hours have been approved for your shift on {start}",
        "Your hours have been approved for your shift on {start}. For shifts worked **and approved**, you can send us a collective invoice per week to [{backoffice_email}](mailto:{backoffice_email}).": "Your hours have been approved for your shift on {start}. For shifts worked **and approved**, you can send us a collective invoice per week to [{backoffice_email}](mailto:{backoffice_email}).",
        "Your information is collected for specific purposes:": "Your information is collected for specific purposes:",
        "Your personal data and your privacy at Top-chefs bv": "Your personal data and your privacy at Top-chefs bv",
        "Your personal data is properly secured against unauthorized access.": "Your personal data is properly secured against unauthorized access.",
        "Your personal data will not be kept longer than necessary. After unsubscribing with us we will keep your data for a maximum of 5 years.": "Your personal data will not be kept longer than necessary. After unsubscribing with us we will keep your data for a maximum of 5 years.",
        "Your personal data will not be kept longer than necessary. After unsubscribing with us we willn                        keep your data for a maximum of 5 years.": "Your personal data will not be kept longer than necessary. After unsubscribing with us we willn                        keep your data for a maximum of 5 years.",
        "Your rights as a data subject:": "Your rights as a data subject:",
        "Your shift hours have been corrected by {locationName}": "Your shift hours have been corrected by {locationName}",
        "Your Top Chefs Credit Note": "Your Top Chefs Credit Note",
        "Your Top Chefs Invoice": "Your Top Chefs Invoice",
        "You will be send a framework agreement by email upon your first shift": "You will be send a framework agreement by email upon your first shift",
        "You will receive this email because your account is ready for {siteName}": "You will receive this email because your account is ready for {siteName}",
        "You’re invited for a shift you liked, apply now!": "You’re invited for a shift you liked, apply now!",
        "Zip code": "Zip code",
        "“Self-employed cook wanted!” Do you also have many vacancies in your restaurant that are not filled? And are you tired of looking for new chefs? Then you've come to the right place at Top-Chefs. We are the matchmaker between chefs and catering companies. Register easily via our website and start your search for a new freelance cook right away!": "“Self-employed cook wanted!” Do you also have many vacancies in your restaurant that are not filled? And are you tired of looking for new chefs? Then you've come to the right place at Top-Chefs. We are the matchmaker between chefs and catering companies. Register easily via our website and start your search for a new freelance cook right away!",
        "€50": "€50",
        "€100": "€100",
        "👉 Register for free and easy via": "👉 Register for free and easy via",
        "👉 Send a message via": "👉 Send a message via",
        "👋 Are you looking for a Top Chef?": "👋 Are you looking for a Top Chef?",
        "👋 Can we help you?": "👋 Can we help you?",
        "routes": {
            "shifts": "shifts",
            "city": "city",
            "employers": "employers",
            "hospitality": "hospitality",
            "positions": "positions",
            "blog": "blog",
            "employer-landing": "employer-landing",
            "hire-a-freelance-cook": "hire-a-freelance-cook",
            "about-us": "about-us",
            "general-terms": "general-terms",
            "privacy-policy": "privacy-policy"
        },
        "pagination": {
            "next": "Next &raquo;",
            "previous": "&laquo; Previous"
        },
        "auth": {
            "failed": "These credentials do not match our records.",
            "password": "The password is incorrect.",
            "throttle": "Too many login attempts. Please try again in {seconds} seconds."
        },
        "http-statuses": {
            "0": "Unknown Error",
            "100": "Continue",
            "101": "Switching Protocols",
            "102": "Processing",
            "200": "OK",
            "201": "Created",
            "202": "Accepted",
            "203": "Non-Authoritative Information",
            "204": "No Content",
            "205": "Reset Content",
            "206": "Partial Content",
            "207": "Multi-Status",
            "208": "Already Reported",
            "226": "IM Used",
            "300": "Multiple Choices",
            "301": "Moved Permanently",
            "302": "Found",
            "303": "See Other",
            "304": "Not Modified",
            "305": "Use Proxy",
            "307": "Temporary Redirect",
            "308": "Permanent Redirect",
            "400": "Bad Request",
            "401": "Unauthorized",
            "402": "Payment Required",
            "403": "Forbidden",
            "404": "Not Found",
            "405": "Method Not Allowed",
            "406": "Not Acceptable",
            "407": "Proxy Authentication Required",
            "408": "Request Timeout",
            "409": "Conflict",
            "410": "Gone",
            "411": "Length Required",
            "412": "Precondition Failed",
            "413": "Payload Too Large",
            "414": "URI Too Long",
            "415": "Unsupported Media Type",
            "416": "Range Not Satisfiable",
            "417": "Expectation Failed",
            "418": "I'm a teapot",
            "419": "Session Has Expired",
            "421": "Misdirected Request",
            "422": "Unprocessable Entity",
            "423": "Locked",
            "424": "Failed Dependency",
            "425": "Too Early",
            "426": "Upgrade Required",
            "428": "Precondition Required",
            "429": "Too Many Requests",
            "431": "Request Header Fields Too Large",
            "444": "Connection Closed Without Response",
            "449": "Retry With",
            "451": "Unavailable For Legal Reasons",
            "499": "Client Closed Request",
            "500": "Internal Server Error",
            "501": "Not Implemented",
            "502": "Bad Gateway",
            "503": "Maintenance Mode",
            "504": "Gateway Timeout",
            "505": "HTTP Version Not Supported",
            "506": "Variant Also Negotiates",
            "507": "Insufficient Storage",
            "508": "Loop Detected",
            "509": "Bandwidth Limit Exceeded",
            "510": "Not Extended",
            "511": "Network Authentication Required",
            "520": "Unknown Error",
            "521": "Web Server is Down",
            "522": "Connection Timed Out",
            "523": "Origin Is Unreachable",
            "524": "A Timeout Occurred",
            "525": "SSL Handshake Failed",
            "526": "Invalid SSL Certificate",
            "527": "Railgun Error",
            "598": "Network Read Timeout Error",
            "599": "Network Connect Timeout Error",
            "unknownError": "Unknown Error"
        },
        "passwords": {
            "reset": "Your password has been reset!",
            "sent": "If we found a matching account, you'll receive password reset instructions in your email.",
            "throttled": "Please wait before retrying.",
            "token": "This link isn't valid anymore, please request a new one.",
            "user": "We can't find a user with that email address."
        },
        "validation": {
            "accepted": "The {attribute} must be accepted.",
            "accepted_if": "The {attribute} must be accepted when {other} is {value}.",
            "active_url": "The {attribute} is not a valid URL.",
            "after": "The {attribute} must be a date after {date}.",
            "after_or_equal": "The {attribute} must be a date after or equal to {date}.",
            "alpha": "The {attribute} may only contain letters.",
            "alpha_dash": "The {attribute} may only contain letters, numbers, and dashes.",
            "alpha_num": "The {attribute} may only contain letters and numbers.",
            "array": "The {attribute} must be an array.",
            "ascii": "The {attribute} field must only contain single-byte alphanumeric characters and symbols.",
            "before": "The {attribute} must be a date before {date}.",
            "before_or_equal": "The {attribute} must be a date before or equal to {date}.",
            "between": {
                "array": "The {attribute} must have between {min} and {max} items.",
                "file": "The {attribute} must be between {min} and {max} kilobytes.",
                "numeric": "The {attribute} must be between {min} and {max}.",
                "string": "The {attribute} must be between {min} and {max} characters."
            },
            "boolean": "The {attribute} field must be true or false.",
            "can": "The {attribute} field contains an unauthorized value.",
            "confirmed": "The {attribute} confirmation does not match.",
            "current_password": "The password is incorrect.",
            "date": "The {attribute} is not a valid date.",
            "date_equals": "The {attribute} must be a date equal to {date}.",
            "date_format": "The {attribute} does not match the format {format}.",
            "decimal": "The {attribute} field must have {decimal} decimal places.",
            "declined": "The {attribute} must be declined.",
            "declined_if": "The {attribute} must be declined when {other} is {value}.",
            "different": "The {attribute} and {other} must be different.",
            "digits": "The {attribute} must be {digits} digits.",
            "digits_between": "The {attribute} must be between {min} and {max} digits.",
            "dimensions": "The {attribute} has invalid image dimensions.",
            "distinct": "The {attribute} field has a duplicate value.",
            "doesnt_end_with": "The {attribute} field must not end with one of the following: {values}.",
            "doesnt_start_with": "The {attribute} field must not start with one of the following: {values}.",
            "email": "The {attribute} must be a valid email address.",
            "ends_with": "The {attribute} must end with one of the following: {values}.",
            "enum": "The selected {attribute} is invalid.",
            "exists": "The selected {attribute} is invalid.",
            "extensions": "The {attribute} field must have one of the following extensions: {values}.",
            "file": "The {attribute} must be a file.",
            "filled": "The {attribute} field is required.",
            "gt": {
                "array": "The {attribute} must have more than {value} items.",
                "file": "The {attribute} must be greater than {value} kilobytes.",
                "numeric": "The {attribute} must be greater than {value}.",
                "string": "The {attribute} must be greater than {value} characters."
            },
            "gte": {
                "array": "The {attribute} must have {value} items or more.",
                "file": "The {attribute} must be greater than or equal {value} kilobytes.",
                "numeric": "The {attribute} must be greater than or equal {value}.",
                "string": "The {attribute} must be greater than or equal {value} characters."
            },
            "hex_color": "The {attribute} field must be a valid hexadecimal color.",
            "image": "The {attribute} must be an image.",
            "in": "The selected {attribute} is invalid.",
            "in_array": "The {attribute} field does not exist in {other}.",
            "integer": "The {attribute} must be an integer.",
            "ip": "The {attribute} must be a valid IP address.",
            "ipv4": "The {attribute} must be a valid IPv4 address.",
            "ipv6": "The {attribute} must be a valid IPv6 address.",
            "json": "The {attribute} must be a valid JSON string.",
            "lowercase": "The {attribute} field must be lowercase.",
            "lt": {
                "array": "The {attribute} must have less than {value} items.",
                "file": "The {attribute} must be less than {value} kilobytes.",
                "numeric": "The {attribute} must be less than {value}.",
                "string": "The {attribute} must be less than {value} characters."
            },
            "lte": {
                "array": "The {attribute} must not have more than {value} items.",
                "file": "The {attribute} must be less than or equal {value} kilobytes.",
                "numeric": "The {attribute} must be less than or equal {value}.",
                "string": "The {attribute} must be less than or equal {value} characters."
            },
            "mac_address": "The {attribute} must be a valid MAC address.",
            "max": {
                "array": "The {attribute} may not have more than {max} items.",
                "file": "The {attribute} may not be greater than {max} kilobytes.",
                "numeric": "The {attribute} may not be greater than {max}.",
                "string": "The {attribute} may not be greater than {max} characters."
            },
            "max_digits": "The {attribute} field must not have more than {max} digits.",
            "mimes": "The {attribute} must be a file of type: {values}.",
            "mimetypes": "The {attribute} must be a file of type: {values}.",
            "min": {
                "array": "The {attribute} must have at least {min} items.",
                "file": "The {attribute} must be at least {min} kilobytes.",
                "numeric": "The {attribute} must be at least {min}.",
                "string": "The {attribute} must be at least {min} characters."
            },
            "min_digits": "The {attribute} field must have at least {min} digits.",
            "missing": "The {attribute} field must be missing.",
            "missing_if": "The {attribute} field must be missing when {other} is {value}.",
            "missing_unless": "The {attribute} field must be missing unless {other} is {value}.",
            "missing_with": "The {attribute} field must be missing when {values} is present.",
            "missing_with_all": "The {attribute} field must be missing when {values} are present.",
            "multiple_of": "The {attribute} must be a multiple of {value}.",
            "not_in": "The selected {attribute} is invalid.",
            "not_regex": "The {attribute} format is invalid.",
            "numeric": "The {attribute} must be a number.",
            "password": {
                "letters": "The {attribute} field must contain at least one letter.",
                "mixed": "The {attribute} field must contain at least one uppercase and one lowercase letter.",
                "numbers": "The {attribute} field must contain at least one number.",
                "symbols": "The {attribute} field must contain at least one symbol.",
                "uncompromised": "The given {attribute} has appeared in a data leak. Please choose a different {attribute}."
            },
            "phone": "The {attribute} field must be a valid number.",
            "present": "The {attribute} field must be present.",
            "present_if": "The {attribute} field must be present when {other} is {value}.",
            "present_unless": "The {attribute} field must be present unless {other} is {value}.",
            "present_with": "The {attribute} field must be present when {values} is present.",
            "present_with_all": "The {attribute} field must be present when {values} are present.",
            "prohibited": "The {attribute} field is prohibited.",
            "prohibited_if": "The {attribute} field is prohibited when {other} is {value}.",
            "prohibited_unless": "The {attribute} field is prohibited unless {other} is in {values}.",
            "prohibits": "The {attribute} field prohibits {other} from being present.",
            "regex": "The {attribute} format is invalid.",
            "required": "The {attribute} field is required.",
            "required_array_keys": "The {attribute} field must contain entries for: {values}.",
            "required_if": "The {attribute} field is required when {other} is {value}.",
            "required_if_accepted": "The {attribute} field is required when {other} is accepted.",
            "required_unless": "The {attribute} field is required unless {other} is in {values}.",
            "required_with": "The {attribute} field is required when {values} is present.",
            "required_with_all": "The {attribute} field is required when {values} is present.",
            "required_without": "The {attribute} field is required when {values} is not present.",
            "required_without_all": "The {attribute} field is required when none of {values} are present.",
            "same": "The {attribute} and {other} must match.",
            "size": {
                "array": "The {attribute} must contain {size} items.",
                "file": "The {attribute} must be {size} kilobytes.",
                "numeric": "The {attribute} must be {size}.",
                "string": "The {attribute} must be {size} characters."
            },
            "starts_with": "The {attribute} must start with one of the following: {values}",
            "string": "The {attribute} must be a string.",
            "timezone": "The {attribute} must be a valid zone.",
            "ulid": "The {attribute} field must be a valid ULID.",
            "unique": "The {attribute} has already been taken.",
            "uploaded": "The {attribute} failed to upload.",
            "uppercase": "The {attribute} field must be uppercase.",
            "url": "The {attribute} format is invalid.",
            "uuid": "The {attribute} must be a valid UUID.",
            "attributes": {
                "address": "address",
                "affiliate_url": "affiliate URL",
                "age": "age",
                "amount": "amount",
                "area": "area",
                "available": "available",
                "birthday": "birthday",
                "body": "body",
                "city": "city",
                "content": "content",
                "country": "country",
                "created_at": "created at",
                "creator": "creator",
                "currency": "currency",
                "current_password": "current password",
                "customer": "customer",
                "date": "date",
                "date_of_birth": "date of birth",
                "day": "day",
                "deleted_at": "deleted at",
                "description": "description",
                "district": "district",
                "duration": "duration",
                "email": "email",
                "excerpt": "excerpt",
                "filter": "filter",
                "first_name": "first name",
                "gender": "gender",
                "group": "group",
                "hour": "hour",
                "image": "image",
                "is_subscribed": "is subscribed",
                "items": "items",
                "last_name": "last name",
                "lesson": "lesson",
                "line_address_1": "line address 1",
                "line_address_2": "line address 2",
                "message": "message",
                "middle_name": "middle name",
                "minute": "minute",
                "mobile": "mobile",
                "month": "month",
                "name": "name",
                "national_code": "national code",
                "number": "number",
                "password": "password",
                "password_confirmation": "password confirmation",
                "phone": "phone",
                "photo": "photo",
                "postal_code": "postal code",
                "preview": "preview",
                "price": "price",
                "product_id": "product ID",
                "product_uid": "product UID",
                "product_uuid": "product UUID",
                "promo_code": "promo code",
                "province": "province",
                "quantity": "quantity",
                "recaptcha_response_field": "recaptcha response field",
                "remember": "remember",
                "restored_at": "restored at",
                "result_text_under_image": "result text under image",
                "role": "role",
                "second": "second",
                "sex": "sex",
                "shipment": "shipment",
                "short_text": "short text",
                "size": "size",
                "state": "state",
                "street": "street",
                "student": "student",
                "subject": "subject",
                "teacher": "teacher",
                "terms": "terms",
                "test_description": "test description",
                "test_locale": "test locale",
                "test_name": "test name",
                "text": "text",
                "time": "time",
                "title": "title",
                "updated_at": "updated at",
                "user": "user",
                "username": "username",
                "year": "year"
            }
        },
        "actions": {
            "accept": "Accept",
            "action": "Action",
            "actions": "Actions",
            "add": "Add",
            "admin": "Admin",
            "agree": "Agree",
            "archive": "Archive",
            "assign": "Assign",
            "attach": "Attach",
            "browse": "Browse",
            "cancel": "Cancel",
            "choose": "Choose",
            "choose_file": "Choose File",
            "choose_image": "Choose Image",
            "click_to_copy": "Click to copy",
            "close": "Close",
            "collapse": "Collapse",
            "collapse_all": "Collapse All",
            "comment": "Comment",
            "confirm": "Confirm",
            "connect": "Connect",
            "create": "Create",
            "delete": "Delete",
            "detach": "Detach",
            "details": "Details",
            "disable": "Disable",
            "discard": "Discard",
            "done": "Done",
            "down": "Down",
            "duplicate": "Duplicate",
            "edit": "Edit",
            "enable": "Enable",
            "expand": "Expand",
            "expand_all": "Expand All",
            "explanation": "Explanation",
            "export": "Export",
            "file": "The {attribute} must be a file.",
            "files": "Files",
            "go_home": "Go Home",
            "hide": "Hide",
            "home": "Home",
            "image": "The {attribute} must be an image.",
            "Impersonate": "Impersonate",
            "Impersonation": "Impersonation",
            "import": "Import",
            "introduction": "Introduction",
            "like": "Like",
            "load": "Load",
            "localize": "Localize",
            "named": {
                "add": "Add {name}",
                "choose": "Choose {name}",
                "create": "Create {name}",
                "delete": "Delete {name}",
                "duplicate": "Duplicate {name}",
                "edit": "Edit {name}",
                "hide": "Hide {name}",
                "import": "Import {name}",
                "new": "New {name}",
                "restore": "Restore {name}",
                "save": "Save {name}",
                "search": "Search {name}",
                "show": "Show {name}",
                "update": "Update {name}",
                "view": "View {name}"
            },
            "new": "New",
            "no": "No",
            "open": "Open",
            "open_website": "Open on the website",
            "preview": "Preview",
            "price": "Price",
            "restore": "Restore",
            "save": "Save",
            "save_and_close": "Save & Close",
            "save_and_return": "Save & Return",
            "search": "Search",
            "select": "Select",
            "select_all": "Select All",
            "send": "Send",
            "settings": "Settings",
            "show": "Show",
            "show_all": "Show All",
            "solve": "Solve",
            "submit": "Submit",
            "subscribe": "Subscribe",
            "switch": "Switch",
            "switch_to_role": "Switch To Role",
            "tag": "Tag",
            "tags": "Tags",
            "target_link": {
                "blank": "Open in a new window",
                "parent": "Open in a parent frame",
                "self": "Open in a current window",
                "top": "Open in the topmost frame"
            },
            "translate": "Translate",
            "translate_it": "Translate It",
            "unpack": "Unpack",
            "unsubscribe": "Unsubscribe",
            "up": "Up",
            "update": "Update",
            "user": "We can't find a user with that email address.",
            "view": "View",
            "yes": "Yes"
        }
    },
    "nl": {
        "(and {count} more error)": "(en {count} andere foutmelding)",
        "(and {count} more errors)": "(en {count} andere foutmeldingen)",
        "* Required fields": "* Verplichte velden",
        "+31 (0)6 29 39 68 57": "+31 (0)6 29 39 68 57",
        "6-Digit code": "6-cijferige code",
        "1234AA Plaatsnaam": "1234AA Place name",
        "1234AA Place": "1234AA Plaatsnaam",
        "{appName}": "{appName}",
        "{Attribute} {lang}": "{Attribuut} {lang}",
        "{Attribute} is invalid": "{Attribute} is ongeldig",
        "{Attribute} must be in the future.": "{Attribute} moet in de toekomst zijn.",
        "{break}": "{break}",
        "{break} minutes": "{break} minuten",
        "{causer} has invited you to apply for a shift you liked.": "{causer} heeft je uitgenodigd om te solliciteren naar een dienst die je leuk vond.",
        "{count} characters": "{count} characters",
        "{employer} invited you to fill the shift you liked. Please apply for this shift to fill it.": "{employer} heeft je uitgenodigd om zijn shift te draaien. Meld je aan om de shift te draaien!",
        "{employer} invited you to fill this shift on {start} at {location}. Please apply for this shift to fill it.": "{employer} heeft je uitgenodigd om zijn shift te draaien op {start} bij {location}. Meld je aan om de shift te draaien!",
        "{freelancer} deleted his account!": "{freelancer} heeft zijn account verwijderd!",
        "{freelancer} deleted his account and cannot work on {start} - {end}.": "{freelancer} heeft zijn account verwijderd en kan niet werken op {start} - {end}.",
        "{freelancer} disputed his amended hours": "{freelancer} heeft de aangepaste uren geweigerd",
        "{freelancer} disputed the hours that were amended by {location}.": "{freelancer} heeft de aangepaste uren van {location} geweigerd.",
        "{freelancer} just applied for your shift on {start}. Please review his application now!": "{freelancer} heeft zojuist gesolliciteerd voor je dienst op {start}. Bekijk zijn aanvraag nu!",
        "{freelancer} just applied your shift": "{freelancer} heeft zojuist gesolliciteerd voor je dienst",
        "{freelancer} just applied your shift on {start}": "{freelancer} heeft zojuist gesolliciteerd voor je dienst op {start}",
        "{freelancer} liked your shift for {position} on {start} at {location}. If you like you're able to invite {freelancer} to apply for this shift.": "{freelancer} vind je dienst voor {position} op {start} leuk bij {location}. Als je wilt, kun je {freelancer} uitnodigen om voor deze dienst te solliciteren.",
        "{freelancer} likes your shift": "{freelancer} vind je dienst leuk",
        "{freelancer} retracted application!": "{freelancer} heeft zijn aanmelding teruggetrokken!",
        "{freelancer} retracted his application for your shift on {start} - {end}.": "{freelancer} heeft zijn aanmelding teruggetrokken voor shift {start} - {end}.",
        "{location} accepted your application for the shift on {shiftStart}!": "{location} heeft je aanmelding voor de shift op {shiftStart} geaccepteerd!",
        "{location} accepted your application for this shift:": "{location} heeft je aanmelding voor deze shift geaccepteerd:",
        "{location} retracted a shift you applied for": "{location} heeft een shift teruggetrokken waar je voor was aangemeld",
        "{location} retracted a shift you applied for.": "{location} heeft een shift teruggetrokken waar je voor was aangemeld.",
        "{Model} {name}": "{Model} {name}",
        "{model} {name}": "{model} {name}",
        "{Model} accepted": "{Model} geaccepteerd",
        "{Model} added to favorites": "{Model} toegevoegd aan favorieten",
        "{Model} amended": "{Model} gewijzigd",
        "{model} applicants": "{model} sollicitanten",
        "{Model} blocked": "{Model} geblokkeerd",
        "{Model} created": "{Model} aangemaakt",
        "{Model} deleted": "{Model} verwijderd",
        "{model} detail": "Detail {model}",
        "{Model} details": "{Model} details",
        "{model} details": "Details {model}",
        "{model} edit": "{model} bewerken",
        "{Model} edited": "{Model} bewerkt",
        "{Model} invited": "{Model} uitgenodigd",
        "{Model} is not a concept": "{Model} is geen concept",
        "{Model} marked as hidden": "{Model} gemarkeerd als verborgen",
        "{Model} marked as paid": "{Model} gemarkeerd als betaald",
        "{Model} marked as unhidden": "{Model} gemarkeerd als niet verborgen",
        "{Model} marked as unpaid": "{Model} gemarkeerd als nog niet betaald",
        "{Model} name": "{Model} name",
        "{model} name": "Naam {model}",
        "{Model} overview": "{Model} overzicht",
        "{model} overview": "Overzicht {model}",
        "{Model} rejected": "{Model} geweigerd",
        "{Model} removed from favorites": "{Model} verwijderd uit favorieten",
        "{Model} retracted": "{Model} ingetrokken",
        "{Model} settings": "{Model} instellingen",
        "{model} team": "Team {model}",
        "{Model} unblocked": "{Model} gedeblokkeerd",
        "{Model} updated": "{Model} gewijzigd",
        "{Model} verified": "{Model} geverifieerd",
        "{Model} was {status_changed}": "{Model} was {status_changed}",
        "{Model} was send": "{Model} is verzonden",
        "{number} reviews": "{number} beoordelingen",
        "{position} required by {employer}": "{position} vereist door {employer}",
        "{start} - {end}": "{start} - {end}",
        "{userName} from {companyName} created location {locationNickname}": "{userName} van {companyName} heeft locatie {locationNickname} gemaakt.",
        "{userName} from {companyName} just created a location named {locationName}": "{userName} van {companyName} heeft zojuist een locatie genaamd {locationName} gemaakt.",
        "? For example, you can work here as a chef, cook or sous-chef. With about 200,000 inhabitants": "? Je kunt hier bijvoorbeeld werken als chef, kok of sous-chef. Met ongeveer 200.000 inwoners",
        "? For example, you can work here as a chef, cook or sous-chef. With about 200,000 inhabitants\" : \"? Je kunt hier bijvoorbeeld aan de slag als chefkok, kok of sous-chef. Met zo’n 200.000 inwoners staat\",": "? Je kunt hier bijvoorbeeld werken als chef-kok, kok of sous-chef. \"Met ongeveer 200.000 inwoners \"? Je kunt hier bijvoorbeeld aan de slag als chefkok, kok of sous-chef. Met zo'n 200.000 inwoners staat\",",
        "? Je kunt hier bijvoorbeeld aan de slag als chefkok, kok of sous-chef. Met zo’n 200.000 inwoners staat": "? Je kunt hier bijvoorbeeld aan de slag als chefkok, kok of sous-chef. Met zo'n 200.000 inwoners staat",
        "About": "Over",
        "About us": "Over ons",
        "Accept": "Aanvaarden",
        "Accept {model}": "Accepteer {model}",
        "Acceptance lapsed": "Acceptatie verlopen",
        "acceptance_lapsed": "acceptatie verlopen",
        "Accept a Top-Chef": "Accepteer een Top-Chef",
        "Accepted": "Aanvaard",
        "accepted": "geaccepteerd",
        "Accept Invitation": "Uitnodiging accepteren",
        "Accept the hours worked and rate Top-Chef": "Accepteer de gewerkte uren en geef de Top-Chef een rating",
        "Accountmanager": "Accountmanager",
        "Accredited": "Geaccrediteerd",
        "Action": "Actie",
        "Action points": "Actiepunten",
        "Action required": "Actie vereist",
        "Actions": "Acties",
        "Active applications": "Actieve sollicitaties",
        "Active freelancers export": "Actieve freelancers exporteren",
        "Activity": "Activiteit",
        "Add": "Toevoegen",
        "Add {attribute}": "Voeg {attribute} toe",
        "Add {model}": "Voeg {model} toe",
        "Add {name}": "Voeg {name} toe",
        "Add a {model}": "Voeg een {model} toe",
        "Add additional security to your account using two factor authentication.": "Voeg extra beveiliging toe aan je account met tweestapsverificatie.",
        "Add a new team member to your team, allowing them to collaborate with you.": "Voeg een nieuw teamlid toe aan je team, zodat ze met je kunnen samenwerken.",
        "Added.": "Toegevoegd.",
        "Add new {attribute}": "Voeg nieuwe {attribute} toe",
        "Address": "Adres",
        "Address data:": "Adresgegevens:",
        "Add Team Member": "Teamlid toevoegen",
        "Add team member": "Teamlid toevoegen",
        "Add to favorites": "Voeg toe aan favorieten",
        "Admin": "Beheerder",
        "Administration email": "Administratie e-mail",
        "Administration emails": "Administratie e-mails",
        "Administrator": "Beheerder",
        "Administrator users can perform any action.": "Beheerders kunnen elke actie uitvoeren.",
        "Adresgegevens:": "Adresgegevens",
        "A fresh verification link has been sent to your email address.": "Er is een nieuwe verificatielink naar je e-mailadres verstuurd.",
        "After signing up you can work immediately as a freelance chef. The clients can then give you a review after a shift. So you are responsible for your work and you build your own success.": "Je kan je direct inschrijven als freelance kok. De opdrachtgevers kunnen jou vervolgens een review geven na een shift. Uiteindelijk ben je zelf verantwoordelijk voor je werk en bouw je aan jouw eigen succes.",
        "Age": "Leeftijd",
        "Agree": "Akkoord",
        "All": "Alle",
        "All {attribute}": "Alle {attribute}",
        "All {model} belonging to the user": "Alle {model} behorend tot de gebruiker",
        "All employees within Top-chefs bv have committed themselves to dealing confidentially with yourn                        personal data.": "Alle medewerkers binnen Top-chefs bv hebben zich verplicht om vertrouwelijk met uw persoonsgegevens om te gaan.",
        "All employees within Top-chefs bv have committed themselves to dealing confidentially with your personal data.": "Alle medewerkers binnen Top-chefs bv hebben zich verplicht om vertrouwelijk om te gaan met uw persoonsgegevens.",
        "All frequently asked questions": "Alle veelgestelde vragen",
        "All of the people that are part of this team.": "Alle mensen die deel uitmaken van dit team.",
        "Allow cookies": "Cookies toestaan",
        "All rights reserved.": "Alle rechten voorbehouden.",
        "Already registered?": "Al geregistreerd?",
        "Already Reported": "Al gemeld",
        "Also interesting?": "Ook interessant?",
        "Also please find attached our framework agreement. We kindly ask you to sign the framework agreement and send it to us via {email} within 7 days.": "Bijgaand tref je ook onze raamovereenkomst aan. Wij verzoeken je het raamovereenkomst binnen 7 dagen te ondertekenen en naar ons terug te sturen via {email}.",
        "Also please we have emailed your our framework agreement. We kindly ask you to sign the framework agreement and send it to us via {email} within 7 days.": "Ook hebben wij u onze raamovereenkomst gemaild. Wij verzoeken u vriendelijk de raamovereenkomst te ondertekenen en binnen 7 dagen naar ons toe te sturen via {email}.",
        "Always ask": "Altijd vragen",
        "Amended": "Gewijzigd",
        "Amount": "Totaal",
        "and": "en",
        "and we will be happy to help you!": "en wij helpen je graag verder!",
        "A new shift(s) where created!": "Een nieuwe shift(s) zijn aangemaakt!",
        "A new shift(s) where created on the location {locationName}": "Er zijn nieuwe shift(s) aangemaakt op de locatie {locationName}",
        "A new shift was created!": "Er is een nieuwe shift aangemaakt!",
        "A new shift was created on the location {locationName}": "Er is een nieuwe shift aangemaakt op locatie {locationName}",
        "A new user has started the registration process but has not completed it yet.": "Een nieuwe gebruiker heeft het registratieproces gestart maar heeft het nog niet voltooid.",
        "A new verification link has been sent to the email address you provided in your profile settings.": "Er is een nieuwe verificatielink verstuurd naar het e-mailadres dat je ingegeven hebt in je profielinstellingen.",
        "A new verification link has been sent to your email address.": "Er is een nieuwe verificatielink naar je e-mailadres verstuurd.",
        "Answer": "Antwoorden",
        "Answer (en)": "Antwoorden (en)",
        "Answer (nl)": "Antwoorden (nl)",
        "A number of advantages for you as a catering company:": "Een aantal voordelen voor jou als cateringbedrijf:",
        "Any employer can sign up and place a shift after approval.": "Elke opdrachtgever kan zich aanmelden en na goedkeuring shifts plaatsen.",
        "API Token": "API-token",
        "API Token Permissions": "API-tokenrechten",
        "API Tokens": "API-tokens",
        "API tokens allow third-party services to authenticate with our application on your behalf.": "Met API-tokens kunnen andere services zich als jou authenticeren in onze applicatie.",
        "App": "App",
        "Application": "Applicatie",
        "application": "sollicitatie",
        "Applied": "aangevraagd",
        "applied": "aangevraagd",
        "Apply for new shifts": "Apply for new shifts",
        "Apply for other shifts": "Meld je aan voor andere shifts",
        "Apply Now": "Aanmelden",
        "Approve {model}": "Goedkeuren {model}",
        "Approve Hours": "Uren goedkeuren",
        "Approve hours": "Uren goedkeuren",
        "Archive": "Archiveren",
        "Are the chefs checked whether they really have a Chamber of Commerce (KvK)?": "Worden de chefs gecheckt of ze wel echt een KvK nummer hebben?",
        "Are the kilometers also registered?": "Worden de kilometers ook geregistreerd?",
        "Are you a freelance chef looking for a new assignment? You choose where and when and get paid quickly! Register now via our app.": "Ben jij een freelance kok en ben je op zoek naar een nieuwe opdracht? Jij kiest waar en wanneer en krijg snel uitbetaald! Registreer je nu via onze app.",
        "Are you looking for a chef for your open shifts? Top-Chefs has the right chefs! You choose who and when and we do all the administration!": "Ben jij op zoek naar een chef voor jouw openstaande shifts? Top-Chefs heeft de juiste chefs! Jij kiest wie en wanneer en wij doen alle administratie!",
        "Are you sure? In this case we will retract this shift and their applicants and place the shift again so that new chefs can apply. This action is irreversable": "Weet je het zeker? In dit geval trekken we deze shift en hun sollicitanten terug en plaatsen we de shift opnieuw zodat nieuwe chefs kunnen solliciteren. Deze actie is onomkeerbaar",
        "Are you sure that you want to remove the review? This will impact the total score.": "Weet je zeker dat je deze review wilt verwijderen? Dit heeft ook inloed op de totale score.",
        "Are you sure that you want to remove the review? We will still count the review in the total score.": "Weet je zeker dat je deze review wilt verwijderen? We zullen de review wel nog steeds meetellen voor de totale score.",
        "Are you sure you want to accept this applicant?": "Weet je zeker dat je deze sollicitant wilt accepteren?",
        "Are you sure you want to approve these hours ?": "Weet je zeker dat je deze uren wilt goedkeuren?",
        "Are you sure you want to delete this {model}?": "Weet je zeker dat je dit {model} wilt verwijderen?",
        "Are you sure you want to delete this team? Once a team is deleted, all of its resources and data will be permanently deleted.": "Weet je zeker dat je dit team wilt verwijderen? Zodra een team is verwijderd, worden alle bronnen en gegevens ook permanent verwijderd.",
        "Are you sure you want to delete your account? Once your account is deleted, all of its resources and data will be permanently deleted. Please enter your password to confirm you would like to permanently delete your account.": "Weet je zeker dat je je account permanent wilt verwijderen? Als je account wordt verwijderd, worden alle gekoppelde bestanden en gegevens ook permanent verwijderd. Voer alsjeblieft je wachtwoord in, om te bevestigen dat je je account permanent wilt verwijderen.",
        "Are you sure you want to invite this freelancer?": "Weet je zeker dat je deze zeflstandige wilt toevoegen?",
        "Are you sure you want to propose these new hours?": "Weet je zeker dat je deze nieuwe uren wilt voorstellen?",
        "Are you sure you want to retract this shift?": "Weet je zeker dat je deze shift wilt intrekken?",
        "Are you sure you would like to delete this API token?": "Weet je zeker dat je deze API-token wilt verwijderen?",
        "Are you sure you would like to leave this team?": "Weet je zeker dat je dit team wilt verlaten?",
        "Are you sure you would like to remove this person from the team?": "Weet je zeker dat je deze persoon uit het team wilt verwijderen?",
        "arrow": "pijl",
        "As an employer, can I also hire a chef within the app?": "Kan ik als opdrachtgever ook uren inkopen?",
        "As an employer, can I also pay in advance?": "Kan ik als opdrachtgever ook vooruitbetalen?",
        "Ascension": "Hemelvaartsdag",
        "Ascension day": "Hemelvaartsdag",
        "A selection of our clients:": "Een selectie van onze opdrachtgevers:",
        "Assign": "Toewijzen",
        "A test notification from {appName}": "Een test notificatie van {appName}",
        "A Timeout Occurred": "Time-out opgetreden",
        "At least one administration email is required. Be sure to click the plus icon to add an email.": "Tenminste één administratie e-mail is vereist. Zorg ervoor dat je op het plus icoon klikt om een e-mail toe te voegen.",
        "At least one contract email is required. Be sure to click the plus icon to add an email.": "Ten minste één e-mail met een contract is vereist. Klik op het plus-pictogram om een e-mail toe te voegen.",
        "Attach": "Koppelen",
        "Attached to the email you will find the contract": "In de bijlage vind je het contract",
        "Attached to the email you will find the contract(s)": "In de bijlage vind je het contract(en)",
        "At The Seafood Bar, we are thrilled to be working with Top Chefs. Their Customer Service department is really fantastic, so fast and very proactive and helpful. That makes the cooperation really nice!": "Bij The Seafood Bar zijn we blij met de samenwerking met Top Chefs. Hun Customer Service afdeling is werkelijk fantastisch, zo snel en heel erg pro-actief en behulpzaam. Dat maakt de samenwerking echt heel fijn!",
        "Author": "Auteur",
        "Author:": "Auteur:",
        "Average": "Gemiddeld",
        "Average {model}": "Gemiddeld {model}",
        "Back": "Terug",
        "back": "terug",
        "Back to overview": "Terug naar het overzicht",
        "Back to overview arrow": "Terug naar overzicht pijltje",
        "Backup codes": "Backup codes",
        "Bad": "Slecht",
        "Bad Gateway": "Slechte poort",
        "Badge assigned": "Badge toewijzen",
        "Badges": "Badges",
        "Badge unassigned": "Badge ingetrokken",
        "Bad Request": "Foute aanvraag",
        "Bandwidth Limit Exceeded": "Bandbreedte overschreden",
        "Bas Brouwer - Top-Chef": "Bas Brouwer - Top-Chef",
        "Bas brouwer - Top-Chef": "Bas Brouwer - Top-Chef",
        "Before continuing, could you verify your email address by clicking on the link we just emailed to you? If you didn't receive the email, we will gladly send you another.": "Kun je voor je verder gaat je e-mailadres verifiëren door op de link te klikken die we net naar je verstuurd hebben? Als je geen e-mail hebt ontvangen, sturen wij je er graag nog een.",
        "Before proceeding, please check your email for a verification link.": "Om verder te gaan, check je e-mail voor een verificatielink.",
        "Besides my family and regular job, I like having Top-Chefs put me away at different locations, I can decide when and where I work also the payments are always fast. I have already had the opportunity to work with Top-Chefs' app.<br><br>From registering to accepting shifts and hours registration is fast and flawless. Writing mutual reviews works extra motivating for me to work hard, but also gives me a voice about how we the \"chefs\" are treated on location.": "Naast mijn gezin en vaste baan vind ik het fijn dat Top-Chefs mij op verschillende locaties wegzet, ik kan zelf bepalen wanneer en waar ik werk ook de betalingen zijn altijd snel.<br><br>Ik heb al mogen werken met de app van Top-Chefs.Van het inschrijven tot het accepteren van diensten en uren het inschrijven gaat snel en vlekkeloos. Het schrijven van onderlinge reviews werkt voor mij extra motiverend om hard te werken, maar geeft mij ook een stem over hoe wij als \"chefs\" behandeld worden op locatie.",
        "Besides my family and regular job, I like having Top-Chefs put me away at different locations, I can decide when and where I work also the payments are always fast. I have already had the opportunity to work with Top-Chefs' app.nnFrom registering to accepting shifts and hours registration is fast and flawless. Writing mutual reviews works extra motivating for me to work hard, but also gives me a voice about how we the \"chefs\" are treated on location.": "Naast mijn gezin en vaste baan vind ik het fijn om via Top-Chefs mij te laten weg zetten op verschillende locaties, ik kan zelf bepalen wanneer en waar ik werk ook zijn de betalingen altijd snel. Ik heb al mogen werken met de app van Top-Chefs.nnVan het registreren tot aan accepteren van diensten en urenregistratie gaat snel en vlekkeloos. Het wederzijds reviews schrijven werkt voor mij extra motiverend om hard te werken, maar geeft mij ook een stem over hoe wij de “chefs” worden behandeld op locatie.",
        "Besides my family and regular job, I like having Top-Chefs put me away at different locations, I can decide when and where I work also the payments are always fast. I have already had the opportunity to work with Top-Chefs\\' app.<br><br>From registering to accepting shifts and hours registration is fast and flawless. Writing mutual reviews works extra motivating for me to work hard, but also gives me a voice about how we the \"chefs\" are treated on location.": "Naast mijn gezin en vaste baan vind ik het fijn dat Top-Chefs me op verschillende locaties wegzet, ik kan zelf bepalen wanneer en waar ik werk ook de betalingen gaan altijd snel. Ik heb al mogen werken met de app van Top-Chefs.<br><br>Van het inschrijven tot het accepteren van diensten en uren gaat het inschrijven snel en vlekkeloos. Het schrijven van wederzijdse beoordelingen werkt extra motiverend voor mij om hard te werken, maar geeft mij ook een stem over hoe wij als \"chefs\" worden behandeld op locatie.",
        "Besides my family and regular job, I like having Top-Chefs put me away at different locations, I can decide when and where I work also the payments are always fast. I have already had the opportunity to work with Top-Chefs\\' app.nnFrom registering to accepting shifts and hours registration is fast and flawless. Writing mutual reviews works extra motivating for me to work hard, but also gives me a voice about how we the \"chefs\" are treated on location.": "Naast mijn gezin en vaste baan vind ik het fijn om via Top-Chefs mij te laten weg zetten op verschillende locaties, ik kan zelf bepalen wanneer en waar ik werk ook zijn de betalingen altijd snel. Ik heb al mogen werken met de app van Top-Chefs.nnVan het registreren tot aan accepteren van diensten en urenregistratie gaat snel en vlekkeloos. Het wederzijds reviews schrijven werkt voor mij extra motiverend om hard te werken, maar geeft mij ook een stem over hoe wij de “chefs” worden behandeld op locatie.",
        "Besides my family and regular job, I like having Top-Chefs put me away at different locations, I can decide when and where I work also the payments are always fast. I have already had the opportunity to work with Top-Chefs\\' app.\\n\\nFrom registering to accepting shifts and hours registration is fast and flawless. Writing mutual reviews works extra motivating for me to work hard, but also gives me a voice about how we the \"chefs\" are treated on location.": "Naast mijn gezin en vaste baan vind ik het fijn dat Top-Chefs mij op verschillende locaties wegzet, ik kan zelf bepalen wanneer en waar ik werk ook de betalingen zijn altijd snel. Ik heb al mogen werken met de app van Top-Chefs.Van het inschrijven tot het accepteren van diensten en uren het inschrijven gaat snel en vlekkeloos. Het schrijven van onderlinge reviews werkt voor mij extra motiverend om hard te werken, maar geeft mij ook een stem over hoe wij als \"chefs\" behandeld worden op locatie.",
        "Billing": "Facturering",
        "Block": "Blokkeren",
        "Block {model}": "Blokkeer {model}",
        "Blocked": "Geblokkeerd",
        "Blocked {model}": "Geblokkeerd {model}",
        "Blocked freelancers": "Geblokkeerde freelancers",
        "Blog": "Blog",
        "blog": "blog",
        "Blogs": "Blogs",
        "blogs": "blogs",
        "Break": "Pauze",
        "Brought in": "Gebracht",
        "Browse": "Bladeren",
        "Browser Sessions": "Browsersessies",
        "BSN": "BSN",
        "Build a freelance team around your catering business.": "Bouw een freelanceteam om jouw horecabedrijf.",
        "Business": "Bedrijf",
        "By phone daily from {from} to {to}": "Telefonisch dagelijks van {from} tot {to}",
        "By using the Top-Chefs platform, hiring new chefs becomes a lot easier and more fun!": "Door gebruik te maken van het Top-Chefs platform, wordt het inhuren van nieuwe chefs een stuk makkelijker en leuker!",
        "Calling no longer possible? Then send us an emergency WhatsApp message!": "Bellen niet meer mogelijk? Stuur ons dan een nood WhatsApp bericht!",
        "Call to": "Bel naar",
        "Can't wait to get started with the Top-Chefs app? Download the app for free and create a profile. And remember; we’ll do your administration!": "Kan je niet wachten om aan de slag te gaan met de app van Top-Chefs? Download nu de app helemaal gratis en maak een profiel aan. En onthoud; wij doen jouw administratie!",
        "Can be updated only": "Kan alleen worden bijgewerkt",
        "Cancel": "Annuleren",
        "Can I also call Top-Chefs when I have a question?": "Kan ik bij vragen ook bellen naar Top-Chefs?",
        "Can I also work for private parties?": "Is jullie platform ook voor particuliere feesten en partijen?",
        "Can I also write a review?": "Kan ik ook reviews schrijven?",
        "Can I be removed from the platform?": "Kan ik verwijderd worden van het platform?",
        "Can I decide when I work?": "Kan ik zelf bepalen wanneer ik werk?",
        "Can I work at Top-Chefs when I only speak English?": "Kan ik bij Top-Chefs werken als ik alleen Engels spreek? ",
        "Can I work through Top-chefs without being registered with the Chamber of Commerce?": "Kan ik via Top-Chefs werken zonder dat ik bij de KvK geregistreerd ben?",
        "Can the client also block me?": "Kan de opdrachtgever mij ook blokkeren?",
        "Capital company outside honor": "Kapitaalvennootschap buiten eer",
        "Categories": "Categorieën",
        "Category": "Categorie",
        "category": "categorie",
        "Catering companies": "Horecabedrijven",
        "changes": "wijzigingen",
        "Chat": "Chat",
        "Chat with your bosses and or employers.": "Chatten met jouw chefs en of werkgevers.",
        "Check corrections": "Bekijk de correcties",
        "Checkout applicants": "Bekijk sollicitanten",
        "Check Shift": "Bekijk shift",
        "Chef": "Chef",
        "Chef icon": "Chef icon",
        "Chef price": "Prijs Chef",
        "Chef questions": "Chef vragen",
        "Chefs": "Chefs",
        "Chef training completed": "Koksopleiding voltooid",
        "Choose": "Kiezen",
        "Choose {name}": "Kies {name}",
        "Choose File": "Bestand kiezen",
        "Choose Image": "Kies afbeelding",
        "Christmas": "Eerste Kerstdag",
        "Christmas next day": "Tweede Kerstdag",
        "Cities": "Steden",
        "City": "Stad",
        "Clear time registration and costs.": "Overzichtelijke urenregistratie en kosten.",
        "Click here to re-send the verification email.": "Klik hier om de verificatie e-mail opnieuw te versturen.",
        "click here to request another": "vraag hier een andere aan",
        "Click to copy": "Klik om te kopiëren",
        "Click to copy shift": "Klik om shift te kopiëren",
        "Client Closed Request": "Klant Gesloten Verzoek",
        "Close": "Sluit",
        "Closed": "gesloten",
        "closed": "gesloten",
        "CoC (KvK) number": "KvK nummer",
        "CoC (KvK) number is invalid": "KvK nummer is ongeldig",
        "CoC (KvK) number must consist of 8 letters": "KvK nummer moet uit 8 letters bestaan",
        "CoC number": "KvK nummer",
        "Code": "Code",
        "code": "code",
        "Collapse": "Inklappen",
        "Collapse All": "Alles inklappen",
        "Coming soon!": "Binnenkort!",
        "Comment": "Reageren",
        "Companies": "Bedrijven",
        "companies": "bedrijven",
        "Company": "Bedrijf",
        "company": "bedrijf",
        "Company details": "Bedrijfsgegevens",
        "Company framework agreements": "Bedrijfsraamovereenkomsten",
        "Company name": "Bedrijfsnaam",
        "Company owner": "Eigenaar",
        "Completed": "Voltooid",
        "Completed {model}": "Voltooid {model}",
        "Completed Shifts": "Voltooide shifts",
        "Completed shifts:": "Voltooide diensten: ",
        "Complete your registration to sign up for shifts": "Voltooi je registratie om je voor diensten aan te melden",
        "Concept": "Concept",
        "conditions": "voorwaarden",
        "Confirm": "Bevestig",
        "Confirm Password": "Bevestig wachtwoord",
        "Confirm password: *": "Bevestig nieuw wachtwoord: *",
        "Conflict": "Conflict",
        "Connect": "Aansluiten",
        "Connection Closed Without Response": "Verbinding gesloten zonder reactie",
        "Connection Timed Out": "Connectie duurt te lang",
        "Contact": "Contact",
        "Content": "Content",
        "Continue": "Doorgaan",
        "contract": "contract",
        "Contract(s) have been sent to your email address": "Contract(en) zijn naar je e-mailadres verzonden",
        "Contract emails": "Contract e-mails",
        "Contracts emails": "Contracten e-mails",
        "Contract signed": "Contract ondertekend",
        "Cook": "Kok",
        "Cook price": "Prijs Kok",
        "Cost": "Kosten",
        "Cost {model}": "Kosten {model}",
        "Cost center": "Kostenplaats",
        "cost center": "kostenplaats",
        "Costs creating {model}": "Kosten creërend {model}",
        "Could not fetch data": "Kon de data niet ophalen",
        "Country": "Land",
        "Create": "Aanmaken",
        "Create {attribute}": "Creëer {attribute}",
        "Create {model}": "Creëer {model}",
        "Create {name}": "Creëer {name}",
        "Create a {model}": "Creëer een {model}",
        "Create Account": "Account aanmaken",
        "Create account": "Account aanmaken",
        "Create an account": "Maak een account",
        "Create a new team to collaborate with others on projects.": "Maak een nieuw team aan om met anderen aan projecten samen te werken.",
        "Create a password": "Maak een wachtwoord aan",
        "Create API Token": "Maak een API-token",
        "Create a shift": "Creëer een shift",
        "Created": "Gemaakt",
        "Created.": "Aangemaakt.",
        "Created at": "Aangemaakt op",
        "Created in shiftbase": "Gemaakt in shiftbase",
        "Create New Team": "Maak nieuw team aan",
        "Create Password": "Wachtwoord aanmaken",
        "Create Team": "Maak team aan",
        "Create your own preferred pool of favorite chefs.": "Creëer je eigen voorkeurspool met favoriete chefs.",
        "Creating shift exceeds maximum allowed credit": "Het aanmaken van een shift overschrijdt het maximaal toegestane tegoed",
        "Creating shift exceeds maximum allowed credit. Please contact support to resolve this issue.": "Het aanmaken van een shift overschrijdt het maximaal toegestane tegoed. Neem contact op met support om dit probleem te verhelpen.",
        "Credit Invoice": "Kredietfactuur",
        "Current {attribute}": "Huidige {attribute}",
        "Current Password": "Huidig wachtwoord",
        "Danger": "Gevaar",
        "Dashboard": "Dashboard",
        "Data exchange": "Uitwisseling gegevens",
        "Date": "Datum",
        "Date/Time": "Datum/Tijd",
        "Date {model}": "Datum {model}",
        "Date Of Birth": "Geboortedatum",
        "Date of birth": "Geboortedatum",
        "Day": "Dag",
        "Dear {freelancer},": "Beste {freelancer},",
        "Dear {location},": "Beste {location},",
        "Dear {Name},": "Beste {Name},",
        "Dear Sir / Madam,": "Geachte heer/mevrouw,",
        "Dear Top Chef,": "Beste Top-chef,",
        "Decline": "Afwijzen",
        "Decline cookies": "Cookies weigeren",
        "Default": "Standaard",
        "Delete": "Verwijder",
        "Delete {model}": "Verwijder {model}",
        "Delete {name}": "Verwijder {name}",
        "Delete Account": "Account Verwijderen",
        "Delete API Token": "API-token Verwijderen",
        "Delete Team": "Team Verwijderen",
        "Department": "Afdeling",
        "department": "afdeling",
        "department required": "afdeling vereist",
        "Depending on the type of user, the following data will be removed": "Afhankelijk van het type gebruiker, worden de volgende gegevens verwijderd",
        "Description": "Omschrijving",
        "Description {attribute}": "Beschrijving {attribute}",
        "Detach": "Losmaken",
        "Details": "Informatie",
        "Details {model}": "Details {model}",
        "Developer": "Ontwikkelaar",
        "Did you forget your password?": "Wachtwoord vergeten?",
        "Direct debit": "Automatische incasso",
        "Disable": "Schakel uit",
        "Discard": "Weggooien",
        "Discount on manhours": "Korting op manuren",
        "Disputed": "Twijfelachtig",
        "Disputed {model}": "Betwist {model}",
        "Disputed shift reviewed": "Twijfelachtige shift beoordeeld",
        "Distance from location": "Afstand van locatie",
        "Distance from location (km)": "Afstand van locatie (km)",
        "Distance in {attribute}": "Afstand in {attribute}",
        "Done": "Klaar",
        "Done.": "Klaar.",
        "done icon": "gedaan icon",
        "Dont have an account yet?": "Heb je nog geen account?",
        "Don’t have access to your authenticator device ?": "Heb je geen toegang tot je authenticator apparaat?",
        "Down": "Omlaag",
        "Download {model}": "Download {model}",
        "Download app": "Download app",
        "Download backup codes and continue.": "Download de backup codes en ga verder",
        "Download de app.": "Download the app",
        "Download de app via de app store": "Download the app from the app store",
        "Download de app via google play": "Download the app from google play",
        "Download the {brand} app": "Download de {brand} app",
        "Download the app.": "Download de app.",
        "Download the app on google play": "Download de app in google play",
        "Download the app on the app store": "Download de app in de app store",
        "Do you also do long-term assignments?": "Doen jullie ook aan lange termijn opdrachten?",
        "Do you have a question or complaint? For example, about who we share data with or our handlingn                        of your data? If so, please send an e-mail to": "Heb je een vraag of klacht? Bijvoorbeeld over met wie we gegevens delen of hoe we met uw gegevens omgaan? Stuur dan een e-mail naar",
        "Do you have a question or complaint? For example, about who we share data with or our handling of your data ? If so, please send an e - mail to": "Heb je een vraag of klacht? Bijvoorbeeld over met wie we gegevens delen of hoe we met uw gegevens omgaan? Stuur dan een e - mail naar",
        "Do you have a question or complaint? For example, about who we share data with or our handling of your data? If so, please send an e-mail to": "Heeft u een vraag of een klacht? Bijvoorbeeld over met wie wij gegevens delen of onze omgang met uw gegevens? Dan kunt u een e-mail sturen aan",
        "Do you have questions or can`t register?": "Heb je vragen of lukt het registreren niet?",
        "Draft": "Ontwerp",
        "Dress code": "Kledingvoorschriften",
        "Dropdown arrow": "Dropdown pijltje",
        "Due Date": "Vervaldatum",
        "Due date": "Vervaldatum",
        "Duplicate": "Dupliceren",
        "Duplicate {name}": "Dupliceer: naam",
        "Duration": "Duur",
        "E-mail": "Email",
        "E-mail*": "E-mailadres",
        "E-Mail Address": "E-mailadres",
        "E-mail address": "E-mailadres",
        "Easily chat with your future chefs": "Gemakkelijk chatten met jouw toekomstige chefs",
        "Easily find freelance chefs for open shifts": "Vind gemakkelijk freelance chefs voor openstaande shifts",
        "Easily find freelance operating employees for open shifts": "Vind gemakkelijk freelance bedieningsmedewerkers voor openstaande shifts",
        "Easter": "Pasen",
        "Easter Monday": "Paasmaandag",
        "Easter monday": "Paasmaandag",
        "Easy registration.": "Makkelijke registratie.",
        "Easy time registration": "Makkelijke urenregistratie",
        "Easy time registration.": "Eenvoudige tijdregistratie.",
        "Edit": "Aanpassen",
        "Edit {model}": "Bewerk {model}",
        "Edit {name}": "Bewerk {name}",
        "Editor": "Redacteur",
        "Editor users have the ability to read, create, and update.": "Redacteurs hebben de bevoegdheid om te lezen, te creëren en te bewerken.",
        "Edit Profile": "Profiel bewerken",
        "Eelco Meerman": "Eelco Meerman",
        "Email": "E-mailadres",
        "email": "e-mail",
        "Email #{position} is not valid": "E-mailadres #{position} is niet geldig",
        "Email: {email}": "E-mailadres {email}",
        "Email: {employerEmail}": "E-mailadres: {employerEmail}",
        "Email address": "E-mailadres",
        "Email Password Reset Link": "Verstuur link",
        "Emergency number daily from {from} to {to}": "Noodnummer dagelijks van {from} tot {to}",
        "Employer": "Werkgever",
        "employer": "werkgever",
        "Employer, position or city*": "Opdrachtgever, functie of plaats",
        "Employer, position or place*": "Opdrachtgever, functie of plaats",
        "Employer questions": "Werkgever vragen",
        "Employers": "Werkgever",
        "employers": "werkgevers",
        "Employers, position or city*": "Opdrachtgever, functie of plaats",
        "Enable": "Schakel in",
        "Enclosed you will find the invoice for the worked services.": "Bijgaand treft u de factuur van de betreffende gewerkte diensten.",
        "End": "Einde",
        "End Date": "Einddatum",
        "End time": "Eindtijd",
        "end time": "eindtijd",
        "End week": "Einde week",
        "Ensure your account is using a long, random password to stay secure.": "Zorg ervoor dat je account een lang, willekeurig wachtwoord gebruikt om veilig te blijven.",
        "Entrepreneur of a": "Ondernemer van een",
        "Every day we match hospitality staff & hospitality companies! Are you a freelance waitress and looking for a new assignment? You choose where and when and get paid quickly! Register now via our app.": "Elke dag matchen wij horecapersoneel & horecabedrijven! Ben jij een freelance bedieningsmedewerker en ben je op zoek naar een nieuwe opdracht? Jij kiest waar en wanneer en krijg snel uitbetaald! Registreer je nu via onze app.",
        "Every day we match Top Chefs & hospitality companies! Are you a freelance chef looking for a new assignment? You choose where and when and get paid quickly! Register now via our app.": "Elke dag matchen wij Top-Chefs & horecabedrijven! Ben jij een freelance kok en ben je op zoek naar een nieuwe opdracht? Jij kiest waar en wanneer en krijg snel uitbetaald! Registreer je nu via onze app.",
        "Every day we match Top Chefs & Restaurants! With success, because we do not work with temporary or permanent contracts. You determine the shifts yourself! So do you want another Top Chef? No problem, because you choose.": "Elke dag matchen wij Top-Chefs & Restaurants! Met succes, want wij werken niet met tijdelijke of vaste contracten. Jij bepaalt namelijk zelf de shifts! Dus wil jij een andere Top-Chef? Geen probleem, want jij kiest.",
        "Everyone": "Iedereen",
        "Every single day we match Top-Chefs & Restaurants! And with success, because we don’t work with fixed-term and permanent contracts. Or on the other side; do you want to work in different restaurants? No problem. You decide!": "Elke dag matchen wij Top-Chefs & Restaurants! Met succes, want wij werken niet met tijdelijke of vaste contracten. Jij bepaalt namelijk zelf de shifts! Of ben jij een Top-Chef en wil je werken in verschillende restaurants? Geen probleem, want jij kiest!",
        "Every single day we match Top-Chefs & Restaurants! And with success, because we don’t work with fixed-term and permanent contracts. So do you want another Top-Chef? No problem. You decide!": "Elke dag matchen wij Top-Chefs & Restaurants! Met succes, want wij werken niet met tijdelijke of vaste contracten. Jij bepaalt namelijk zelf de shifts! Dus wil jij een andere Top-Chef? Geen probleem, want jij kiest.",
        "Every single day we match Top-Chefs & Restaurants! And with success, because we don’t work with fixed-term and permanent contracts. So do you want another Top-Chef? Or on the other side; do you want to work in different restaurants? No problem. You decide!": "Elke dag matchen wij Top-Chefs & Restaurants! Met succes, want wij werken niet met tijdelijke of vaste contracten. Jij bepaalt namelijk zelf de shifts! Dus wil jij een andere Top-Chef? Of ben jij een Top-Chef en wil je werken in verschillende restaurants? Geen probleem, want jij kiest!",
        "ex.btw": "excl. BTW",
        "Example: NL123456789B00": "Voorbeeld: NL123456789B00",
        "Excerpt": "Korte inleiding",
        "excerpt": "Korte inleiding",
        "Expand": "Uitvouwen",
        "Expand All": "Alles uitvouwen",
        "Expectation Failed": "Niet voldaan aan verwachting",
        "Explanation": "Uitleg",
        "Export": "Exporteren",
        "Export Shifts": "Export shifts",
        "Export shifts": "Export shifts",
        "F": "V",
        "Failed Dependency": "Gefaalde afhankelijkheid",
        "FAQ": "FAQ",
        "FAQ chef": "FAQ chef",
        "FAQ employer": "FAQ opdrachtgever",
        "FAQ general": "FAQ algemeen",
        "FAQs": "FAQ's",
        "Fast payment, between 10 and 12 days.": "Snelle uitbetaling, tussen 10 en 12 dagen.",
        "Fast payment.": "Snelle betaling.",
        "Fast registration": "Snelle registratie",
        "Favorite {model}": "Favoriet {model}",
        "Favorite freelancers": "Favoriete freelancers",
        "Favorites": "Favorieten",
        "filament-breezy:{default}.clipboard.link": "filament-breezy:{default}.clipboard.link",
        "filament-breezy:{default}.clipboard.tooltip": "filament-breezy:{default}.clipboard.tooltip",
        "filament-breezy:{default}.profile.2fa.description": "filament-breezy:{default}.profile.2fa.description",
        "filament-breezy:{default}.profile.2fa.enabled.description": "filament-breezy:{default}.profile.2fa.enabled.description",
        "filament-breezy:{default}.profile.2fa.enabled.store_codes": "filament-breezy:{default}.profile.2fa.enabled.store_codes",
        "filament-breezy:{default}.profile.2fa.enabled.title": "filament-breezy:{default}.profile.2fa.enabled.title",
        "filament-breezy:{default}.profile.2fa.finish_enabling.description": "filament-breezy:{default}.profile.2fa.finish_enabling.description",
        "filament-breezy:{default}.profile.2fa.finish_enabling.title": "filament-breezy:{default}.profile.2fa.finish_enabling.title",
        "filament-breezy:{default}.profile.2fa.must_enable": "filament-breezy:{default}.profile.2fa.must_enable",
        "filament-breezy:{default}.profile.2fa.not_enabled.description": "filament-breezy:{default}.profile.2fa.not_enabled.description",
        "filament-breezy:{default}.profile.2fa.not_enabled.title": "filament-breezy:{default}.profile.2fa.not_enabled.title",
        "filament-breezy:{default}.profile.2fa.setup_key": "filament-breezy:{default}.profile.2fa.setup_key",
        "filament-breezy:{default}.profile.2fa.title": "filament-breezy:{default}.profile.2fa.title",
        "filament-breezy:{default}.profile.password.heading": "filament-breezy:{default}.profile.password.heading",
        "filament-breezy:{default}.profile.password.subheading": "filament-breezy:{default}.profile.password.subheading",
        "filament-breezy:{default}.profile.password.submit.label": "filament-breezy:{default}.profile.password.submit.label",
        "filament-breezy:{default}.profile.personal_info.heading": "filament-breezy:{default}.profile.personal_info.heading",
        "filament-breezy:{default}.profile.personal_info.subheading": "filament-breezy:{default}.profile.personal_info.subheading",
        "filament-breezy:{default}.profile.personal_info.submit.label": "filament-breezy:{default}.profile.personal_info.submit.label",
        "filament-breezy:{default}.profile.sanctum.copied.label": "filament-breezy:{default}.profile.sanctum.copied.label",
        "filament-breezy:{default}.profile.sanctum.create.message": "filament-breezy:{default}.profile.sanctum.create.message",
        "filament-breezy:{default}.profile.sanctum.description": "filament-breezy:{default}.profile.sanctum.description",
        "filament-breezy:{default}.profile.sanctum.title": "filament-breezy:{default}.profile.sanctum.title",
        "File": "Bestand",
        "Files": "Bestanden",
        "Filled": "Gevuld",
        "Filled in shiftbase": "Ingevuld in shiftbase",
        "Fill in the 6-digit code": "Vul de 6-cijferige code in",
        "Fill shift": "Shift vullen",
        "Filter by {attribute}": "Filter op {attribute}",
        "Finding the right freelance chef or working at the place you would like to work is not easy. With our platform, we are now changing that and can connect Top-Chefs and restaurants quickly and easily. Because both restaurants and chefs have Top-Chefs subscriptions, we create a place where they meet. With our new platform, we are the matchmaker in the culinary world!": "Het vinden van de juiste freelance chef of werken op de plek waar jij zou willen werken is niet gemakkelijk. Met ons platform brengen wij daar nu verandering in en kunnen wij Top-Chefs en restaurants snel en eenvoudig met elkaar verbinden. Doordat zowel de restaurants als de chefs een Top-Chefs abonnement hebben, creëren wij een plek waar ze elkaar ontmoeten. Met ons nieuwe platform zijn wij dé matchmaker in de culinaire wereld!",
        "Finding the right independent cook is not easy. The Top-Chefs platform will change that! We can connect freelance chefs and restaurants quickly and easily. Because both the catering companies and the independent chefs have a Top Chefs subscription, we create a place where they meet. With our new platform we are the matchmaker in the culinary world.": "Het vinden van de juiste zelfstandig kok is niet gemakkelijk. Met het platform van Top-Chefs wordt daar verandering in gebracht! Wij kunnen freelance chefs en restaurants snel en eenvoudig met elkaar verbinden. Doordat zowel de horecabedrijven als de zelfstandig koks een Top-Chefs abonnement hebben, creëren wij een plek waar ze elkaar ontmoeten. Met ons nieuwe platform zijn wij dé matchmaker in de culinaire wereld.",
        "Finding the right independent cook is not easy. The Top-Chefs platform will change that! We cann                        connect freelance chefs and restaurants quickly and easily. Because both the catering companiesn                        and the independent chefs have a Top Chefs subscription, we create a place where they meet. Withn                        our new platform we are the matchmaker in the culinary world.": "De juiste zelfstandige kok vinden is niet eenvoudig. Het Top-Chefs platform zal dat veranderen! Wij kunnen freelance koks en restaurants snel en gemakkelijk met elkaar verbinden. Omdat zowel de cateringbedrijvenn als de zelfstandige koks een Top Chefs abonnement hebben, creëren wij een plek waar ze elkaar ontmoeten. Met ons nieuwe platform zijn wij de matchmaker in de culinaire wereld.",
        "Find the perfect {brand} for your open shift": "Vind de perfecte {brand} voor je open dienst",
        "Find the perfect chefs for your open shift | Top-Chefs": "Vind de perfecte chefs voor je open shift | Top-Chefs",
        "Find the perfect Top-Chef for your open shift": "Vind de perfecte Top-Chef voor je open shift!",
        "Find the right freelance chef or work in the place where you would like to work. This wasn’t that easy. But now, we can connect restaurants and Top Chefs fast and easy. Because both the restaurants and the chefs subscribe to Top-Chefs, we create a place where both meet. With our new app we are the Matchmaker in the culinary world! Of course you ask yourself the question if it’s not a lot of work for you. The answer is no, because guess what… Top-Chefs takes care of all the administrative work. All you have to do is sign up in our user-friendly app. After your subscription you can choose the shift or chef you want. You decide and we’ll do the work!": "Een geschikte freelance chef vinden of werken op de plek waar jij graag zou willen werken. Dit was niet vanzelfsprekend, met de nadruk op was. Restaurants en Top-Chefs snel en makkelijk met elkaar koppelen is inmiddels geen probleem meer. Doordat zowel de restaurants als de chefs zich inschrijven op Top-Chefs, zorgen wij ervoor dat vraag en aanbod elkaar ontmoeten. Met de nieuwe app zijn wij de Matchmaker in de culinaire wereld! Natuurlijk vraag jij jezelf af of dat alsnog niet heel veel werk is. Het antwoord is nee, want guess what… Top-Chefs zorgt voor alle administratieve afhandelingen. Het enige wat jij hoeft te doen is je aanmelden in onze gebruiksvriendelijke app. Vervolgens maak je gebruik van het ruime aanbod. Jij bepaalt en wij doen het werk!",
        "Find the right freelance chef or work in the place where you would like to work. This wasn’t that easy. But now, we can connect restaurants and Top Chefs fast and easy. Because both the restaurants and the chefs subscribe to Top-Chefs, we create a place where both meet. With our new platform we are the Matchmaker in the culinary world! Of course you ask yourself the question if it’s not a lot of work for you. The answer is no, because guess what… Top-Chefs takes care of all the administrative work. All you have to do is sign up. After your subscription you can choose the shift or chef you want. You decide and we’ll do the work!": "Een geschikte freelance chef vinden of werken op de plek waar jij graag zou willen werken. Dit was niet vanzelfsprekend, met de nadruk op was. Restaurants en Top-Chefs snel en makkelijk met elkaar koppelen is inmiddels geen probleem meer. Doordat zowel de restaurants als de chefs zich inschrijven op Top-Chefs, zorgen wij ervoor dat vraag en aanbod elkaar ontmoeten. Met ons platform zijn wij de Matchmaker in de culinaire wereld! Natuurlijk vraag jij jezelf af of dat alsnog niet heel veel werk is. Het antwoord is nee, want guess what… Top-Chefs zorgt voor alle administratieve afhandelingen. Het enige wat jij hoeft te doen is je aanmelden. Vervolgens maak je gebruik van het ruime aanbod. Jij bepaalt en wij doen het werk!",
        "Find your perfect Top-Chef now": "Vind nu de perfecte Top-Chef!",
        "Finish enabling two factor authentication.": "Inschakelen van tweestapsverificatie afronden.",
        "First Name": "Voornaam",
        "First name": "Voornaam",
        "First Name*": "Voornaam",
        "Follow us": "Volg ons",
        "Food and drinks included": "Eten en drinken inbegrepen",
        "Food and drinks included?": "Eten en drinken inbegrepen?",
        "Forbidden": "Geen toegang",
        "for effective management and policy;": "voor doelmatig beheer en beleid;",
        "Forgot password?": "Wachtwoord vergeten?",
        "Forgot your CoC number? You can also type in the name of your company.": "KvK nummer vergeten? Je kunt ook de bedrijfsnaam invullen.",
        "Forgot Your Password?": "Wachtwoord Vergeten?",
        "Forgot your password?": "Wachtwoord vergeten?",
        "Forgot your password? No problem. Just let us know your email address and we will email you a password reset link that will allow you to choose a new one.": "Wachtwoord vergeten? Geen probleem. Geef hier je e-mailadres in en we sturen je een link via mail waarmee je een nieuw wachtwoord kan instellen.",
        "For which freelancers": "Voor welke freelancers",
        "For which TOP-CHEFS?": "Voor welke TOP-CHEFS?",
        "For your security, please confirm your password to continue.": "Bevestig voor de zekerheid je wachtwoord om door te gaan.",
        "Found": "Gevonden",
        "Foundation": "Stichting",
        "Founder": "Eigenaar",
        "Fr": "Vr",
        "Framework agreement": "Framework overeenkomst",
        "framework agreement": "framework overeenkomst",
        "Framework agreement signed": "Raamovereenkomst getekend",
        "Free app usage": "Gratis gebruik app",
        "Freelancer": "Freelancer",
        "freelancer": "freelancer",
        "Freelancer (optional)": "Freelancer (optioneel)",
        "Freelancer applied notification sent": "Freelancer aanmelding verzonden",
        "Freelancer blocked": "Freelancer geblokkeerd",
        "Freelancer has already applied for overlapping shift": "Freelancer heeft al een overlappende shift",
        "Freelancer has multiple shifts on same day": "Freelancer heeft meerdere diensten op dezelfde dag",
        "Freelancer has one or more overlapping shifts": "Freeelancer heeft een of meerdere overlappende shifts",
        "Freelancer Hours": "Zelfstandig uren",
        "Freelancers": "Freelancers",
        "freelancers": "freelancers",
        "Freelancers can only use our mobile app to login": "Freelancers kunnen onze mobiele app alleen gebruiken om in te loggen",
        "Freelancer unblocked": "Freelancer gedeblokkeerd",
        "Free parking": "Gratis parkeren",
        "Free use app": "Gratis gebruik app",
        "Free use of the Top-Chefs app, in which you can register for free and quickly.": "Gratis gebruik van de Top-Chefs app, waarin je je gratis en snel kunt registreren.",
        "Frequently Asked Questions": "Veelgestelde vragen",
        "From": "Van",
        "From a chef to chef!<br /> Top-Chefs stands for reliability, loyalty, quality & craftmanship. Not hiring but collaboration!": "Van chef tot chef!<br /> Top-Chefs staat voor betrouwbaarheid, loyaliteit, kwaliteit & vakmanschap. Geen inhuren maar samenwerken!",
        "From the authenticator app": "Van de authenticator-app",
        "Function": "Functie",
        "Fwo factor authentication": "Tweestapsverificatie",
        "Gallery": "Galerij",
        "Gateway Timeout": "Gateway-time-out",
        "General": "Algemeen",
        "General questions": "Algemene vragen",
        "General terms": "Algemene voorwaarden",
        "general terms": "algemene voorwaarden",
        "Get in touch": "Neem contact op",
        "Get paid instantly": "Snelle uitbetaling",
        "Get paid quickly": "Snelle uitbetaling",
        "Get started!": "Starten maar!",
        "Get started and get paid weekly": "Ga aan de slag en krijg wekelijks uitbetaald",
        "Give freelance chefs reviews.": "Geef freelance chefs beoordelingen.",
        "Given {model}": "Gegeven {model}",
        "Go Home": "Terug naar de voorpagina",
        "Gone": "Verdwenen",
        "Good": "Goed",
        "Good friday": "Goede vrijdag",
        "Google Authenticator": "Google-authenticator",
        "Go to page {page}": "Ga naar pagina {page}",
        "Great! You have accepted the invitation to join the {team} team.": "Mooizo! Je hebt de uitnodiging om deel te nemen aan {team} geaccepteerd.",
        "Head Development": "Hoofd Development",
        "Header": "Kop",
        "Head Marketing": "Hoofd Marketing",
        "Helaas, er zijn geen shifts met deze filters gevonden. Pas deze aan en zoek opnieuw.": "Helaas zijn er geen shifts met deze filters gevonden. Pas deze aan en zoek opnieuw.",
        "Hello!": "Hallo!",
        "Hi": "Hoi",
        "Hi {firstName}!": "Hallo {firstName}!",
        "Hi {userName}": "Hi {username}",
        "Hidden": "Verborgen",
        "Hide": "Verbergen",
        "Hide {name}": "Verberg {name}",
        "Hire a Chef": "Huur een Chef",
        "Hi Support,": "Hi Support,",
        "Hi Top-Chef": "Hallo Top-Chef",
        "Home": "Startpagina",
        "Homepage": "Homepage",
        "hospitality business?": "horecabedrijf?",
        "hospitality company": "horecabedrijf",
        "hour": "uur",
        "Hourly Cost": "Uurtarief",
        "Hourly cost for {position}": "Uurtarief voor {position}",
        "Hours": "Uren",
        "hours": "uren",
        "Hours accredited": "Uren geaccrediteerd",
        "Hours registration in the app/platform, direct to shift approval.": "Urenregistratie in de app/platform, direct naar de shift goed te keuren.",
        "How can we help you?": "Hoe kunnen we je helpen?",
        "How does it work?": "Hoe werkt het?",
        "How do I call in sick?": "Hoe moet ik mij ziek melden als kok?",
        "How many shifts can I upload?": "Hoeveel shifts kan ik uploaden?",
        "How much experience do I need to work through Top-Chefs?": "Hoeveel ervaring heb ik nodig nodig om via Top-Chefs te werken?",
        "HTTP Version Not Supported": "HTTP-versie wordt niet ondersteund",
        "I'm a teapot": "Ik ben een theepot",
        "I'm a Top-Chef questions": "Ik ben een Top-Chef vragen",
        "I'm looking for personnel": "Ik ben op zoek naar personeel",
        "I'm looking for work": "Ik zoek werk.",
        "I agree to the": "Ik ga akkoord met de",
        "I agree to the {terms_of_service} and {privacy_policy}": "Ik ga akkoord met de {terms_of_service} en de {privacy_policy}",
        "I am a test notification from {appName}": "Ik ben een test notificatie van {appName}",
        "I am a Top-Chef!": "Ik ben een Top-Chef!",
        "I am looking to:": "Ik ben op zoek naar:",
        "IBAN": "IBAN",
        "Id card": "Identiteitskaart",
        "I declare that I have read and agree to the": "Ik verklaar kennis te hebben genomen van en akkoord te gaan met de",
        "Identification": "Identificatie",
        "identification": "identificatie",
        "Identification back": "Identificatie achterkant",
        "Identification front": "Identificatie voorkant",
        "Identification number": "Identificatie nummer",
        "Identification number valid until": "Identificatie nummer geldig tot",
        "Identification type": "Identificatie type",
        "If necessary, you may log out of all of your other browser sessions across all of your devices. Some of your recent sessions are listed below; however, this list may not be exhaustive. If you feel your account has been compromised, you should also update your password.": "Indien nodig kun je uitloggen bij al je andere browsersessies op al je apparaten. Sommige van je recente sessies staan hieronder vermeld; deze lijst is echter mogelijk niet volledig. Als je vermoedt dat je account is gecompromitteerd, wijzig dan ook je wachtwoord.",
        "If the employer is not satisfied, they can choose to block you. The shifts of this client will then no longer show up on your timeline.": "Als de opdrachtgever niet tevreden is, kan hij of zij ervoor kiezen om je te blokkeren. De opdrachten van deze opdrachtgever komen dan niet meer tussen de beschikbare shifts",
        "If this {model} needs to be removed, use the button below": "Als dit {model} verwijderd moet worden, gebruik dan de knop hieronder",
        "If you're having trouble clicking the \"{actionText}\" button, copy and paste the URL below into your web browser:": "Als je problemen hebt met het klikken op de \"{actionText}\" knop, kopieer en plak dan de URL hieronder:",
        "If you're having trouble clicking the \"{actionText}\" button, copy and paste the URL belowninto your web browser:": "Als je problemen hebt met de \"{actionText}\" knop, kopieer en plak de URL hierondernin je webbrowser:",
        "If you already have an account, you may accept this invitation by clicking the button below:": "Als je al een account hebt, kan je deze uitnodiging accepteren door op onderstaande knop te klikken:",
        "If you created this {model} by mistake, it can be removed.": "Als u dit {model} per ongeluk heeft aangemaakt, kan het worden verwijderd.",
        "If you did not create an account, no further action is required.": "Als je geen account hebt aangemaakt hoef je verder niets te doen.",
        "If you did not expect to receive an invitation to this team, you may discard this email.": "Als je geen uitnodiging voor dit team verwachtte, mag je deze mail negeren.",
        "If you did not receive the email": "Als je de e-mail niet hebt ontvangen",
        "If you did not request a password reset, no further action is required.": "Als je geen wachtwoordherstel hebt aangevraagd, hoef je verder niets te doen.",
        "If you do not have an account, you may create one by clicking the button below. After creating an account, you may click the invitation acceptance button in this email to accept the team invitation:": "Als je nog geen account hebt, kan je er een aanmaken door op onderstaande knop te klikken. Na het aanmaken van je account kan je op de uitnodiging in deze mail klikken om die te accepteren:",
        "If you encounter any difficulties during the registration process, please do not hesitate to send us the necessary information at support@top-chefs.nl. Our team stands ready to assist you at any time.": "Mocht je tijdens het registratieproces problemen ondervinden, aarzel dan niet om ons de benodigde informatie te sturen naar support{'@'}top-chefs.nl. Ons team staat altijd klaar om je te helpen.",
        "If you have any other questions and/or comments, please contact our support department at: {telNo}": "Heeft u nog andere vragen en/of opmerkingen neem dan contact op met onze support afdeling via: {telNo}.",
        "If you have not yet done so, you can directly create a location under the settings. You can also add your staff members under each location. Once you have created a location, you can immediately schedule your services.": "Mocht je dit nog niet gedaan hebben kan je onder de instellingen direct een locatie aanmaken. Onder elke locatie kan je ook je personeelsleden toevoegen. Zodra je een locatie hebt aangemaakt kan je direct jouw diensten inplannen.",
        "If you think this was a mistake, please contact us via email or phone.": "Als je denkt dat dit een fout is, neem dan contact met ons op via e-mail of telefoon.",
        "If you want to use your rights, you can make this known verbally or by means of an email. This email can be sent to backoffice@top-chefs.nl. Your interests can also represented by a representative (such as a written proxy, or your trustee or mentor).": "Als u gebruik wilt maken van uw rechten, kunt u dit mondeling of via een e-mail kenbaar maken. Deze e-mail kun je sturen naar backoffice{'@'}top-chefs.nl. Je belangen kunnen ook worden behartigd door een vertegenwoordiger (zoals een schriftelijke volmacht, of je vertrouwenspersoon of mentor).",
        "I have saved the backup codes": "Ik heb de backup codes opgeslagen",
        "Image": "Afbeelding",
        "Image impression": "Sfeerafbeelding",
        "Image of app": "Afbeelding van de app",
        "Image of laptop": "Afbeelding van een laptop",
        "Impersonate": "Voordoen als een ander",
        "Impersonation": "Nabootsing van identiteit",
        "Import": "Importeren",
        "Import {name}": "Importeren {name}",
        "Impression image": "Sfeerafbeelding",
        "IM Used": "Ik ben gebruikt",
        "Inactive freelancers export": "Inactieve freelancers exporteren",
        "In addition, we take care of all the administration, through the online registration system in the Top-Chefs app. At a glance, you can easily check your hours on our applications. By using our platform, you create a freelance team around your catering company, so you always have enough catering staff.": "Daarnaast zorgen wij voor alle administratie, doormiddel van het online registratiesysteem in de Top-Chefs app. In één oogopslag kan je gemakkelijk de uren controleren op onze applicaties. Door gebruik te maken van ons platform, creëer je een freelanceteam om jouw horecabedrijf heen, zodat je altijd genoeg horecapersoneel hebt.",
        "In addition to being registered at the Chamber of Commerce (KvK), you are expected to be able to work on your own.": "Naast dat je bij de KvK geregistreerd moet zijn, wordt er verwacht dat je minimaal zelfstandig kan werken.",
        "In addition to my family and permanent job, I like having Top-Chefs put me away at different locations, I can decide when and where I work, and the payments are always fast. I have already been able to work with the Top Chefs app. From registering to accepting services and time registration is fast and flawless. The option to chat with employers in case of any problems or questions is also nice. Writing mutual reviews motivates me to work hard, but also gives me a say in how we the “chefs” are treated on location.": "Naast mijn gezin en vaste baan vind ik het fijn om via Top-Chefs mij te laten weg zetten op verschillende locaties, ik kan zelf bepalen wanneer en waar ik werk ook zijn de betalingen altijd snel. Ik heb al mogen werken met de app van Top-Chefs.nnVan het registreren tot aan accepteren van diensten en urenregistratie gaat snel en vlekkeloos. Ook is de optie voor chatten met werkgevers bij eventuele problemen of vragen wel fijn. Het wederzijds reviews schrijven werkt voor mij extra motiverend om hard te werken, maar geeft mij ook een stem over hoe wij de “chefs” worden behandeld op locatie.",
        "incl.btw": "incl. BTW",
        "Independent": "Zelfstandige",
        "Independent chef": "Zelfstandig werkend kok",
        "Independent chef price": "Prijs onafhankelijke kok",
        "in de top 10 grootste steden van Nederland. Reken maar dat dat kansen oplevert voor jouw carrière! Kijk gauw welke shifts er hier voor jou open staan.": "in de top 10 grootste steden van Nederland. Reken maar dat dat kansen oplevert voor jouw carrière! Kijk gauw welke diensten er hier voor jou open staan.",
        "I need a Top-Chef!": "Ik wil een Top-Chef!",
        "In how many days will my invoice be paid?": "Binnen hoeveel dagen wordt mijn factuur betaald?",
        "In principle, no processing takes place for other purposes.": "Er vindt in beginsel geen verwerking plaats voor andere doeleinden.",
        "Insufficient Storage": "Onvoldoende opslag",
        "Internal remark": "Interne opmerking",
        "Internal Server Error": "Interne serverfout",
        "in the top 10 largest cities in the Netherlands. You can bet that this will provide opportunities for your career! Take a quick look at which shifts are open for you here.": "in de top 10 grootste steden van Nederland. Reken maar dat dat kansen oplevert voor jouw carrière! Kijk gauw welke shifts er hier voor jou open staan.",
        "in the top 10 largest cities in the Netherlands. You can bet that this will provide opportunities for your career! Take a quick look at which shifts are open for you here.\" : \"in de top 10 grootste steden van Nederland. Reken maar dat dat kansen oplevert voor jouw carrière! Kijk gauw welke shifts er hier voor jou open staan.\"": "in de top 10 van grootste steden in Nederland. Reken maar dat dit kansen biedt voor jouw carrière! Bekijk hier snel welke diensten er voor jou openstaan.\" : \"in de top 10 grootste steden van Nederland. Reken maar dat dat kansen oplevert voor jouw carrière! Kijk gauw welke ploegen er hier voor jou openstaan.",
        "Introduction": "Invoering",
        "Invalid JSON was returned from the route.": "Er is ongeldige JSON teruggekomen van de route.",
        "Invalid phone number": "Ongeldig telefoonnummer",
        "Invalid SSL Certificate": "Ongeldig SSL-certificaat",
        "Invitation": "Uitnodiging",
        "Invite": "Uitnodigen",
        "Invited": "Uitgenodigd",
        "Invite freelancer": "Freelancer uitnodigen",
        "Invoice": "Factuur",
        "invoice": "factuur",
        "Invoice date": "Factuur datum",
        "Invoice due date": "Factuur vervaldatum",
        "Invoice Number": "Factuurnummer",
        "Invoice number": "Factuurnummer",
        "Invoice paid to freelancer": "Factuur betaald aan freelancer",
        "Invoice received from freelancer": "Factuur ontvangen van freelancer",
        "Invoice reference": "Factuur referentie",
        "Invoices": "Facturen",
        "invoices": "Facturen",
        "Invoice status": "Factuur status",
        "Is already a team members": "Is al een teamlid",
        "Is it possible to work directly with a restaurant after an assignment from Top-Chefs?": "Kan er rechtstreeks bij een restaurant worden gewerkt na een opdracht van Top-Chefs?",
        "Is there a minimum number of hours you have to work?": "Is er een minimaal aantal uur die je moet werken?",
        "Is there free parking available on this location?": "Is het mogelijk om gratis te parkeren op deze locatie?",
        "It is recommended to keep the {attribute} between {min} and {max} characters long": "Het wordt aanbevolen het {attribuut} tussen {min} en {max} tekens lang te houden.",
        "I want a Top-Chef": "Ik zoek een Top-Chef",
        "I want a Top-Chef questions": "Ik zoek een Top-Chef vragen",
        "I’m a Top-Chef": "Ik ben een Top-Chef",
        "Just now": "Zojuist",
        "kilometer": "kilometer",
        "Kind regards,": "Met vriendelijke groet,",
        "Kingsday": "Koningsdag",
        "KM around": "KM rondom",
        "Known": "Bekend",
        "KVK number": "KvK nummer",
        "KvK number": "KvK nummer",
        "Language": "Taal",
        "Language dropdown icon": "Taalswitcher icon",
        "Language flag": "Taal vlag",
        "Lapsed": "Vervallen",
        "Last active": "Laatst actief",
        "Last activity": "Laatste activiteit",
        "Last activity from": "Laatste activiteit van",
        "Last activity from/to": "Laatste activiteit van/tot",
        "Last activity source": "Laatste activiteit bron",
        "Last activity to": "Laatste activiteit tot",
        "Last Name": "Achternaam",
        "Last name": "Achternaam",
        "Last Name*": "Achternaam",
        "Last used": "Laatst gebruikt",
        "Latitude": "Breedtegraad",
        "Leave": "Verlaat",
        "Leave empty to hide from public": "Laat leeg om te verbergen voor het publiek",
        "Leave Team": "Team Verlaten",
        "Legal form": "Wettelijk document",
        "Length Required": "Lengte vereist",
        "Let Top-Chefs fill this shift": "Laat topchefs deze shift invullen",
        "Let Top-Chefs pick one of my favorites to fill this shift": "Laat Top-Chefs iemand kiezen uit een van mijn favorieten",
        "Liberation day": "Bevrijdingsdag",
        "Libration day": "Bevrijdingsdag",
        "Like": "Leuk vinden",
        "Liked": "Ik vond  leuk.",
        "Limited company": "besloten vennootschap",
        "limited company": "besloten vennootschap",
        "Limited partnership": "commanditaire vennootschap",
        "limited partnership": "commanditaire vennootschap",
        "line": "lijn",
        "Load": "Laden",
        "Localize": "Lokaliseren",
        "Location": "Locatie",
        "location": "locatie",
        "Location {location} has been updated": "Locatie {location} is bijgewerkt",
        "Location blocked": "Locatie geblokkeerd",
        "Location created for {companyName}": "Locatie aangemaakt voor {companyName}",
        "Location Hours": "Locatie uren",
        "Location icon": "Locatie icon",
        "Location name": "Locatie naam",
        "location radius": "locatie straal",
        "Locations": "Locaties",
        "locations": "locaties",
        "Location unblocked": "Locatie gedeblokkeerd",
        "Location was created for {companyName}": "Locatie is gemaakt voor {companyName}",
        "Locked": "Afgesloten",
        "Log in": "Inloggen",
        "Login": "Inloggen",
        "Login icon": "Inlog icon",
        "Login with a recovery code.": "Log in met een backup code",
        "Log Out": "Uitloggen",
        "Logout": "Uitloggen",
        "Log Out Other Browser Sessions": "Uitloggen bij alle sessies",
        "Longitude": "Lengtegraad",
        "Loop Detected": "Loop gedetecteerd",
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
        "M": "M",
        "Mailadres": "Mailadres",
        "Mail naar": "Mail to",
        "Mail to": "Mail naar",
        "Maintenance Mode": "Dienst niet beschikbaar",
        "Make appointment": "Afspraak maken",
        "Manage Account": "Accountbeheer",
        "Manage and log out your active sessions on other browsers and devices.": "Beheer je actieve sessies op andere browsers en andere apparaten.",
        "Manage API Tokens": "Beheer API-tokens",
        "Manage Role": "Beheer Rol",
        "Manage Team": "Beheer Team",
        "Manual": "Handmatig",
        "Margin": "Marge",
        "Margin per hour": "Marge per uur",
        "Margin per hour for {position}": "Marge per uur voor {position}",
        "Mark as completed": "Markeer als voltooid",
        "Mark as open": "Markeer als open",
        "Mark as paid": "Markeer als betaald",
        "Marketing": "Marketing",
        "Maximum credit": "Maximaal tegoed",
        "Maximum traveling costs": "Maximale reiskosten",
        "Mediation": "Bemiddeling",
        "Message": "Bericht",
        "Message*": "Bericht",
        "Message [{LOCALE}]": "Bericht [{LOCALE}]",
        "Meta description": "Meta beschrijving",
        "Method Not Allowed": "Methode niet toegestaan",
        "Microsoft Authenticator": "Microsoft Authenticator",
        "minutes": "minuten",
        "Misdirected Request": "Verkeerd geadresseerd verzoek",
        "Mitchel": "Mitchel",
        "Mo": "Ma",
        "Mo-fr from 8{00} - 18{00}": "Mo-vr van 8{00} - 18{00}",
        "Mobile number": "Mobiel nummer",
        "Moment": "Moment",
        "Month": "Maand",
        "Monthly": "Maandelijks",
        "Mood image 1": "Sfeerafbeelding 1",
        "Mood image 2": "Sfeerafbeelding 2",
        "Mood image 3": "Sfeerafbeelding 3",
        "Mood image 4": "Sfeerafbeelding 4",
        "Mood image 5": "Sfeerafbeelding 5",
        "Mood image 6": "Sfeerafbeelding 6",
        "Mood image 7": "Sfeerafbeelding 7",
        "More information": "Meer informatie",
        "Moved Permanently": "Definitief verplaatst",
        "Multi-Status": "Meerdere statussen",
        "Multiple Choices": "Meerkeuze",
        "Muted": "Gedempt",
        "N/A": "N/A",
        "Name": "Naam",
        "name": "naam",
        "Name: {employerName}": "Naam: {employerName}",
        "Name: {name}": "Naam: {name}",
        "Nationality": "Nationaliteit",
        "Nederland": "Netherlands",
        "Needs verification": "Verificatie nodig",
        "Netherlands": "Nederland",
        "Network Authentication Required": "Netwerkauthenticatie vereist",
        "Network Connect Timeout Error": "Fout bij time-out netwerkverbinding",
        "Network Read Timeout Error": "Time-outfout netwerk lezen",
        "New": "Nieuwe",
        "New {attribute}": "Nieuwe {attribute}",
        "New {model}": "Nieuw {model}",
        "New {name}": "Nieuw {name}",
        "New contact form from {$this->message->email}!": "Nieuw contactformulier van {$this->message->email}!",
        "New employer registered": "Nieuwe werkgever geregistreerd",
        "New freelancer company": "Nieuw freelance bedrijf",
        "New message from Top-Chefs": "Nieuw bericht van Top-Chefs",
        "New Password": "Nieuw wachtwoord",
        "New password: *": "Nieuw wachtwoord: *",
        "News": "Nieuws",
        "Newsletter": "Nieuwsbrief",
        "New unfinished registration": "Nieuwe onvoltooide registratie",
        "New years day": "Nieuwjaarsdag",
        "Next": "Volgende",
        "Next page": "Volgende pagina",
        "Nickname": "Bijnaam",
        "No": "Nee",
        "No, in the contracts and general terms & conditions is written that all assignments run through the platform. Our quality managers will visit the restaurants. In case of violation of the general terms & conditions, we will act immediately.": "Nee, de contracten en algemene voorwaarden zijn zo opgesteld dat de alle opdrachten via het platform lopen. Onze kwaliteitsmanagers zullen de locatie bezoeken. Bij overtreding van de algemene voorwaarden zullen wij daarop handelen.",
        "No, the time registration is done within the app. After the shift you can enter your hours. The employer can then immediately approve the hours you’ve worked.": "Nee, de urenregistratie gaat via de app. Na de shift kan jij jouw uren invullen. Vervolgens kan de opdrachtgever die gelijk akkoord geven.",
        "No, we only work with catering related companies.": "Nee, wij werken alleen met horeca gerelateerde bedrijven.",
        "No, we only work with freelance chefs.": "Nee, wij werken alleen met freelance chefs.",
        "No {model} found": "Geen {model} gevonden",
        "No available shifts": "Geen beschikbare shifts",
        "No budget known": "Geen budget bekend",
        "No Content": "Geen inhoud",
        "No files found": "Geen bestanden gevonden",
        "Non-Authoritative Information": "Niet-gemachtigde informatie",
        "None": "Geen",
        "No rating": "Geen beoordeling",
        "No surcharges exist": "Er bestaan geen toeslagen",
        "Not Acceptable": "Niet aanvaardbaar",
        "Not Extended": "Niet verlengd",
        "Not Found": "Niet gevonden",
        "Notification sent!": "Notificatie verzonden!",
        "Not Implemented": "Niet geïmplementeerd",
        "Not implemented yet": "Nog niet geinplementeerd",
        "Not Modified": "Niet gewijzigd",
        "Not verified": "Niet geverifieerd",
        "Number": "Nummer",
        "Number of {models}": "Aantal {models}",
        "of": "van",
        "Oh no": "Oh nee",
        "OK": "Oké",
        "Once a team is deleted, all of its resources and data will be permanently deleted. Before deleting this team, please download any data or information regarding this team that you wish to retain.": "Zodra een team is verwijderd, worden alle bronnen en gegevens permanent verwijderd. Download voordat je dit team verwijdert alle gegevens of informatie over dit team die je wilt behouden.",
        "Once your account is deleted, all of its resources and data will be permanently deleted. Before deleting your account, please download any data or information that you wish to retain.": "Als je account wordt verwijderd, worden alle gekoppelde bestanden en gegevens ook permanent verwijderd. Sla alsjeblieft alle data op die je wilt behouden, voordat je je account verwijderd.",
        "Only favorites": "Alleen favorieten",
        "Only favorites can respond to your shift, show to all freelancers? (This action is irreversible)": "Alleen favorieten kunnen reageren, aan alle freelancers laten zien? (Let op je kunt dit niet terugdraaien)",
        "On your phone or tablet with camera. We can advise you to use {google} or {microsoft}.": "Op je telefoon of tablet met camera. We kunnen je adviseren om {google} of {microsoft} te gebruiken.",
        "Oops": "Oeps",
        "Open": "Open",
        "Open {model}": "Open {model}",
        "Open blog": "Open blog",
        "Open budget": "Open begroting",
        "Open general terms": "Open algemene voorwaarden",
        "Open in a current window": "Open in een huidig ​​venster",
        "Open in a new window": "Open in een nieuw venster",
        "Open in a parent frame": "Open in een bovenliggend frame",
        "Opening hours support:": "Openingstijden support:",
        "Open in the topmost frame": "Open in het bovenste frame",
        "Open on the website": "Open op de website",
        "Open the authenticator app, add a new entry and scan the QR code": "Open de authenticator-app, voeg een nieuw item toe en scan de QR-code",
        "Operating employee!": "Bedienings-medewerker!",
        "Optional. If provided, the user is redirected to the shift when clicking on the notification.": "Optioneel. Voeg hieronder de shift id toe, de gebruiker gaat dan bij 't klikken op de notificatie direct naar de shift toe.",
        "Order": "Volgorde",
        "Order Column": "Volgorde",
        "or download our Top-Chefs app and place your first shift!": "of download onze Top-Chefs app en plaats je eerste shift!",
        "Origin Is Unreachable": "Herkomst is onbereikbaar",
        "Our account manager Mitchel is here for you!": "Onze accountmanager Mitchel staat voor je klaar!",
        "Our backoffice can be reached via chat if you are registered within our platform.": "Onze backoffice is te bereiken via chat als je bent geregistreerd binnen ons platform.",
        "Our clients": "Onze opdrachtgevers",
        "Our platform is designed to enable people like you to easily find and apply for shifts that align with your expertise and schedule. To fully utilize this feature, we kindly ask that you complete your registration through our app.": "Ons platform is ontworpen om professionals zoals jij eenvoudig shifts te laten vinden en zich hiervoor aan te melden die passen bij je expertise en schema. Om volledig gebruik te maken van deze functie, vragen we je vriendelijk om de registratie te voltooien via de app.",
        "Our support is there for you in case of questions and/or problems.": "Onze support staat voor vragen en/of problemen voor je klaar.",
        "Our support team is there for you, should you have any questions.": "Ons support-team staat voor je klaar, mocht je vragen hebben.",
        "Our Team": "Ons team",
        "Our Top Chefs love it!": "Onze Top-Chefs vinden het geweldig!",
        "Overview {models}": "Overzicht {models}",
        "Owner": "Eigenaar",
        "Owner of the selected company": "Eigenaar van het geselecteerde bedrijf",
        "Owner restaurant VADEVI & Sonah catering": "Eigenaar restaurant VADEVI & Sonah catering",
        "Page Expired": "Pagina niet meer geldig",
        "Page item": "Pagina item",
        "Pagination Navigation": "Paginanavigatie",
        "Paid": "Betaald",
        "Partial Content": "Gedeeltelijke inhoud",
        "Partnership": "vennootschap onder firma",
        "partnership": "vennootschap onder firma",
        "Passport": "Paspoort",
        "Password": "Wachtwoord",
        "password": "wachtwoord",
        "Paste or type the code we sent a code to your e-mail.": "Plak of typ de code die we naar je e-mail hebben gestuurd.",
        "Paste or type the code we sent a code to your phone.": "Plak of typ de code die we naar je telefoon hebben gestuurd.",
        "PAY ATTENTION! Messages should only be used for TOP-CHEFS services. Use for personal purposes, recruitment or harassment of TOP-CHEFS is not allowed.": "LET OP! Berichten dienen alleen te worden gebruikt voor diensten van TOP-CHEFS. Gebruik voor persoonlijke doeleinden, werving of het lastig vallen van TOP-CHEFS is niet toegestaan.",
        "Payload Too Large": "Aanvraag te groot",
        "Payment period": "Betalingsperiode",
        "Payment Required": "Betaling Vereist",
        "Pay no connection and usage costs": "Betaal geen aansluit- en gebruikskosten",
        "Pay no connection and usage fees.": "Betaal geen aansluit en gebruik kosten.",
        "Pending Team Invitations": "Openstaande Team uitnodigingen",
        "Pentecost": "Pinksteren",
        "Pentecost monday": "Pinkstermaandag",
        "per {model}": "per {model}",
        "Percentage": "Percentage",
        "Percentage on top of the regular wages": "Percentage bovenop het normale loon",
        "per hour": "per uur",
        "Permanently delete this team.": "Verwijder dit team definitief.",
        "Permanently delete your account.": "Verwijder je account permanent.",
        "Permanent Redirect": "Definitieve omleiding",
        "Permissions": "Rechten",
        "per Shift": "per shift",
        "Person is authorized to sign.": "Persoon is tekenbevoegd.",
        "Phone Number": "Telefoonnummer",
        "Phone number": "Telefoonnummer",
        "phone number": "telefoonnummer",
        "Phone number: {employerPhoneNumber}": "Telefoonnummer: {employerPhoneNumber}",
        "Photo": "Foto",
        "Photo identification matches the data.": "Foto identificatiebewijs komt overeen met de gegevens.",
        "Place name": "Plaatsnaam",
        "Planning": "Planning",
        "Platform cost": "Kosten platform",
        "Please add a location at the bottom under {companyName} and you're good to go!": "Voeg onderaan een locatie toe inclusief aan {companyName} en je bent klaar om te gaan!",
        "Please check with client expectations.": "Controleer de verwachtingen van de klant.",
        "Please click the button below to checkout your applicants and approve them for your shift.": "Klik op de onderstaande knop om uw aanmeldingen te beheren.",
        "Please click the button below to verify your email address.": "Klik op de knop hieronder om je e-mailadres te verifiëren.",
        "Please confirm access to your account by entering one of your emergency recovery codes.": "Bevestig de toegang tot je account door een van je noodherstelcodes in te voeren.",
        "Please confirm access to your account by entering the authentication code provided by your authenticator application.": "Bevestig de toegang tot je account door de authenticatiecode in te voeren die door je authenticator-applicatie is aangemaakt.",
        "Please copy your new API token. For your security, it won't be shown again.": "Kopieer je nieuwe API-token. Voor de veiligheid zal het niet opnieuw getoond worden.",
        "Please enter your password to confirm you would like to log out of your other browser sessions across all of your devices.": "Voer je wachtwoord in om te bevestigen dat je je wilt afmelden bij je andere browsersessies op al je apparaten.",
        "Please indicate if it's possible to park for free.": "Geef aan of het mogelijk is om gratis te parkeren.",
        "Please indicate if Top-Chefs should pick one of your favorites.": "Geef aan of Top-Chefs een van uw favorieten moet kiezen.",
        "Please indicate the budget.": "Geef het budget aan.",
        "Please note! Our rates may fluctuate seasonally, due to crowds or the number of queries. <br /> Fixed price agreements are negotiable! If so, please contact our support: {email}": "Let op! Onze tarieven kunnen seizoensgebonden fluctueren, vanwege drukte of het aantal vragen. <br /> Vaste prijsafspraken zijn bespreekbaar! Neem in dat geval contact op met support: {email}",
        "Please note, when email address is changed, the current user no longer has access to this company. In addition, a new user will be created with the provided fields (if the email address is not already in use).": "Let op, wanneer het e-mailadres wordt gewijzigd, heeft de huidige gebruiker geen toegang meer tot dit bedrijf. Bovendien wordt een nieuwe gebruiker aangemaakt met de opgegeven velden (als het e-mailadres nog niet in gebruik is).",
        "Please note, when email address is changed, the current user no longer has access to this company.n                    In addition, a new user will be created with the provided fields (if the email address is notn                    already in use).": "Let op: als het e-mailadres wordt gewijzigd, heeft de huidige gebruiker geen toegang meer tot dit bedrijf.n Daarnaast wordt er een nieuwe gebruiker aangemaakt met de opgegeven velden (als het e-mailadres nog nietn in gebruik is).",
        "Please pay the invoice amount as disclosed the payment terms. Our bank details are stated on the invoice. In case of a credit note, we would like to receive your bank details. You can send this to {email}. We will then transfer the credit amount to you as soon as possible. Please note that refunds are only possible via bank transfer.": "Gelieve het factuurbedrag te voldoen binnen de gestelde betaaltermijn. Onze bankgegevens staan vermeld op de factuur. In geval van een creditnota, ontvangen wij graag uw bankgegevens. U kunt deze versturen aan {email}. Wij zullen dan het credit bedrag zo spoedig mogelijk aan u overmaken. Houd er rekening mee dat de terugbetaling alleen mogelijk is via een bankoverschrijving.",
        "Please provide the email address of the person you would like to add to this team.": "Geef het e-mailadres op van de persoon die je aan dit team wilt toevoegen.",
        "Please retract your shift from the platform so somebody else can apply for it. Please do this at least 6 hours in advance.": "Annuleer je shift minimaal 6 uur voordat de shift begint. Op die manier stel jij iemand anders nog in de gelegenheid om jouw shift over te nemen.",
        "Please use the international notation, +31612345678": "Gebruik de internationale notatie, +31612345678",
        "Position": "Functie",
        "Positions": "Functies",
        "positions": "functies",
        "Positions: Hourly costs": "Functies: Uurtarieven",
        "Positions: Margin per hour": "Functies: Marge per uur",
        "Postal code": "Postcode",
        "Precondition Failed": "Niet voldaan aan de vooraf gestelde voorwaarde",
        "Precondition Required": "Voorwaarde nodig",
        "Premium Badge": "Premium badge",
        "Premium badge": "Premium badge",
        "Press 1 after calling": "Druk 1 na bellen",
        "Preview": "Voorvertoning",
        "Previous page": "Vorige pagina",
        "Prices": "Prijzen",
        "Pricing": "Prijzen",
        "Print": "Uitprinten",
        "Privacy declaration": "Privacy verklaring",
        "Privacy Policy": "Privacybeleid",
        "privacy policy": "privacybeleid",
        "Privacy policy: Top-chefs bv": "Privacy verklaring: Top-chefs bv",
        "Privacy Statement": "Privacyverklaring",
        "Private company": "naamloze vennootschap",
        "private company": "naamloze vennootschap",
        "Processing": "Verwerken",
        "Profile": "Profiel",
        "Profile {name}": "Profiel {name}",
        "Profile data": "Profiel gegevens",
        "Profile Image": "Profiel afbeelding",
        "Profile Information": "Profiel Informatie",
        "Profile information": "Profiel informatie",
        "Profile picture": "Profielfoto",
        "profile picture": "profielfoto",
        "Properties": "Eigenschappen",
        "Propose new hours": "Nieuwe uren voorstellen",
        "Proprietorship": "eenmanszaak",
        "proprietorship": "eenmanszaak",
        "Provide a reason for rejecting this company": "Geef een reden voor het weigeren van dit bedrijf",
        "Provision of your personal data to third parties": "Verstrekking van uw persoonsgegevens aan derden",
        "Proxy Authentication Required": "Authenticatie op de proxyserver verplicht",
        "Public": "Openbaar",
        "Publication date": "Publicatiedatum",
        "Published": "Gepubliceerd",
        "Published on: {moment}": "Gepubliceerd op: {moment}",
        "Quantity": "Hoeveelheid",
        "Quarterly": "Driemaandelijks",
        "Question": "Vraag",
        "Question (en)": "Vraag (en)",
        "Question (nl)": "Vraag (nl)",
        "Question or complaint": "Vraag of klacht",
        "Railgun Error": "Railgun foutmelding",
        "Range Not Satisfiable": "Bereik niet bevredigend",
        "Rate": "Tarief",
        "Rating": "Beoordeling",
        "rating": "beoordeling",
        "Read more": "Meer lezen",
        "Realised by": "Gerealiseerd door",
        "Reason": "Reden",
        "Reason for blocking": "Reden voor blokkeren",
        "Reason for decline": "Reden voor weigering",
        "Receive a clear invoice": "Ontvang een overzichtelijke factuur",
        "Received {model}": "Ontvangen {model}",
        "Recovery Code": "Herstelcode",
        "Regards": "Met vriendelijke groet",
        "Regenerate Recovery Codes": "Herstelcodes Opnieuw Genereren",
        "Register": "Registreren",
        "Register as a Chef": "Registreer je als Chef",
        "Register as a Top-Chef": "Registreer je als Top-Chef",
        "Register as a Top Operating Officer": "Registreer je als bedieningsmedewerker",
        "Register in no-time": "Snelle registratie",
        "Register within minutes.": "Registreer je binnen enkele minuten.",
        "Registration almost complete!": "Inschrijving bijna rond!",
        "Registration declined": "Inschrijving geweigerd",
        "Regular price": "Normale prijs",
        "Reinsert {model}": "Herplaats {model}",
        "Reject": "Weigeren",
        "Reject {model}": "Afkeuren {model}",
        "Rejected": "geweigerd",
        "rejected": "geweigerd",
        "Rejected Applications": "Afgewezen aanvragen",
        "Related blogs:": "Gerelateerde berichten:",
        "Remark freelancer": "Opmerking freelancer",
        "Remember Me": "Onthoud mij",
        "Remember me": "Onthouden",
        "Remove": "Verwijder",
        "Remove {attribute}, keep {second_attribute}": "Verwijder {attribute}, {second_attribute} laten staan",
        "Remove {attribute}, keep {second_attribute": "Verwijder {attribuut}, bewaar {tweede_attribuut",
        "Remove from favorites": "Verwijder vanuit favorieten",
        "Remove Photo": "Foto Verwijderen",
        "Remove Team Member": "Teamlid Verwijderen",
        "Remove {attribute, keep {second_attribute}": "Remove {attribute, keep {second_attribute}",
        "Repeat {attribute}": "Herhaal {attribute}",
        "Replace company": "Bedrijf vervangen",
        "Replaced": "Vervangen",
        "Replace identification back": "Identificatie terugplaatsen",
        "Replace identification front": "Identificatiefront vervangen",
        "Replace Profile Image": "Profielafbeelding vervangen",
        "Request Header Fields Too Large": "Headers van de aanvraag te lang",
        "Request Timeout": "Aanvraagtijd verstreken",
        "Required Action": "Verplichte actie",
        "Resend": "Opnieuw versturen",
        "Resend {attribute}": "Opnieuw verzenden {attribute}",
        "Resend in": "Opnieuw versturen in",
        "Resend Verification Email": "Verificatie-e-mail opnieuw versturen",
        "Reset": "Resetten",
        "Reset Content": "Inhoud opnieuw instellen",
        "Reset Password": "Wachtwoord herstellen",
        "Reset password": "Reset wachtwoord",
        "Reset Password Notification": "Wachtwoordherstel notificatie",
        "Residency permit": "Verblijfsvergunning",
        "Restore": "Herstel",
        "Restore {name}": "Herstel {name}",
        "results": "resultaten",
        "Retract": "Intrekken",
        "Retract {model}": "Intrekken {model}",
        "Retracted": "Ingetrokken",
        "retracted": "ingetrokken",
        "Retry With": "Opnieuw proberen met",
        "Revenue": "Omzet",
        "Review": "Review",
        "review": "review",
        "Reviews": "Reviews",
        "reviews": "beoordelingen",
        "Role": "Rol",
        "Royal day": "Koningsdag",
        "S": "S",
        "Sa": "Za",
        "Salary icon": "Salaris icon",
        "Save": "Opslaan",
        "Save & Close": "Opslaan en sluiten",
        "Save & Return": "Opslaan en teruggaan",
        "Save {name}": "Sla {name} op",
        "Saved.": "Opgeslagen.",
        "Save these codes on a secure place if you can’t get access with your Authenticator app.": "Bewaar deze codes op een veilige plaats als je geen toegang kunt krijgen met je Authenticator-app.",
        "Scanning not possible? Fill in this code in the app.": "Scannen niet mogelijk? Vul deze code in de app in.",
        "Scan the QR code": "Scan de QR-code",
        "Scheduled for: {moment}": "Gepland voor: {moment}",
        "Search": "Zoek",
        "Search {name}": "Zoek {name}",
        "Search by {attribute}": "Zoeken op {attribuut}",
        "sec": "sec",
        "Second day of Christmas": "Tweede Kerstdag",
        "See Other": "Zie andere",
        "Select": "Selecteer",
        "Select a {model}": "Selecteer een {model}",
        "Select a day": "Selecteer een dag",
        "Select All": "Selecteer Alle",
        "Select a month": "Selecteer een maand",
        "Select A New Photo": "Selecteer Een Nieuwe Foto",
        "Select an option": "Selecteer een optie",
        "Select Freelancer": "Selecteer Freelancer",
        "Select Location": "Selecteer Locatie",
        "Select Location/Freelancer": "Selecteer Locatie/Freelancer",
        "Send": "Versturen",
        "Send invoice now": "Stuur nu een factuur",
        "Send notification": "Stuur notificatie",
        "Send Password Reset Link": "Verstuur link voor wachtwoordherstel",
        "Send to notification center": "Verstuur naar notificatiecentrum",
        "Send us a message:": "Stuur ons een bericht",
        "Send via email": "Verstuur via e-mail",
        "Send via push notification": "Verstuur via push notificatie",
        "Send via sms": "Verstuur via sms",
        "Sent24u registration reminder": "Sent24u registratieherinnering",
        "SEO {attribute}": "SEO {attribuut}",
        "SEO title": "SEO titel",
        "Server Error": "Server fout",
        "Service Unavailable": "Website onbeschikbaar",
        "Session Has Expired": "Pagina verlopen",
        "Settings": "Instellingen",
        "Settings updated": "Instellingen bijgewerkt",
        "Set Two Factor Authentication": "Stel Tweestapsverificatie in",
        "Setup Key": "Setup Sleutel",
        "Share": "Artikel delen",
        "Shift": "Shift",
        "shift": "shift",
        "Shift agreement": "Opdrachtovereenkomst",
        "Shiftbase API key": "Shiftbase API sleutel",
        "Shiftbase Department": "Shiftbase afdeling",
        "Shift billing": "Shift facturering",
        "shift contract": "shift contract",
        "Shift hours": "Shift uren",
        "Shift hours approved": "Shift uren goedgekeurd",
        "Shift id": "Shift-id",
        "Shift is already accredited or amended": "Shift is al geaccrediteerd of aangepast",
        "Shift is not (yet) completed": "Shift is niet (nog) voltooid",
        "Shift kopiëren": "Shift kopiëren",
        "Shifts": "Shifts",
        "shifts": "shifts",
        "Shifts already placed will not be updated with new default value.": "Reeds geplaatste shifts zullen niet worden aangepast met deze waarde.",
        "Shifts available": "Shifts beschikbaar",
        "Show": "Toon",
        "Show (web)app": "Toon web(app)",
        "Show {model}": "Toon {model}",
        "Show {name}": "Toon {name}",
        "Show All": "Toon alles",
        "Showing": "Toont",
        "Show on {attribute}": "Toon op {attribute}",
        "Show Recovery Codes": "Toon herstelcodes",
        "Show to all freelancers": "Toon aan alle freelancers",
        "Sign this": "Onderteken dit",
        "Simple item": "Eenvoudig item",
        "Since it’s your first time logging in here, we’ve sent a code to your phone to verify your user profile.": "Omdat het de eerste keer is dat je hier inlogt, hebben we een code naar je telefoon gestuurd om je gebruikersprofiel te verifiëren.",
        "Sincerely,": "Met vriendelijke groet,",
        "Sitemap": "Sitemap",
        "Slug": "Slug",
        "sms": "sms",
        "Solution": "Oplossing",
        "Solve": "Oplossen",
        "Sous chef": "Sous chef",
        "Sous chef price": "Prijs souschef",
        "specialties": "specialiteiten",
        "Specialty": "Specialiteit",
        "Specific": "Specifiek",
        "SSL Handshake Failed": "SSL-handshake mislukt",
        "Staff": "Personeel",
        "staff shortage?": "personeelstekort?",
        "Start": "Start",
        "Start Date": "Startdatum",
        "Start date": "Startdatum",
        "Starting from": "Vanaf",
        "Start new chat": "Nieuwe chat starten",
        "Start time": "Starttijd",
        "start time": "starttijd",
        "Start week": "Start week",
        "Status": "Status",
        "statuses": "statussen",
        "statussen": "statussen",
        "Step {number}": "Stap {number}",
        "Store {attribute}": "Bewaar {attribute}",
        "Store changes": "Wijzigingen opslaan",
        "Store these recovery codes in a secure password manager. They can be used to recover access to your account if your two factor authentication device is lost.": "Bewaar deze herstelcodes in een beveiligde wachtwoordbeheerder. Ze kunnen worden gebruikt om de toegang tot je account te herstellen als je tweestapsverificatie verloren is gegaan.",
        "Street name": "Straatnaam",
        "Streetname 1": "Straatnaam 1",
        "Street Number": "Huisnummer",
        "Street Number Addition": "Huisnummer toevoeging",
        "Su": "Zo",
        "Subject": "Onderwerp",
        "subject": "onderwerp",
        "Submit": "Indienen",
        "Subscribe": "Aanmelden",
        "Subtotal": "Subtotaal",
        "Success": "Succes",
        "Support": "Ondersteuning",
        "Surcharges": "Toeslagen",
        "Switch": "Schakelaar",
        "Switching Protocols": "Protocolwissel",
        "Switch Teams": "Wissel Van Team",
        "Switch To Role": "Schakel over naar rol",
        "T": "D",
        "Tag": "Label",
        "Tags": "Labels",
        "Team": "Team",
        "Team Details": "Teamdetails",
        "Team Invitation": "Team uitnodiging",
        "Team Members": "Teamleden",
        "Team members": "Teamleden",
        "Team Name": "Teamnaam",
        "Team Owner": "Team Eigenaar",
        "Team Settings": "Team Instellingen",
        "Temporary Redirect": "Tijdelijke omleiding",
        "Terms and Conditions": "Algemene voorwaarden",
        "Terms of Service": "Algemene voorwaarden",
        "Th": "Do",
        "Thank you for reaching out!": "Bedankt voor het contact opnemen!",
        "Thank you for reaching out! We will get back to you shortly.": "Bedankt voor je bericht! We nemen zo snel mogelijk contact met je op.",
        "Thank you for registering!": "Bedankt voor je registratie!",
        "Thank you for registration! You can apply for shifts now right away!": "Bedankt voor je inschrijving! Je kunt nu direct voor shiften aanmelden!",
        "Thank you for your message! We've sent you a confirmation mail, we'll get in touch with you soon!": "Bedankt voor je bericht! We hebben je een bevestigingsmail gestuurd, we nemen zo spoedig mogelijk contact met je op!",
        "Thank you for your message! We\\'ve sent you a confirmation mail, we\\'ll get in touch with you soon!": "Bedankt voor uw bericht! We hebben je een bevestigingsmail gestuurd, we nemen snel contact met je op!",
        "That's not a problem at all.": "Dat is geen probleem!",
        "That's possible, Top-Chefs only fulfill the shifts. The chefs themselves can indicate whether they accept the shifts.": "Dat kan, wij vervullen alleen de shift. De chefs kunnen zelf aangeven of ze de opdrachten accepteren.",
        "That is possible. Please send us an email.": "Dat kan, daarvoor moet je wel eerst even contact met ons opnemen.",
        "The \"Premium Badge\" on our freelancing platform represents active participation, substantial experience, and steadfast reliability. It's awarded to freelancers who consistently take on and excellently complete shifts. The badge symbolizes trustworthiness in delivering high-quality work on time.": "De \"Premium Badge\" bij Top-Chefs staat voor actieve deelname, aanzienlijke ervaring en betrouwbaarheid. Het wordt toegekend aan freelancers die consequent shifts aannemen en uitstekend afronden. De badge symboliseert  betrouwbaarheid bij het leveren van hoogwaardig werk dat op tijd af is.",
        "The \"Premium Badge\" on our freelancing platform represents active participation, substantial experience, and steadfast reliability. It\\'s awarded to freelancers who consistently take on and excellently complete shifts. The badge symbolizes trustworthiness in delivering high-quality work on time.": "De \"Premium Badge\" bij Top-Chefs staat voor actieve deelname, aanzienlijke ervaring en betrouwbaarheid. Het wordt toegekend aan freelancers die consequent shifts aannemen en uitstekend afronden. De badge symboliseert  betrouwbaarheid bij het leveren van hoogwaardig werk dat op tijd af is.",
        "The {attribute} can not be zero.": "Het {attribute} kan niet null zijn",
        "The {attribute} field is required when {other} is {value}.": "Het {attribute} veld is verplicht wanneer {other} {value} is.",
        "The {attribute} is not valid.": "Dit {attribute} is ongeldig",
        "The {attribute} must be after {date}.": "Het {attribute} moet na {date} zijn.",
        "The {attribute} must be at least {length} characters.": "Het {attribute} moet minstens {length} karakters lang zijn.",
        "The {attribute} must be at least {length} characters and contain at least one number.": "Het {attribute} moet minimaal {length} tekens lang zijn en minimaal één cijfer bevatten.",
        "The {attribute} must be at least {length} characters and contain at least one special character.": "Het {attribute} moet minimaal {length} tekens lang zijn en minstens één speciaal karakter bevatten.",
        "The {attribute} must be at least {length} characters and contain at least one special character and one number.": "Het {attribute} moet minstens {length} karakters zijn en minstens één speciaal teken en één nummer bevatten.",
        "The {attribute} must be at least {length} characters and contain at least one uppercase character, one number, and one special character.": "Het {attribute} moet minstens {length} tekens lang zijn en minstens één hoofdletter, één cijfer en één speciaal teken bevatten.",
        "The {attribute} must be at least {length} characters and contain at least one uppercase character.": "Het {attribute} moet minimaal {length} tekens lang zijn en minimaal één hoofdletter bevatten.",
        "The {attribute} must be at least {length} characters and contain at least one uppercase character and one number.": "Het {attribute} moet minstens {length} tekens lang zijn en minstens één hoofdletter en één cijfer bevatten.",
        "The {attribute} must be at least {length} characters and contain at least one uppercase character and one special character.": "Het {attribute} moet minimaal {length} tekens lang zijn en minimaal één hoofdletter en één speciaal teken bevatten.",
        "The {attribute} must be a valid role.": "Het {attribute} moet een geldige rol zijn.",
        "The {attribute} must be verified.": "Dit {attribute} moet worden geverifieerd",
        "The {attribute} must contain at least one letter.": "Het {attribute} moet minimaal één letter bevatten.",
        "The {attribute} must contain at least one number.": "Het {attribute} moet minimaal één cijfer bevatten.",
        "The {attribute} must contain at least one symbol.": "Het {attribute} moet minimaal één symbool bevatten.",
        "The {attribute} must contain at least one uppercase and one lowercase letter.": "Het {attribute} moet minimaal één hoofdletter en één kleine letter bevatten.",
        "The AVG is the law to protect privacy and personal data. Under this law an organization working with personal data certain obligations. These privacy regulations are intended to inform you about your rights and our obligations under the AVG.": "De AVG is de wet ter bescherming van privacy en persoonsgegevens. Op grond van deze wet heeft een organisatie die met persoonsgegevens werkt bepaalde plichten. Dit privacyreglement is bedoeld om u te informeren over uw rechten en onze plichten die gelden op grond van de AVG.",
        "The duties of Top-chefs bv": "De plichten van Top-chefs bv",
        "The employees of Top-chefs bv are obliged to treat your personal data confidentially. handle confidentially. This means, for example, that top-chefs bv requires your express permission before providing your personal express permission. However, there are some exceptions to this rule.": "De medewerkers van Top-chefs bv hebben de verplichting vertrouwelijk met uw persoonsgegevens om te gaan. Dit houdt bijvoorbeeld in dat top-chefs bv voor verstrekking van uw persoonsgegevens uw uitdrukkelijke toestemming nodig heeft. Op deze regel bestaan echter enkele uitzonderingen.",
        "The following details of {location} have been changed:": "De volgende details van {location} zijn gewijzigd:",
        "The following people don’t have any valid ID’s anymore, please check.": "De volgende mensen hebben geen geldige ID's meer, controleer dit alstublieft.",
        "The freelancer {name} has multiple shifts on the same day ({date}).": "De freelancer {name} heeft meerdere diensten op dezelfde dag ({date}).",
        "The given {attribute} has appeared in a data leak. Please choose a different {attribute}.": "Het {attribute} is aangetroffen in een datalek. Geef een ander {attribute}.",
        "The given data was invalid.": "De gegeven data was ongeldig.",
        "The hourly rate varies per assignment, per shift and per client.": "Het uurtarief verschilt per opdracht, per shift en per opdrachtgever.",
        "The password is incorrect.": "Het wachtwoord is incorrect.",
        "The platform is free to use.": "Het platform is gratis te gebruiken.",
        "The provided code was invalid.": "De opgegeven code was ongeldig.",
        "The provided password does not match your current password.": "Het opgegeven wachtwoord komt niet overeen met je huidige wachtwoord.",
        "The provided password was incorrect.": "Het opgegeven wachtwoord is onjuist.",
        "The provided two factor authentication code was invalid.": "De opgegeven tweestapsverificatie was ongeldig.",
        "The provided two factor recovery code was invalid.": "De gegeven twee-staps herstelcode was ongeldig.",
        "There is a credit limit per employer. If that limit is exceeded, the invoices must be paid or the credit needs to be extended first to upload shifts again.": "Per opdrachtgever is er een limiet voor het uitzetten van het aantal shifts. Als die limiet is overschreden, moeten eerst de facturen worden betaald om het account weer vrij te geven. Daarna kan je als opdrachtgever weer shifts uploaden.",
        "The response is not a streamed response.": "De respons is niet gestreamd.",
        "The response is not a view.": "De respons is geen view.",
        "The right to correct, supplement or delete data if necessary": "Het recht op correctie, aanvulling of verwijdering van gegevens indien dat nodig mocht zijn",
        "The right to inspect and copy that data (to the extent that another person's privacy is is not violated thereby).": "Het recht op inzage en afschrift van die gegevens (voor zover de privacy van een ander daardoor niet wordt geschaad).",
        "The right to inspect and copy that data (to the extent that another person\\'s privacy is is not violated thereby).": "Het recht om deze gegevens in te zien en te kopiëren (voor zover de privacy van een andere persoon hierdoor niet wordt geschonden).",
        "The right to know whether and what personal data about you is being processed.": "Het recht om te weten of en welke persoonsgegevens van u verwerkt worden.",
        "The right to oppose the processing of your data in certain cases.": "Het recht om u in bepaalde gevallen tegen de verwerking van uw gegevens te verzetten.",
        "The right to request (partial) destruction of your data. This can only if the preservation of the data for another person is not of significant importance and the data on the basis of a statutory regulation does not must be kept.": "Het recht om (gedeeltelijke) vernietiging van uw gegevens te vragen. Hieraan kan alleen tegemoet worden gekomen als het bewaren van de gegevens voor een ander niet van aanmerkelijk belang is en de gegevens op grond van een wettelijke regeling niet bewaard moeten blijven.",
        "These people have been invited to your team and have been sent an invitation email. They may join the team by accepting the email invitation.": "Deze personen hebben een uitnodiging ontvangen om lid te worden van je team. Ze kunnen deelnemen door de uitnodiging te accepteren.",
        "The shift has been replaced.": "Shift is vervangen.",
        "The team's name and owner information.": "De naam van het team en de informatie over de eigenaar.",
        "The Top-Chefs platform makes finding chefs for your restaurant a lot easier. Read more here!": "Het platform van Top-Chefs maakt het vinden van chefs voor jouw restaurant een stuk gemakkelijker. Lees hier meer!",
        "The Top-Chefs platform makes finding wait staff for your restaurant a lot easier. Read more here!": "Het platform van Top-Chefs maakt het vinden van bedieningsmedewerkers voor jouw restaurant een stuk gemakkelijker. Lees hier meer!",
        "This {attribute} is invalid": "Dit {attribute} is ongeldig",
        "This combination of {attribute} is invalid": "Deze combinatie van {attribuut} is ongeldig",
        "This device": "Dit apparaat",
        "This form can only be used to register as Employer. To register as Chef, please download the App.": "Dit formulier kan alleen worden gebruikt om je als opdrachtgever te registreren. Om je als chef te registreren, download dan de app.",
        "This invoice is completed": "Deze factuur is voldaan",
        "This invoice is not yet completed": "Deze factuur is nog niet voldaan",
        "This invoice is not yet paid": "Deze factuur is nog niet betaald",
        "This invoice is paid": "Deze factuur is betaald",
        "This invoice may have been sent twice or never arrived before due to technical reasons. We would like to ask you to pay the invoices that have already expired immediately. We count on your understanding.": "Deze factuur kan dubbel zijn verzonden of nooit eerder zijn aangekomen door technische redenen. Wij willen u vragen de facturen die al reeds zijn verstreken direct te voldoen. Wij rekenen op uw begrip.",
        "This is a secure area of the application. Please confirm your password before continuing.": "Dit is een beveiligd gedeelte van de applicatie. Bevestig je wachtwoord voordat je doorgaat.",
        "This link expires in 24 hours": "Dit link verloopt in 24 uur",
        "This link expires in {number} minutes": "Deze link verloopt in {number} minuten",
        "This location already has a concept invoice": "Deze locatie heeft al een concept factuur",
        "This moment has a<strong>surcharge</strong>of {surcharge}%, which is paid to the freelancer": "Dit moment heeft een<strong>toeslag</strong> van {toeslag}%, die wordt betaald aan de freelancer",
        "This moment has a surcharge of {surcharge}%, which is paid to the freelancer": "Dit moment heeft een toeslag van {surcharge}%, die wordt betaald aan de freelancer",
        "This password does not match our records.": "Het wachtwoord is onbekend.",
        "This password reset link will expire in {count} minutes.": "Deze link om je wachtwoord te herstellen verloopt over {count} minuten.",
        "This shift is no longer open": "Dit shift is niet meer beschikbaar",
        "This user already belongs to the team.": "Deze gebruiker is al toegewezen aan het team.",
        "This user has already been invited to the team.": "Deze gebruiker is al uitgenodigd voor het team.",
        "Till": "Tot",
        "till": "tot",
        "Time": "Tijd",
        "Time surcharges": "Tijdtoeslagen",
        "Title": "Titel",
        "title": "titel",
        "To": "Tot",
        "to": "tot",
        "Today": "Vandaag",
        "Toevoegen": "Toevoegen",
        "To finish enabling two factor authentication, scan the following QR code using your phone's authenticator application or enter the setup key and provide the generated OTP code.": "Scan de volgende QR-code met de auhenticatie-applicatie van je telefoon of gebruik de setup-sleutel en voer de gegenereerde OTP-code in om het inschakelen van tweestapsverificatie af te ronden.",
        "Toggle navigation": "Schakel navigatie",
        "token": "token",
        "Token Name": "Token Naam",
        "Too Early": "Te vroeg",
        "Too Many Requests": "Te veel serververzoeken",
        "Top-Chef": "Top-Chef",
        "Top-Chefs": "Top-Chefs",
        "Top-Chefs, since 2017. Top-Chefs already had a lot of experience in the food industry. Looking for suitable freelance chefs was a common problem in this industry. But why not solve this problem? That’s where it all started. With Michelin and concept development experience, this was just a matter of time. Now, Top-Chefs connects restaurants and chefs every day. Our new app Top-Chefs fits the current market perfectly!": "Top-Chefs, sinds 2017. Top-Chefs heeft voor de oprichting al veel ervaring opgedaan. Het vinden van een geschikte freelance chef is altijd een probleem geweest. Dus waarom dit probleem niet verhelpen? “Het moet toch veel makkelijker kunnen?” Dat was inderdaad het geval! Met Michelin ervaring en conceptontwikkeling ervaring was het een kwestie van tijd. Vandaag de dag worden er elke dag restaurants en chefs moeiteloos gekoppeld met elkaar door Top-Chefs. Met de nieuwe app sluit het concept van Top-Chefs naadloos aan bij de huidige markt.",
        "Top-chefs bv": "Topchefs bv",
        "Top-chefs bv does not exchange your relevant data with third parties, exception is STRIXIn                        Solutions B.V. for maintaining the app and profile.": "Top-chefs bv wisselt uw relevante gegevens niet uit met derden, uitzondering is STRIXIn Solutions B.V. voor het onderhouden van de app en het profiel.",
        "Top-chefs bv does not exchange your relevant data with third parties, exception is STRIXI Solutions B.V. for maintaining the app and profile.": "Top-chefs bv wisselt uw relevant gegevens niet met derden, uitzondering is STRIXI Solutions B.V. voor het onderhoud van de app en profiel.",
        "Top-chefs bv does not exchange your relevant data with third parties, exception is STRIXI Solutions B.V.for maintaining the app and profile.": "Top-chefs bv wisselt uw relevante gegevens niet uit met derden, uitzondering is STRIXI Solutions B.V.voor het onderhouden van de app en het profiel.",
        "Top-chefs bv is according to the AVG the controller for the processing of personal data thatn                        takes place in the practice. The practice complies with the resulting obligations as follows:": "Top-chefs bv is volgens de AVG de verantwoordelijke voor de verwerking van persoonsgegevens dien plaatsvindt in de praktijk. De praktijk voldoet als volgt aan de daaruit voortvloeiende verplichtingen:",
        "Top-chefs bv is according to the AVG the controller for the processing of personal data that takes place in the practice. The practice complies with the resulting obligations as follows:": "Top-chefs bv is volgens de AVG de verantwoordelijke voor de verwerking van persoonsgegevens die in de praktijk plaatsvindt. Aan de plichten die daaruit voortkomen, voldoet de praktijk als volgt:",
        "Top-chefs bv is according to the AVG the controller for the processing of personal data that takes place in the practice.The practice complies with the resulting obligations as follows:": "Top-chefs bv is volgens de AVG de verantwoordelijke voor de verwerking van persoonsgegevens die in de praktijk plaatsvindt.De praktijk voldoet als volgt aan de daaruit voortvloeiende verplichtingen:",
        "Top-chefs bv may process various personal data about you. This is necessary to properly accountn                        properly and necessary for the financial settlement of any hours worked. hours. In addition,n                        processing may be necessary for, for example, combating serious problems so that we can supportn                        you.": "Top-chefs bv kan verschillende persoonsgegevens van u verwerken. Dit is nodig voor een goede verantwoordingn van gewerkte uren en nodig voor de financiële verrekeningn van gewerkte uren. uren. Daarnaast kan,n verwerking noodzakelijk zijn voor bijvoorbeeld het bestrijden van ernstige problemen, zodat we je kunnen ondersteunen.",
        "Top-chefs bv may process various personal data about you. This is necessary to properly account properly and necessary for the financial settlement of any hours worked. hours. In addition, processing may be necessary for, for example, combating serious problems so that we can support you.": "Bij Top-chefs bv kunnen diverse persoonsgegevens van u verwerkt worden. Dit is noodzakelijk om u account goed te kunnen beoordelen en nodig voor het financieel afhandelen van eventuele gemaakte uren. Daarnaast kan verwerking noodzakelijk zijn voor, bijvoorbeeld, de bestrijding van ernstig problemen zodat wij u kunnen ondersteunen.",
        "Top-Chefs has sent you the following message:": "Top-Chefs heeft je het volgende bericht gestuurd:",
        "Top-Chefs have the rights to remove you from the platform at any time.": "Top-Chefs heeft te allen tijde het recht om je te verwijderen van het platform.",
        "Top-Chefs is a top party to work with. We have been using their platform from the start and we like it very much. Easy user interface, the services are filled quickly and the quality of the freelancers is high. In short, a very nice platform!": "Top-Chefs is een top partij om mee te werken. Wij maken van het begin af aan gebruik van hun platform en dat bevalt prima. Gemakkelijke gebruiksinterface, de diensten zijn snel gevuld en kwaliteit van de freelancers is hoog. Kortom een heel fijn platform!",
        "Top-Chefs is the matchmaker between chefs and hospitality companies!": "Top-Chefs is de matchmaker tussen chefs en horecabedrijven!",
        "Top-Chefs is the matchmaker between chefs and hospitality companies. As a hospitality company, you decide who fills a shift and when! So if you want another Top-Chef, no problem, because you choose! Register easily via our website now!": "Top-Chefs is de matchmaker tussen chefs en horecabedrijven. Als horecabedrijf bepaal jij door wie en wanneer een shift wordt gevuld! Dus wil jij een andere Top-Chef? Geen probleem, want jij kiest! Registreer je nu gemakkelijk via onze website!",
        "Top-Chefs is the matchmaker between chefs and restaurants!": "Top-Chefs is de matchmaker tussen chefs en restaurants!",
        "Top-Chefs was founded in 2017 and has been supporting catering companies in finding new staff for many years. With our years of experience, we can quickly put the right freelance chef in the right place. We quickly link the requests from catering companies to our chefs, so that you as a catering entrepreneur save time and can fully focus on the quality of the restaurant! And the most beautiful thing? We take care of the administration and perform the online time registration. It doesn't get any easier!": "Top-Chefs is opgericht in 2017 en ondersteund al vele jaren horecabedrijven in het vinden van nieuw personeel. Met onze jarenlange ervaring kunnen wij razendsnel de juiste freelance chef op de juiste plaats zetten. Wij koppelen de aanvragen van horecabedrijven snel aan onze chefs, waardoor jij als horecaondernemer tijd bespaart en je volledig kan focussen op de kwaliteit van het restaurant! En het allermooiste? Wij zorgen voor de administratie en voeren online de urenregistratie uit. Makkelijker wordt het niet!",
        "Top-Chefs was founded in 2017 and has been supporting hospitality companies in finding hospitality staff for many years. We have an extensive network and years of experience, which allows us to quickly put the right freelancer in the right place. By quickly matching requests from hospitality businesses with our freelancers, you save time and can focus on the quality of your business.": "Top-Chefs is opgericht in 2017 en ondersteund al vele jaren horecabedrijven in het vinden van horecapersoneel. Wij hebben een uitgebreid netwerk en jarenlange ervaring, waardoor wij razendsnel de juiste freelancer op de juiste plek zetten. Door aanvragen van horecabedrijven snel te koppelen aan onze freelancers, bespaar je tijd en kan jij je concentreren op de kwaliteit van jouw horecabedrijf.",
        "Top-Chefs was founded in 2017 and has supported hospitality companies in finding staff for many years.<br /><br />Top-Chefs has an extensive network and years of experience. As a result, we can put the right chef in the right place at lightning speed. By matching your requests to our chefs quickly, you save time and can concentrate on the quality of your hotel and catering business. In addition, we also take care of the administration, taking the registration of hours away from you by registering online. You can check hours, indicate issues and approve them at a glance. By using our platform, you create a freelance team around your catering company so that you always have enough chefs. In the platform, we also offer an option to share reviews and ratings and chat directly with Top Chefs. This ensures that you create motivated and hard-working freelancers in your team.": "Top-Chefs is opgericht in 2017 en ondersteunt al jaren horecabedrijven bij het vinden van personeel.<br /><br />Top-Chefs heeft een uitgebreid netwerk en jarenlange ervaring. Hierdoor kunnen wij razendsnel de juiste chef op de juiste plek zetten. Door uw aanvragen snel aan onze koks te koppelen, bespaart u tijd en kunt u zich concentreren op de kwaliteit van uw horecabedrijf. Daarnaast nemen wij ook de administratie uit handen, door de urenregistratie online uit handen te nemen. U kunt in één oogopslag uren controleren, issues aangeven en goedkeuren. Door gebruik te maken van ons platform creëer je een freelance team rondom je cateringbedrijf zodat je altijd voldoende koks hebt. In het platform bieden we ook een optie om beoordelingen en ratings te delen en direct te chatten met Topkoks. Dit zorgt ervoor dat je gemotiveerde en hardwerkende freelancers in je team creëert.",
        "Top-Chefs was founded in 2017 and has supported hospitality companies in finding staff for many years.nnTop-Chefs has an extensive network and years of experience. As a result, we can put the right chef in the right place at lightning speed. By matching your requests to our chefs quickly, you save time and can concentrate on the quality of your hotel and catering business. In addition, we also take care of the administration, taking the registration of hours away from you by registering online. You can check hours, indicate issues and approve them at a glance. By using our platform, you create a freelance team around your catering company so that you always have enough chefs. In the platform, we also offer an option to share reviews and ratings and chat directly with Top Chefs. This ensures that you create motivated and hard-working freelancers in your team.": "Top-Chefs is opgericht in 2017 en ondersteund al vele jaren horecabedrijven in het vinden van personeel.nnTop-Chefs heeft een uitgebreid netwerk en jarenlange ervaring. Daardoor kunnen wij razendsnel de juiste chef op de juiste plaats zetten. Door jouw aanvragen snel te koppelen aan onze chefs bespaar je tijd en kan jij je concentreren op de kwaliteit van jouw horecabedrijf. Daarnaast zorgen wij ook voor de administratie, het registeren van uren nemen wij bij jou weg door het online te registreren. Je kan in één oogopslag de uren controleren, issues aangeven en goedkeuren. Door gebruik te maken van ons platform creëer je een freelance team om jouw horecabedrijf heen zodat je altijd genoeg chefs hebt. In het platform bieden wij ook een optie aan om reviews en ratings uit te delen en direct te chatten met de Top-Chefs. Hierdoor zorg je ervoor dat je gemotiveerde en hardwerkende freelancers creëert in jouw team.",
        "To revoke the shift send an email to {email}": "Om de dienst in te trekken, stuur een e-mail naar {email}",
        "Total": "Totaal",
        "Total {model}": "Totaal {model}",
        "Total Accredited Hours": "Totaal geaccrediteerde uren",
        "Total Future Hours": "Totaal toekomstige uren",
        "Total hours worked: {duration} h": "Totaal aantal gewerkte uren: {duration} u",
        "Total Requested Hours": "Totaal uitgevraagde uren",
        "To use Top Chefs Two Factor Authentication is required as extra security, you can set this now": "Om Topchefs te gebruiken is Two Factor Authentication nodig als extra beveiliging, u kunt dit nu instellen",
        "Translate": "Vertalen",
        "Translate It": "Vertaal het",
        "Travel costs: € {cost}": "Reiskosten: € {cost}",
        "Travel Reimbursement": "Reiskostenvergoeding",
        "Travel reimbursement": "Reiskostenvergoeding",
        "Travel reimbursement budget": "Budget reiskostenvergoeding",
        "Tu": "Di",
        "Two Factor Authentication": "Tweestapsverificatie",
        "Two factor authentication": "Tweestapsverificatie",
        "Two Factor Authentication - Backup codes": "Tweestapsverificatie - Backup codes",
        "Two factor authentication is now enabled. Scan the following QR code using your phone's authenticator application or enter the setup key.": "Tweestapsverificatie is nu ingeschakeld. Scan de volgende QR-code met de authenticatie-applicatie van je telefoon of gebruik de setup-sleutel.",
        "Type": "Type",
        "Type: {type}": "Type: {type}",
        "types": "types",
        "Unassign": "Intrekken",
        "Unauthorized": "Onbevoegd",
        "Unavailable For Legal Reasons": "Toegang geweigerd om juridische redenen",
        "Unblock": "Deblokkeren",
        "Unfortunately, the page you are looking for does not exist (anymore). Looking for information that you can't find on our website? Then contact us!": "De pagina die je zoekt bestaat helaas niet (meer). Op zoek naar informatie die je niet kunt vinden op onze website? Neem dan contact met ons op!",
        "Unfortunately, the page you are looking for does not exist (anymore). Looking for information that you can\\'t find on our website? Then contact us!": "Helaas, de pagina die u zoekt bestaat niet (meer). Zoekt u informatie die u niet op onze website kunt vinden? Neem dan contact met ons op!",
        "Unit Price": "Eenheid prijs",
        "Unit price": "Eenheid prijs",
        "unit price": "eenheid prijs",
        "Unknown": "Onbekend",
        "Unknown Error": "Onbekende foutmelding",
        "Unpack": "Uitpakken",
        "Unprocessable Entity": "Aanvraag kan niet worden verwerkt",
        "Unsubscribe": "Uitschrijven",
        "Unsupported Media Type": "Media-type niet ondersteund",
        "Up": "Omhoog",
        "Update": "Bewerk",
        "Update {name}": "Bijwerken {name}",
        "Updated": "Bijgewerkt",
        "Update Password": "Wachtwoord Aanpassen",
        "Update password": "Wachtwoord aanpassen",
        "Update your account's profile information and email address.": "Pas je profiel informatie en e-mailadres aan.",
        "Upgrade Required": "Upgrade nodig",
        "Uploaded back identification": "Geüploade rugidentificatie",
        "Uploaded front identification": "Geüploade front identificatie",
        "Uploaded identification": "Geüploade identificatie",
        "Uploaded selfie": "Geüploade selfie",
        "URI Too Long": "Aanvraag-URL te lang",
        "Use an authentication code": "Gebruik een autorisatiecode",
        "Use a recovery code": "Gebruik een herstelcode",
        "Use Proxy": "Gebruik Proxy",
        "User": "Gebruiker",
        "User cannot be removed from own team": "Gebruiker kan niet uit eigen team worden verwijderd",
        "User is already in team": "Gebruiker zit al in team",
        "User Profile": "Gebruikersprofiel",
        "user types": "gebruikerstypes",
        "User was added to team": "Gebruiker is toegevoegd aan team",
        "User was removed from team": "Gebruiker is verwijderd van het team",
        "Uses the Top-Chefs app in: {lang}": "Gebruikt de Top-Chefs app in: {lang}",
        "Using a non-Dutch number? Please also add the country code. (example +3234567890)": "Gebruik je een niet-Nederlands nummer? Voeg dan ook de landcode toe. (bijvoorbeeld +3234567890)",
        "UX Designer": "UX Designer",
        "Validity: {date}": "Geldigheid: {datum}",
        "Variant Also Negotiates": "Variant onderhandelt ook",
        "VAT number": "BTW nummer",
        "VAT number (optional)": "BTW nummer (optioneel)",
        "Vat Rate": "BTW-tarief",
        "Vat rate": "BTW-tarief",
        "Verification required": "Verificatie vereist",
        "Verified": "Geverifieerd",
        "Verified by Top-Chefs.": "Geverifieerd door Top-Chefs.",
        "Verified identity": "Geverifieerde identiteit",
        "Verify": "Verifiëren",
        "Verify {attribute}": "Verifieer {attribute}",
        "Verify {model}": "Verifieer {model}",
        "Verify Email Address": "Verifieer e-mailadres",
        "Verify with your phone": "Verifieer met je telefoon",
        "Verify Your Email Address": "Verifieer je e-mailadres",
        "Very bad": "Zeer slecht",
        "Very Good": "Zeer goed",
        "View": "Bekijk",
        "View {name}": "Bekijk {name}",
        "View all blogposts": "Bekijk alle blogberichten",
        "View chefs' skills and experiences.": "Bekijk de vaardigheden en ervaringen van de chefs.",
        "View chefs skills and experiences.": "Bekijk de vaardigheden en ervaringen van de chefs.",
        "View Freelancer": "Bekijk Freelancer",
        "View profile": "Bekijk profiel",
        "View the shifts and sign up": "Bekijk de shifts en meld je aan",
        "W": "W",
        "Wage": "Loon",
        "Waiter!": "Bedienings-medewerker!",
        "Waitstaff": "Bedieningsmedewerker",
        "Waitstaff price": "Prijs obers",
        "wanted": "gezocht",
        "Want guidance for finding freelance chefs to suit your hospitality business? We are here for you every day to help your hospitality business. From answering questions about our platform, to personal advice.": "Wil je begeleiding voor het zoeken naar freelance chefs, passend bij jouw horecabedrijf? Wij staan elke dag voor je klaar om jouw horecabedrijf verder te helpen. Van het beantwoorden van vragen over ons platform, tot persoonlijk advies.",
        "Was unable to find an address with this postcode and street number": "Kon geen adres vinden met deze postcode en straatnummer",
        "We": "Wo",
        "We already provide staff to these clients:": "Wij leveren al personeel aan deze opdrachtgevers:",
        "We are happy to help your {brand}!": "Wij helpen uw {brand} graag!",
        "We are thrilled to welcome you onboard! However, we have noticed that your registration process is not yet complete.": "Bedankt voor je aanmelding! We hebben echter gemerkt dat het registratieproces nog niet voltooid is.",
        "Web Server is Down": "Webserver is onbereikbaar",
        "Website": "Website",
        "We declined your registration": "We hebben uw registratie geweigerd",
        "We declined your registration because of the following reason:": "Wij hebben je registratie afgewezen om de volgende reden:",
        "We do all the administration": "Wij doen alle administratie",
        "We do all the administration.": "Wij doen alle administratie.",
        "Week": "Week",
        "Weekly": "Wekelijks",
        "Week nr.": "Week nr.",
        "weeks": "weken",
        "We have been working with Top-Chefs for some time now and for now we are very satisfied! Communication is good and experienced forces are placed.": "Wij werken nu enige tijd met Top-Chefs en zijn vooralsnog erg tevreden! De communicatie is goed en er worden ervaren krachten geplaatst.",
        "Welcome to Top-Chefs!": "Welkom bij Topchefs!",
        "We look forward to being part of your professional journey!": "We kijken ernaar uit om je te helpen met je professionele carrière!",
        "We match freelance cooks with catering companies.": "Wij matchen freelance koks met horecabedrijven.",
        "We pay the invoice within 7 and 14 days. Invoices are paid once a week.": "Wij streven ernaar om de factuur binnen 7 en 14 dagen te betalen. De facturen worden 1x per week betaald.",
        "We try to respond as quickly as possible!": "Wij proberen zo snel mogelijk te reageren!",
        "We were unable to find a registered user with this email address.": "Er is geen gebruiker met dit e-mailadres.",
        "We’ll do all the administration": "Wij doen alle administratie",
        "We’re sorry for the inconvenience. We’ve re-opened the shift so you can receive new applicants or accept someone else.": "Excuses voor het ongemak, we hebben de shift weer opengesteld zodat andere kandidaten kunnen aanmelden, of zodat je iemand anders kunt accepteren.",
        "What are the advantages of our platform as a hospitality company?": "Wat zijn de voordelen van ons platform als horecabedrijf?",
        "What are the benefits of our platform for you as a freelancer?": "Wat zijn de voordelen van ons platform voor jou als freelancer?",
        "What are the minimum requirements?": "Wat zijn de minimale vereisten?",
        "What do I have to bring to my shift?": "Wat moet ik zelf meenemen naar mijn shift?",
        "What do you mean {brand} With our platform, you'll never have to deal with this again!": "Hoe bedoel je {brand} Met ons platform heb je hier geen last meer van!",
        "What is the hourly rate?": "Wat is het uurtarief?",
        "What kind of employers are there?": "Wat voor opdrachtgevers zijn er?",
        "WhatsApp daily from {from} to {to}": "WhatsApp dagelijks van {from} tot {to}",
        "WhatsApp to": "WhatsApp naar",
        "What to do if a freelancer doesn't show up?": "Wat te doen als een freelancer niet komt opdagen?",
        "When two factor authentication is enabled, you will be prompted for a secure, random token during authentication. You may retrieve this token from your phone's Google Authenticator application.": "Als tweestapsverificatie is ingeschakeld, word je tijdens de authenticatie om een veilige, willekeurige token gevraagd. Je kunt dit token ophalen uit de Google Authenticator-applicatie op je telefoon.",
        "Whoops!": "Oeps!",
        "Whoops! Something went wrong.": "Oeps! Er is iets misgelopen.",
        "Will I receive a timesheet from you?": "Krijg ik van jullie een urenbriefje?",
        "Within a few clicks, you have placed an enquiry and/or accepted a shift.": "Binnen enkele klikken heb je een uitvraag geplaatst en/of shift geaccepteerd.",
        "With Top-Chefs, you'll have a freelance chef filling your shift within an hour! And with your own preferred pool, you decide by whom and when. Register now completely free and place your first shift!": "Met Top-Chefs heb je binnen een uur een freelance chef die jouw shift vult! En met jouw eigen voorkeurspool bepaal je zelf door wie en wanneer. Registreer je nu volledig gratis en plaats je eerste shift!",
        "Worked hours": "Gewerkte uren",
        "Work experience": "Werkervaring",
        "work experience": "werkervaring",
        "work experiences": "werkervaringen",
        "Works with Top-Chefs in: {lang}": "Werkt met Top-Chefs in: {lang}",
        "Would you like to carry out freelance assignments in": "Zie jij het wel zitten om freelance opdrachten uit te voeren in",
        "Would you like to have an appointment?": "Heb je graag een afspraak?",
        "Would you rather still have contact with our account manager?": "Heb je liever nog contact met onze accountmanager?",
        "Write reviews and give stars": "Schrijf reviews en deel sterren uit",
        "Write reviews and share stars, this is possible for chefs and employers.": "Schrijf reviews en deel sterren uit, dit is mogelijk voor chefs en werkgevers.",
        "Yes": "Ja",
        "Yes, both the chef and the client can leave a review.": "Jazeker, zowel de kok als de opdrachtgever kunnen een review achterlaten.",
        "Yes, i would like to receive newsletters.": "Ja, ik wil graag nieuwsbrieven ontvangen.",
        "Yes, that’s possible. When it’s too busy, you can leave a voicemail. Our customer service will call you back as soon as possible.": "Dat kan zeker. Mocht het te druk zijn, kan je de voicemail inspreken. Er wordt dan gelijk een mail gestuurd naar onze klantenservice en die gaat jou dan z.s.m. terugbellen.",
        "Yes, the client can upload a long-term assignment over several shifts for which you can apply.": "Ja, die kan de opdrachtgever uitzetten en daar kan jij dan uiteraard op reageren.",
        "Yes, when registering we ask for a Chamber of Commerce (KvK) registration number. We verify the existence of the company automatically.": "Ja, bij het inschrijven vragen we om een KvK nummer en paspoort. Die worden automatisch gecheckt op echtheid door onze koppeling met de Kamer van Koophandel.",
        "Yes, with the Chamber of Commerce check, the kilometers per employer are automatically calculated.": "Ja, de kilometers worden per opdrachtgever automatisch berekend.",
        "Yes, you can respond to shifts or accept shifts.": "Ja, je kan zelf reageren op opdrachten of opdrachten aanvaarden.",
        "You accept our": "Je aanvaardt ons",
        "You are expected to have your clothes and knives with you. In addition, you look neat and tidy. When there’s clothing available on location, we will mention this.": "Er wordt verwacht dat je zelf jouw kleding en messen in orde hebt. Daarnaast zie je er netjes en verzorgd uit. Mocht er kleding beschikbaar zijn op locatie, zullen wij dat vermelden bij de opdracht.",
        "You are not allowed to delete your account.": "Je mag je account niet verwijderen.",
        "You are receiving this email because we received a password reset request for your account.": "Je ontvangt deze e-mail omdat we een wachtwoordherstel verzoek hebben ontvangen voor je account.",
        "You can immediately send an email to Top-Chefs. This issue is then added to the chef's file. You can also block the chef right away.": "Gelijk een mail sturen naar Top-Chefs. Dit wordt vervolgens toegevoegd aan het dossier van de chef. Ook kan je de kok gelijk blokkeren.",
        "You cannot create shifts for a restricted location. If you recently created a location, please wait for it to be approved.": "Je kan geen shifts aanmaken voor een beperkte locatie. Als je recent een locatie hebt aangemaakt, wacht dan even tot deze is goedgekeurd.",
        "You choose where and when": "Jij kiest waar en wanneer",
        "You choose where and when.": "Jij kiest waar en wanneer.",
        "You consent when creating your profile of the fact that personal data about you will ben                        processed will be processed.": "Bij het aanmaken van je profiel geef je toestemming dat persoonlijke gegevens over jou worden verwerkt.",
        "You consent when creating your profile of the fact that personal data about you will be processed will be processed.": "U geeft akkoord bij het maken van uw profiel van het feit dat er persoonsgegevens van u verwerkt worden.",
        "You have been invited to join the {team} team!": "Je bent uitgenodigd om lid te worden van team {team}!",
        "You have enabled two factor authentication.": "Je hebt tweestapsverificatie ingeschakeld.",
        "You have not enabled two factor authentication.": "Je hebt tweestapsverificatie niet ingeschakeld.",
        "You have the following rights:": "U heeft de volgende rechten:",
        "You have to work at least 6 hours per day.": "Op een dag hanteren wij minimaal 6 uur.",
        "You may accept this invitation by clicking the button below:": "Je kunt de uitnodiging accepteren door op de volgende knop te klikken:",
        "You may delete any of your existing tokens if they are no longer needed.": "Je kunt al je bestaande tokens verwijderen als ze niet langer nodig zijn.",
        "You may not delete your personal team.": "Je mag je persoonlijke team niet verwijderen.",
        "You may not leave a team that you created.": "Je kan het team dat je aangemaakt hebt niet verlaten.",
        "You must accept the general terms, and privacy policy to register.": "Je moet de algemene voorwaarden en privacy verklaring accepteren om te registreren.",
        "Your account for {siteName}": "Je account voor {siteName}",
        "Your account is incomplete or blocked. Please contact Top-Chefs support at 085 760 34 18.": "Uw account is niet volledig of geblokkeerd. Neem contact op met support van Top-Chefs via 085 760 34 18.",
        "Your application for {location} was rejected. Better luck next time!": "Je aanvraag voor {location} is afgewezen. Volgende keer beter!",
        "Your disputed shift has been reviewed": "Je betwiste dienst is beoordeeld",
        "Your disputed shift has been reviewed by Top-Chefs support and the shift hours are now definitive.": "Je betwiste dienst is beoordeeld door Top-Chefs support en de shift uren zijn nu definitief.",
        "You received this email because your account is ready for {siteName}": "Je ontvangt deze e-mail omdat je account klaar is voor {siteName}",
        "Your email address is unverified.": "Je e-mailadres is niet geverifieerd.",
        "Your experience on this site is enhanced by the use of cookies.": "Uw ervaring op deze site wordt verbeterd door het gebruik van cookies.",
        "Your hours have been approved. For shifts worked **and approved**, you can send us a collective invoice per week to [{backoffice_email}](mailto:{backoffice_email}).": "Je uren zijn goedgekeurd. Voor gewerkte **en goedgekeurde** shifts kan je ons per week een verzamelfactuur sturen naar [{backoffice_email}](mailto:{backoffice_email}).",
        "Your hours have been approved. For shifts worked and approved, you can send us a collective invoice per week to {backoffice_email}.": "Je uren zijn goedgekeurd. Voor gewerkte en goedgekeurde shifts kan je ons per week een verzamelfactuur sturen naar {backoffice_email}.",
        "Your hours have been approved for your shift on {start}": "Je uren zijn goedgekeurd voor je shift op {start}",
        "Your hours have been approved for your shift on {start}. For shifts worked **and approved**, you can send us a collective invoice per week to [{backoffice_email}](mailto:{backoffice_email}).": "Je uren zijn goedgekeurd voor je shift op {start}. Voor gewerkte **en goedgekeurde** shifts kan je ons per week een verzamelfactuur sturen naar [{backoffice_email}](mailto:{backoffice_email}).",
        "Your information is collected for specific purposes:": "Uw gegevens worden voor specifieke doeleinden verzameld:",
        "Your personal data and your privacy at Top-chefs bv": "Uw persoonsgegevens en uw privacy bij Top-chefs bv",
        "Your personal data is properly secured against unauthorized access.": "Uw persoonsgegevens worden goed beveiligd tegen onbevoegde toegang.",
        "Your personal data will not be kept longer than necessary. After unsubscribing with us we will keep your data for a maximum of 5 years.": "Uw persoonsgegevens worden niet langer bewaard dan noodzakelijk. Na uitschrijven bij ons zullen wij maximaal 5 jaar uw gegevens bewaren.",
        "Your personal data will not be kept longer than necessary. After unsubscribing with us we willn                        keep your data for a maximum of 5 years.": "Je persoonlijke gegevens worden niet langer bewaard dan noodzakelijk. Na afmelding bewaren we je gegevens maximaal 5 jaar.",
        "Your rights as a data subject:": "Uw rechten als betrokkene:",
        "Your shift hours have been corrected by {locationName}": "De uren van je shift zijn aangepast door {locationName}",
        "Your Top Chefs Credit Note": "Je Top Chefs creditnota",
        "Your Top Chefs Invoice": "Je Top Chefs factuur",
        "You will be send a framework agreement by email upon your first shift": "Bij je eerste dienst ontvang je per e-mail een raamovereenkomst.",
        "You will receive this email because your account is ready for {siteName}": "Je ontvangt deze e-mail omdat je account klaar is voor {siteName}",
        "You’re invited for a shift you liked, apply now!": "Je bent uitgenodigd voor een shift die je hebt geliked. Meld je nu aan!",
        "Zip code": "Postcode",
        "“Self-employed cook wanted!” Do you also have many vacancies in your restaurant that are not filled? And are you tired of looking for new chefs? Then you've come to the right place at Top-Chefs. We are the matchmaker between chefs and catering companies. Register easily via our website and start your search for a new freelance cook right away!": "“Zelfstandig kok gezocht!” Heb jij ook vele vacatures in jouw restaurant openstaan welke maar niet gevuld worden? En ben je het zoeken naar nieuwe chefs zat? Dan ben je bij Top-Chefs aan het juist adres. Wij zijn dé matchmaker tussen chefs en horecabedrijven. Registreer je gemakkelijk via onze website en start direct je zoektocht naar een nieuwe freelance kok!",
        "€50": "€50",
        "€100": "€100",
        "👉 Register for free and easy via": "👉 Registreer je gratis en gemakkelijk via",
        "👉 Send a message via": "👉 Stuur een bericht via",
        "👋 Are you looking for a Top Chef?": "👋 Ben je op zoek naar een Top Chef?",
        "👋 Can we help you?": "👋 Kunnen we je helpen?",
        "routes": {
            "frequently-asked-questions": "veelgestelde-vragen",
            "shifts": "opdrachten",
            "city": "stad",
            "employers": "werkgevers",
            "positions": "functies",
            "blog": "blog",
            "employer-landing": "werkgevers-landing",
            "hire-a-freelance-cook": "freelance-kok-inhuren",
            "hospitality": "bediening",
            "about-us": "over-ons",
            "general-terms": "algemene-voorwaarden",
            "privacy-policy": "privacy-policy"
        },
        "pagination": {
            "next": "Volgende &raquo;",
            "previous": "&laquo; Vorige"
        },
        "auth": {
            "failed": "Deze combinatie van e-mailadres en wachtwoord is niet geldig.",
            "password": "Wachtwoord is onjuist.",
            "throttle": "Te veel mislukte aanmeldpogingen. Probeer het nog eens over {seconds} seconden."
        },
        "http-statuses": {
            "0": "Onbekende foutmelding",
            "100": "Doorgaan",
            "101": "Protocolwissel",
            "102": "Verwerken",
            "200": "Oké",
            "201": "Aangemaakt",
            "202": "Aanvaard",
            "203": "Niet-gemachtigde informatie",
            "204": "Geen inhoud",
            "205": "Inhoud opnieuw instellen",
            "206": "Gedeeltelijke inhoud",
            "207": "Meerdere statussen",
            "208": "Al gemeld",
            "226": "Ik ben gebruikt",
            "300": "Meerkeuze",
            "301": "Definitief verplaatst",
            "302": "Gevonden",
            "303": "Zie andere",
            "304": "Niet gewijzigd",
            "305": "Gebruik Proxy",
            "307": "Tijdelijke omleiding",
            "308": "Definitieve omleiding",
            "400": "Foute aanvraag",
            "401": "Niet geautoriseerd",
            "402": "Betaalde toegang",
            "403": "Verboden toegang",
            "404": "Niet gevonden",
            "405": "Methode niet toegestaan",
            "406": "Niet aanvaardbaar",
            "407": "Authenticatie op de proxyserver verplicht",
            "408": "Aanvraagtijd verstreken",
            "409": "Conflict",
            "410": "Verdwenen",
            "411": "Lengte vereist",
            "412": "Niet voldaan aan de vooraf gestelde voorwaarde",
            "413": "Aanvraag te groot",
            "414": "Aanvraag-URL te lang",
            "415": "Media-type niet ondersteund",
            "416": "Bereik niet bevredigend",
            "417": "Niet voldaan aan verwachting",
            "418": "Ik ben een theepot",
            "419": "Pagina verlopen",
            "421": "Verkeerd geadresseerd verzoek",
            "422": "Aanvraag kan niet worden verwerkt",
            "423": "Afgesloten",
            "424": "Gefaalde afhankelijkheid",
            "425": "Te vroeg",
            "426": "Upgrade nodig",
            "428": "Voorwaarde nodig",
            "429": "Te veel requests",
            "431": "Headers van de aanvraag te lang",
            "444": "Verbinding gesloten zonder reactie",
            "449": "Opnieuw proberen met",
            "451": "Toegang geweigerd om juridische redenen",
            "499": "Klant Gesloten Verzoek",
            "500": "Interne serverfout",
            "501": "Niet geïmplementeerd",
            "502": "Slechte poort",
            "503": "Dienst niet beschikbaar",
            "504": "Gateway-time-out",
            "505": "HTTP-versie wordt niet ondersteund",
            "506": "Variant onderhandelt ook",
            "507": "Onvoldoende opslag",
            "508": "Loop gedetecteerd",
            "509": "Bandbreedte overschreden",
            "510": "Niet verlengd",
            "511": "Netwerkauthenticatie vereist",
            "520": "Onbekende foutmelding",
            "521": "Webserver is onbereikbaar",
            "522": "Connectie duurt te lang",
            "523": "Herkomst is onbereikbaar",
            "524": "Time-out opgetreden",
            "525": "SSL-handshake mislukt",
            "526": "Ongeldig SSL-certificaat",
            "527": "Railgun foutmelding",
            "598": "Time-outfout netwerk lezen",
            "599": "Fout bij time-out netwerkverbinding",
            "unknownError": "Onbekende foutmelding"
        },
        "passwords": {
            "reset": "Het wachtwoord van uw account is gewijzigd.",
            "sent": "Als we een matchende account hebben gevonden, ontvangt u in uw e-mail instructies om het wachtwoord opnieuw in te stellen.",
            "throttled": "Gelieve even te wachten en het dan opnieuw te proberen.",
            "token": "Deze link is niet meer geldig, vraag een nieuwe aan alstublieft.",
            "user": "Geen gebruiker bekend met het e-mailadres."
        },
        "validation": {
            "accepted": "{Attribute} moet worden geaccepteerd.",
            "accepted_if": "{Attribute} moet worden geaccepteerd als {other} {value} is.",
            "active_url": "{Attribute} is geen geldige URL.",
            "after": "{Attribute} moet een datum na {date} zijn.",
            "after_or_equal": "{Attribute} moet een datum na of gelijk aan {date} zijn.",
            "alpha": "{Attribute} mag alleen letters bevatten.",
            "alpha_dash": "{Attribute} mag alleen letters, nummers, underscores (_) en streepjes (-) bevatten.",
            "alpha_num": "{Attribute} mag alleen letters en nummers bevatten.",
            "array": "{Attribute} moet geselecteerde elementen bevatten.",
            "ascii": "De {attribute} mag alleen alfanumerieke tekens en symbolen van één byte bevatten.",
            "before": "{Attribute} moet een datum vóór {date} zijn.",
            "before_or_equal": "{Attribute} moet een datum vóór of gelijk aan {date} zijn.",
            "between": {
                "array": "{Attribute} moet tussen {min} en {max} waardes bevatten.",
                "file": "{Attribute} moet tussen {min} en {max} kilobytes zijn.",
                "numeric": "{Attribute} moet tussen {min} en {max} zijn.",
                "string": "{Attribute} moet tussen {min} en {max} karakters zijn."
            },
            "boolean": "{Attribute} moet ja of nee zijn.",
            "can": "{Attribute} bevat een waarde waar je niet bevoegd voor bent.",
            "confirmed": "Bevestiging van {attribute} komt niet overeen.",
            "current_password": "Huidig wachtwoord is onjuist.",
            "date": "{Attribute} moet een datum bevatten.",
            "date_equals": "{Attribute} moet een datum gelijk aan {date} zijn.",
            "date_format": "{Attribute} voldoet niet aan het formaat {format}.",
            "decimal": "De {attribute} moet {decimal} decimalen hebben.",
            "declined": "{Attribute} moet afgewezen worden.",
            "declined_if": "{Attribute} moet afgewezen worden wanneer {other} gelijk is aan {value}.",
            "different": "{Attribute} en {other} moeten verschillend zijn.",
            "digits": "{Attribute} moet bestaan uit {digits} cijfers.",
            "digits_between": "{Attribute} moet bestaan uit minimaal {min} en maximaal {max} cijfers.",
            "dimensions": "{Attribute} heeft geen geldige afmetingen.",
            "distinct": "{Attribute} heeft een dubbele waarde.",
            "doesnt_end_with": "{Attribute} mag niet eindigen met één van de volgende waarden: {values}.",
            "doesnt_start_with": "{Attribute} mag niet beginnen met één van de volgende waarden: {values}.",
            "email": "{Attribute} is geen geldig e-mailadres.",
            "ends_with": "{Attribute} moet met één van de volgende waarden eindigen: {values}.",
            "enum": "Gekozen {attribute} is ongeldig.",
            "exists": "{Attribute} bestaat niet.",
            "extensions": "{Attribute} moet een van de volgende bestandsextensies hebben: {values}.",
            "file": "{Attribute} moet een bestand zijn.",
            "filled": "{Attribute} is verplicht.",
            "gt": {
                "array": "{Attribute} moet meer dan {value} waardes bevatten.",
                "file": "{Attribute} moet groter zijn dan {value} kilobytes.",
                "numeric": "{Attribute} moet groter zijn dan {value}.",
                "string": "{Attribute} moet meer dan {value} tekens bevatten."
            },
            "gte": {
                "array": "{Attribute} moet {value} of meer waardes bevatten.",
                "file": "{Attribute} moet groter of gelijk zijn aan {value} kilobytes.",
                "numeric": "{Attribute} moet groter of gelijk zijn aan {value}.",
                "string": "{Attribute} moet minimaal {value} tekens bevatten."
            },
            "hex_color": "{Attribute} moet een geldige hexadecimale kleurcode zijn.",
            "image": "{Attribute} moet een afbeelding zijn.",
            "in": "{Attribute} is ongeldig.",
            "in_array": "{Attribute} bestaat niet in {other}.",
            "integer": "{Attribute} moet een getal zijn.",
            "ip": "{Attribute} moet een geldig IP-adres zijn.",
            "ipv4": "{Attribute} moet een geldig IPv4-adres zijn.",
            "ipv6": "{Attribute} moet een geldig IPv6-adres zijn.",
            "json": "{Attribute} moet een geldige JSON-string zijn.",
            "lowercase": "{Attribute} mag alleen kleine letters bevatten.",
            "lt": {
                "array": "{Attribute} moet minder dan {value} waardes bevatten.",
                "file": "{Attribute} moet kleiner zijn dan {value} kilobytes.",
                "numeric": "{Attribute} moet kleiner zijn dan {value}.",
                "string": "{Attribute} moet minder dan {value} tekens bevatten."
            },
            "lte": {
                "array": "{Attribute} moet {value} of minder waardes bevatten.",
                "file": "{Attribute} moet kleiner of gelijk zijn aan {value} kilobytes.",
                "numeric": "{Attribute} moet kleiner of gelijk zijn aan {value}.",
                "string": "{Attribute} moet maximaal {value} tekens bevatten."
            },
            "mac_address": "{Attribute} moet een geldig MAC-adres zijn.",
            "max": {
                "array": "{Attribute} mag niet meer dan {max} waardes bevatten.",
                "file": "{Attribute} mag niet meer dan {max} kilobytes zijn.",
                "numeric": "{Attribute} mag niet hoger dan {max} zijn.",
                "string": "{Attribute} mag niet uit meer dan {max} tekens bestaan."
            },
            "max_digits": "{Attribute} mag niet uit meer dan {max} cijfers bestaan.",
            "mimes": "{Attribute} moet een bestand zijn van het bestandstype {values}.",
            "mimetypes": "{Attribute} moet een bestand zijn van het bestandstype {values}.",
            "min": {
                "array": "{Attribute} moet minimaal {min} waardes bevatten.",
                "file": "{Attribute} moet minimaal {min} kilobytes zijn.",
                "numeric": "{Attribute} moet minimaal {min} zijn.",
                "string": "{Attribute} moet minimaal {min} tekens zijn."
            },
            "min_digits": "{Attribute} moet minimaal uit {min} cijfers bestaan.",
            "missing": "Het veld {attribute} moet ontbreken.",
            "missing_if": "Het veld {attribute} moet ontbreken als {other} {value} is.",
            "missing_unless": "Het veld {attribute} moet ontbreken, tenzij {other} {value} is.",
            "missing_with": "Het veld {attribute} moet ontbreken wanneer {values} aanwezig is.",
            "missing_with_all": "Het veld {attribute} moet ontbreken wanneer er {values} aanwezig zijn.",
            "multiple_of": "{Attribute} moet een veelvoud van {value} zijn.",
            "not_in": "{Attribute} is ongeldig.",
            "not_regex": "Het formaat van {attribute} is ongeldig.",
            "numeric": "{Attribute} moet een getal zijn.",
            "password": {
                "letters": "{Attribute} moet minimaal één letter bevatten.",
                "mixed": "{Attribute} moet minimaal één kleine letter en één hoofdletter bevatten.",
                "numbers": "{Attribute} moet minimaal één cijfer bevatten.",
                "symbols": "{Attribute} moet minimaal één vreemd teken bevatten.",
                "uncompromised": "Het opgegeven {attribute} komt voor in een datalek. Kies een ander {attribute}."
            },
            "phone": "The {attribute} field must be a valid number.",
            "present": "{Attribute} moet aanwezig zijn.",
            "present_if": "{Attribute} moet aanwezig zijn als {other} {value} is.",
            "present_unless": "{Attribute} moet aanwezig zijn tenzij {other} {value} is.",
            "present_with": "{Attribute} moet aanwezig zijn als {values} aanwezig is.",
            "present_with_all": "{Attribute} moet aanwezig zijn als {values} aanwezig zijn.",
            "prohibited": "{Attribute} is niet toegestaan.",
            "prohibited_if": "{Attribute} is niet toegestaan indien {other} gelijk is aan {value}.",
            "prohibited_unless": "{Attribute} is niet toegestaan tenzij {other} gelijk is aan {values}.",
            "prohibits": "{Attribute} is niet toegestaan in combinatie met {other}.",
            "regex": "Het formaat van {attribute} is ongeldig.",
            "required": "{Attribute} is verplicht.",
            "required_array_keys": "{Attribute} moet waardes bevatten voor {values}.",
            "required_if": "{Attribute} is verplicht indien {other} gelijk is aan {value}.",
            "required_if_accepted": "{Attribute} is verplicht indien {other} is geaccepteerd.",
            "required_unless": "{Attribute} is verplicht tenzij {other} gelijk is aan {values}.",
            "required_with": "{Attribute} is verplicht in combinatie met {values}.",
            "required_with_all": "{Attribute} is verplicht in combinatie met {values}.",
            "required_without": "{Attribute} is verplicht als {values} niet ingevuld is.",
            "required_without_all": "{Attribute} is verplicht als {values} niet ingevuld zijn.",
            "same": "{Attribute} en {other} moeten overeenkomen.",
            "size": {
                "array": "{Attribute} moet {size} waardes bevatten.",
                "file": "{Attribute} moet {size} kilobytes groot zijn.",
                "numeric": "{Attribute} moet {size} zijn.",
                "string": "{Attribute} moet {size} tekens zijn."
            },
            "starts_with": "{Attribute} moet beginnen met een van de volgende: {values}.",
            "string": "{Attribute} moet een tekst zijn.",
            "timezone": "{Attribute} moet een geldige tijdzone zijn.",
            "ulid": "De {attribute} moet een geldige ULID zijn.",
            "unique": "{Attribute} is al in gebruik.",
            "uploaded": "Het uploaden van {attribute} is mislukt.",
            "uppercase": "{Attribute} mag alleen hoofdletters bevatten.",
            "url": "{Attribute} moet een geldige URL zijn.",
            "uuid": "{Attribute} moet een geldige UUID zijn.",
            "attributes": {
                "address": "adres",
                "affiliate_url": "partner-URL",
                "age": "leeftijd",
                "amount": "bedrag",
                "area": "regio",
                "available": "beschikbaar",
                "birthday": "verjaardag",
                "body": "lichaam",
                "city": "stad",
                "content": "inhoud",
                "country": "land",
                "created_at": "aangemaakt op",
                "creator": "maker",
                "currency": "valuta",
                "current_password": "huidig wachtwoord",
                "customer": "klant",
                "date": "datum",
                "date_of_birth": "geboortedatum",
                "day": "dag",
                "deleted_at": "verwijderd op",
                "description": "omschrijving",
                "district": "wijk",
                "duration": "tijdsduur",
                "email": "e-mailadres",
                "end time": "eindtijd",
                "excerpt": "uittreksel",
                "filter": "filter",
                "first_name": "voornaam",
                "gender": "geslacht",
                "group": "groep",
                "hour": "uur",
                "identification_card_number": "identificatie typer",
                "identification_card_type": "identificatie type",
                "identification_card_valid_until": "identificatie nummer geldig tot",
                "image": "afbeelding",
                "is_subscribed": "is geabonneerd",
                "items": "artikels",
                "kvk number": "kvk nummer",
                "last_name": "achternaam",
                "latitude": "lengthtegraad",
                "lesson": "les",
                "line_address_1": "adresregel 1",
                "line_address_2": "adresregel 2",
                "location name": "locatie naam",
                "location_name": "locatie naam",
                "longitude": "breedtegraad",
                "message": "bericht",
                "middle_name": "tweede naam",
                "minute": "minuut",
                "mobile": "mobiel",
                "month": "maand",
                "name": "naam",
                "national_code": "landcode",
                "number": "nummer",
                "password": "wachtwoord",
                "password_confirmation": "wachtwoordbevestiging",
                "phone": "telefoonnummer",
                "phone_number": "telefoonnummer",
                "photo": "foto",
                "postal_code": "postcode",
                "preview": "voorbeeld",
                "price": "prijs",
                "product_id": "product-ID",
                "product_uid": "product-UID",
                "product_uuid": "product-UUID",
                "promo_code": "promo-code",
                "province": "provincie",
                "quantity": "aantal",
                "recaptcha_response_field": "recaptcha-antwoordveld",
                "remember": "onthouden",
                "restored_at": "hersteld op",
                "result_text_under_image": "antwoord tekst onder afbeelding",
                "role": "rol",
                "second": "seconde",
                "sex": "geslacht",
                "shipment": "verzending",
                "short_text": "korte tekst",
                "size": "grootte",
                "start time": "starttijd",
                "start_date": "startdatum",
                "state": "staat",
                "street": "straatnaam",
                "street number": "huisnummer",
                "street number addition": "toevoeging",
                "street_name": "straatnaam",
                "street_number": "huisnummer",
                "street_number_addition": "toevoeging",
                "student": "leerling",
                "subject": "onderwerp",
                "teacher": "docent",
                "terms": "voorwaarden",
                "test_description": "test omschrijving",
                "test_locale": "test landinstelling",
                "test_name": "test naam",
                "text": "tekst",
                "time": "tijd",
                "title": "titel",
                "updated_at": "bijgewerkt op",
                "user": "gebruiker",
                "username": "gebruikersnaam",
                "year": "jaar"
            }
        },
        "actions": {
            "accept": "Aanvaarden",
            "action": "Actie",
            "actions": "Acties",
            "add": "Toevoegen",
            "admin": "Beheerder",
            "agree": "Akkoord",
            "archive": "Archiveren",
            "assign": "Toewijzen",
            "attach": "Bijvoegen",
            "browse": "Bladeren",
            "cancel": "Annuleren",
            "choose": "Kiezen",
            "choose_file": "Kies bestand",
            "choose_image": "Kies afbeelding",
            "click_to_copy": "Klik om te kopiëren",
            "close": "Sluiten",
            "collapse": "Inklappen",
            "collapse_all": "Alles inklappen",
            "comment": "Reageren",
            "confirm": "Bevestigen",
            "connect": "Aansluiten",
            "create": "Creëren",
            "delete": "Verwijderen",
            "detach": "Losmaken",
            "details": "Details",
            "disable": "Uitzetten",
            "discard": "Weggooien",
            "done": "Klaar",
            "down": "Omlaag",
            "duplicate": "Dupliceren",
            "edit": "Bewerken",
            "enable": "Inschakelen",
            "expand": "Uitvouwen",
            "expand_all": "Alles uitvouwen",
            "explanation": "Uitleg",
            "export": "Exporteren",
            "file": "{Attribute} moet een bestand zijn.",
            "files": "Bestanden",
            "go_home": "Ga naar startpagina",
            "hide": "Verbergen",
            "home": "Startpagina",
            "image": "{Attribute} moet een afbeelding zijn.",
            "Impersonate": "Voordoen als een ander",
            "Impersonation": "Nabootsing van identiteit",
            "import": "Importeren",
            "introduction": "Invoering",
            "like": "Leuk vinden",
            "load": "Laden",
            "localize": "Lokaliseren",
            "named": {
                "add": "Voeg {name} toe",
                "choose": "Kies {name}",
                "create": "Creëer {name}",
                "delete": "Verwijder {name}",
                "duplicate": "Dupliceer: naam",
                "edit": "Bewerk {name}",
                "hide": "Verberg {name}",
                "import": "Importeer {name}",
                "new": "Nieuw {name}",
                "restore": "Herstel {name}",
                "save": "Sla {name} op",
                "search": "Zoek {name}",
                "show": "Toon {name}",
                "update": "Bijwerken {name}",
                "view": "Bekijk {name}"
            },
            "new": "Nieuw",
            "no": "Nee",
            "open": "Open",
            "open_website": "Open op de website",
            "preview": "Voorbeeld",
            "price": "Prijs",
            "restore": "Herstel",
            "save": "Opslaan",
            "save_and_close": "Opslaan en sluiten",
            "save_and_return": "Opslaan en teruggaan",
            "search": "Zoeken",
            "select": "Selecteren",
            "select_all": "Selecteer alles",
            "send": "Versturen",
            "settings": "Instellingen",
            "show": "Toon",
            "show_all": "Toon alles",
            "solve": "Oplossen",
            "submit": "Indienen",
            "subscribe": "Abonneren",
            "switch": "Veranderen",
            "switch_to_role": "Verander naar rol",
            "tag": "Label",
            "tags": "Labels",
            "target_link": {
                "blank": "Open in een nieuw venster",
                "parent": "Open in een bovenliggend frame",
                "self": "Open in een huidig ​​venster",
                "top": "Open in het bovenste frame"
            },
            "translate": "Vertalen",
            "translate_it": "Vertaal het",
            "unpack": "Uitpakken",
            "unsubscribe": "Uitschrijven",
            "up": "Omhoog",
            "update": "Updaten",
            "user": "Geen gebruiker bekend met dit e-mailadres.",
            "view": "Weergave",
            "yes": "Ja"
        }
    }
}
